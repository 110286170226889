import { EntityQuery, Query, is, LegalPerson, LegalForm, LinkedEntityQuery, RelationshipDirection, Address } from "@the-real-insight/tri-model";
import { userAPI } from "../UserAPI";
import { useEffect, useRef, useState } from "react";
import { ActionButton, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton, SearchBox, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import AddressEntry from "./AddressEntry";
import { useAdministrationAPI } from "../AdministrationAPI";

interface Properties {
    className?: string;
    setLegalPerson?: (legalPerson: any) => void
}

export default function LegalPersonPicker(properties: Properties) {
    const { t } = useTranslation();
    const linkRef = useRef(null);
    const { createLegalPerson } = useAdministrationAPI();
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [legalPersons, setLegalPersons] = useState([]) as any;
    const [legalPersonFilter, setLegalPersonFilter] = useState() as any;
    const [menuOpen, setMenuOpen] = useState(false) as any;
    const [createLegalPersonDialogOpen, setCreateLegalPersonDialogOpen] = useState(false) as any;
    const [editLegalPerson, setEditLegalPerson] = useState() as any;

    useEffect(() => {
        if (filter && filter.trim().length > 3) {
            setLoading(true);
        }

        const debounce = setTimeout(async () => {
            console.log(filter);

            if (filter && filter.trim().length > 2) {
                try {
                    const persons = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
                        is('name').equalRegExp(`(?i)${filter}.*`)), [new LinkedEntityQuery('headquarters', RelationshipDirection.out, Address.type)]));

                    setLegalPersons(persons);
                    setMenuOpen(true);
                } catch (error) {
                } finally {
                    setLoading(false);
                }
            }
        }, 1500);

        return () => clearTimeout(debounce)
    }, [filter])

    const filterLegalPersons = async (filter: string) => {
        setFilter(filter);
        setLegalPersonFilter(null);
        setLegalPersons([]);

        if (properties.setLegalPerson) {
            properties.setLegalPerson(null);
        }
    };

    const isValid = () => {
        return editLegalPerson.name && editLegalPerson.name.length > 0 &&
            editLegalPerson.headquarters.street && editLegalPerson.headquarters.street.length > 0 &&
            editLegalPerson.headquarters.streetNumber && editLegalPerson.headquarters.streetNumber.length > 0 &&
            editLegalPerson.headquarters.postalCode && editLegalPerson.headquarters.postalCode.length > 0 &&
            editLegalPerson.headquarters.city && editLegalPerson.headquarters.city.length > 0;
    }

    return <><div className={properties.className || ''}>
        <SearchBox autoFocus placeholder={t('legalPersonPicker.enterNameOrTradeRegisterId')} value={legalPersonFilter ? `${legalPersonFilter.name}` : filter} ref={linkRef} onChange={(event: any, filter: any) => filterLegalPersons(filter)}
            styles={{ root: { width: 450 } }}></SearchBox>
        <ContextualMenu
            items={legalPersons.map((legalPerson: LegalPerson) => {
                return {
                    key: `${legalPerson.name}`, text: `${legalPerson.name} (${legalPerson.tradeRegisterId}, ${legalPerson.tradeRegisterCity})`, onClick: () => {
                        setLegalPersonFilter(legalPerson);
                        setMenuOpen(false);

                        if (properties.setLegalPerson) {
                            properties.setLegalPerson(legalPerson);
                        }
                    }
                };
            })}
            hidden={!menuOpen}
            target={linkRef}
            onItemClick={() => setMenuOpen(false)}
            onDismiss={() => setMenuOpen(false)}
        />
        {loading
            ?
            <div className="marginTopS textS colorPrimary">{t('global.loading')}...</div>
            :
            (legalPersonFilter
                ?
                <div className="marginTopS textS colorPrimary">{legalPersonFilter.name} ({legalPersonFilter.tradeRegisterId}, {legalPersonFilter.tradeRegisterCity})</div>
                :
                <ActionButton iconProps={{ iconName: 'Add' }} onClick={() => {
                    setEditLegalPerson({ headquarters: {} });
                    setCreateLegalPersonDialogOpen(true);
                }}>
                    {t('legalPersonPicker.companyNotFound')}?
                </ActionButton>)
        }
    </div>
        {editLegalPerson ?
            <Dialog
                hidden={!createLegalPersonDialogOpen}
                onDismiss={() => setCreateLegalPersonDialogOpen(!createLegalPersonDialogOpen)}
                maxWidth={700}
                minWidth={700}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t('legalPersonPicker.createCompany'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <div>
                    <div>
                        <TextField required label={t('legalPersonPicker.name')} value={editLegalPerson.name} onChange={(_: any, name: any) => {
                            setEditLegalPerson({ ...editLegalPerson, name });
                        }} />
                    </div>
                    <div className="flexGrow1 displayFlex gapM">
                        <TextField required placeholder="HRB 1234" label={t('legalPersonPicker.tradeRegisterId')} styles={{ fieldGroup: { width: 120 } }} value={editLegalPerson.tradeRegisterId} onChange={(_: any, tradeRegisterId: any) => {
                            setEditLegalPerson({ ...editLegalPerson, tradeRegisterId });
                        }} />
                        <div>
                            <TextField required label={t('legalPersonPicker.tradeRegisterCity')} styles={{ fieldGroup: { width: 300 } }} value={editLegalPerson.tradeRegisterCity} onChange={(_: any, tradeRegisterCity: any) => {
                                setEditLegalPerson({ ...editLegalPerson, tradeRegisterCity });
                            }} />
                        </div>
                        <Dropdown
                            styles={{
                                dropdown: { width: 200 },
                            }}
                            label={t('legalPersonPicker.legalForm')}
                            defaultSelectedKey={'GmbH'}
                            selectedKey={editLegalPerson.legalForm}
                            options={Object.keys(LegalForm).map((key: any) => { return { key: key, text: key } })}
                            onChange={(_: any, item: any) => {
                                setEditLegalPerson({ ...editLegalPerson, legalForm: item.key });
                            }}
                        />
                    </div>
                    <div className="marginTopL textL">{t('legalPersonPicker.address')}</div>
                    <AddressEntry className="marginTopS" address={editLegalPerson.headquarters} addressChanged={(headquarters: any) => {
                        console.log('Legal Person >>>', { ...editLegalPerson, headquarters });
                        setEditLegalPerson({ ...editLegalPerson, headquarters })
                    }}></AddressEntry>
                    <div className="marginTopL displayFlex gapM">
                        <TextField required placeholder={`${t('global.eg')} DE124356789`} label={t('legalPersonPicker.taxID')} styles={{ fieldGroup: { width: 150 } }} value={editLegalPerson.taxID} onChange={(_: any, taxID: any) => {
                            setEditLegalPerson({ ...editLegalPerson, taxID });
                        }} />
                        <TextField required placeholder={`${t('global.eg')} DE02600501010002034304`} label={t('legalPersonPicker.iban')} styles={{ fieldGroup: { width: 250 } }} value={editLegalPerson.iban} onChange={(_: any, iban: any) => {
                            setEditLegalPerson({ ...editLegalPerson, iban });
                        }} />
                    </div>
                </div>
                <DialogFooter className="marginTopXXL">
                    <PrimaryButton disabled={!isValid()} text={t('global.add')} onClick={async () => {
                        console.log('Legal Person >>>', editLegalPerson);

                        //const newLegalPerson = editLegalPerson;
                        const newLegalPerson = await createLegalPerson(editLegalPerson);

                        if (properties.setLegalPerson) {
                            properties.setLegalPerson(newLegalPerson);
                        }

                        setCreateLegalPersonDialogOpen(false);
                    }} />
                    <DefaultButton onClick={() => {
                        setCreateLegalPersonDialogOpen(false);
                    }} text={t('global.cancel')} />
                </DialogFooter>
            </Dialog>
            :
            <></>
        }
    </>
}