import { Text, TextField } from "@fluentui/react";
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { useEffect, useState } from "react";
import { userAPI } from "../UserAPI";

interface Properties {
  readonly?: boolean;
  deviceType: any;
  configuration: any;
  setConfiguration: any;
}

export default function IoTDeviceTypeProperties(properties: Properties) {
  const { t } = useTranslation();

  return <>{properties.deviceType && properties.deviceType.configuration /*&& properties.properties */
    ?
    <div className="flexGrow1">
      {properties.readonly
        ?
        <>
          <div className="displayFlex marginBottomS">
            <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">Type:</Text></div>
            <div><Text variant="medium">{properties.deviceType.id}</Text></div>
          </div>
          {properties.deviceType.configuration.map((field: any) => <div>
            {(() => {
              switch (field.type) {
                case 'integer':
                case 'number': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.id}:</Text></div>
                    <div><Text variant="medium">{properties.configuration && properties.configuration[field.name] ? properties.configuration[field.id] : '-'}</Text></div>
                  </div>
                </>;
                case 'boolean': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.id}:</Text></div>
                    <div><Text variant="medium">{properties.configuration && properties.configuration[field.name] ? properties.configuration[field.id] : '-'}</Text></div>
                  </div>
                </>;
                case 'string':
                default: return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.id}:</Text></div>
                    <div><Text variant="medium">{properties.configuration && properties.configuration[field.name] ? properties.configuration[field.id] : '-'}</Text></div>
                  </div>
                </>;
              }
            })()}
          </div>)}
        </>
        :
        <>
          <div className="displayFlex marginBottomS gapS">
            <div className="displayFlex">
              <Text variant="medium" className="fontWeightBold">Type:</Text>
            </div>
            <div>
              <Text variant="medium">{properties.deviceType.id}</Text>
            </div>
          </div>
          {properties.deviceType.configuration.map((field: any) => <div>
            {(() => {
              switch (field.type) {
                case 'integer':
                case 'number': return <>
                  <TextField type="number" label={field.id} value={properties.configuration[field.id]} onChange={(e: any, value: any) => {
                    const newConfiguration = { ...properties.configuration } as any;

                    newConfiguration[field.id] = value;

                    properties.setConfiguration(newConfiguration)
                  }} />
                </>;
                case 'boolean': return <>
                  <TextField type="number" label={field.id} value={properties.configuration[field.id]} onChange={(e: any, value: any) => {
                    const newConfiguration = { ...properties.configuration } as any;

                    newConfiguration[field.id] = value;

                    properties.setConfiguration(newConfiguration)
                  }} />
                </>;
                case 'string':
                default: return <>
                  <TextField label={field.id} value={properties.configuration[field.id]} onChange={(e: any, value: any) => {
                    const newConfiguration = { ...properties.configuration } as any;

                    newConfiguration[field.id] = value;

                    properties.setConfiguration(newConfiguration)
                  }} />
                </>;
              }
            })()}
          </div>)}
        </>}
    </div>
    :
    <div></div>
  }
  </>;
}
