import { Entity, Field } from "@the-real-insight/tri-model";

interface Properties {
    entity: Entity;
}

export const EntityFields = (properties: Properties) => {
    console.log(properties.entity);

    return <table className="width100">
        <tbody>
            {properties.entity.getTypeClass().fields.filter((field: Field) => field.type !== JSON && field.name !== '_id' && field.name !== '_tenant').map((field: Field) => {
                return <tr key={field.name}>
                    <td className="fontWeightBold width200">{field.getLabel('de')}:</td>
                    <td>
                        {(() => {
                            if (field.type === Date) {
                                return (new Date((properties.entity as any)[field.name])).toLocaleDateString();
                            } else {
                                return (properties.entity as any)[field.name];
                            }
                        })()}
                    </td>
                </tr>;
            })}
        </tbody>
    </table>;
}