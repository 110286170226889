import { ListResource } from "./resource-details/ListResource";
import { LinkResource } from "./resource-details/LinkResource";
import { ChartResource } from "./resource-details/ChartResource";
import { TableResource } from "./resource-details/TableResource";
import { MapResource } from "./resource-details/MapResource";
import { MapAndImageResource } from "./resource-details/MapAndImageResource";
import { ReportResource } from "./resource-details/ReportResource";
import { ImageResource } from "./resource-details/ImageResource";

export const ResourceDetails = ({ resources }) => {
    return <>
        {resources.map((resource) => {
            return <div>
                {(() => {
                    switch (resource.type) {
                        case 'list': return <ListResource resource={resource}></ListResource>
                        case 'link': return <LinkResource resource={resource}></LinkResource>
                        case 'image': return <ImageResource resource={resource}></ImageResource>
                        case 'chart': return <ChartResource resource={resource}></ChartResource>
                        case 'table': return <TableResource resource={resource}></TableResource>
                        case 'map': return <MapResource resource={resource}></MapResource>
                        case 'mapAndImage': return <MapAndImageResource resource={resource}></MapAndImageResource>
                        case 'report': return <ReportResource resource={resource}></ReportResource>
                        default: return <></>;
                    }
                })()}
            </div>
        })}
    </>;
};
