import { Label, PrimaryButton, TextField, Selection, DatePicker } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAdministrationAPI } from "../AdministrationAPI";
import { Text } from '@fluentui/react';
import Section from "../components/Section";
import DataTable from "react-data-table-component";
import { dataTableStyles } from "../styles";
import { useModalContext } from "../components/ModalProvider";

export default function BillingPanel(properties: any) {
    const { t } = useTranslation();
    const { pushError } = useModalContext() as any;
    const { getCustomers, runBilling, runBillingGlobal } = useAdministrationAPI();
    const [customerFilter, setCustomerFilter] = useState() as any;
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState() as any;
    const [period, setPeriod] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState(new Date());
    const [selectedCustomers, setSelectedCustomers] = useState([]) as any;
    const [clearRows, setClearRows] = useState(false) as any;

    const customerSelection = new Selection({
        onSelectionChanged: () => {
            setCustomer(customerSelection.getSelection()[0]);
        }
    })

    const filterItem = (filter: string, item: any, fields: any) => {
        if (!filter || filter.trim().length === 0) {
            return true;
        }

        for (const field of fields) {
            if (item[field] &&
                item[field].toLowerCase().indexOf(filter.toLowerCase()) === 0) {
                return true;
            }
        }

        return false;
    };

    const filterCustomers = async () => {
        const allCustomers = await getCustomers();

        setCustomers(allCustomers.filter((customer: any) => filterItem(customerFilter, customer, ['name', 'domain'])));
    };

    useEffect(() => {
        filterCustomers();
    }, [customerFilter]);

    const customerColumns = [{
        name: 'ID',
        width: '150px',
        cell: (customer: any) => (
            <Text>{customer.id}</Text>
        ),
    }, {
        name: 'Name',
        width: '250px',
        cell: (customer: any) => (
            <Text>{customer.name}</Text>
        ),
    }, {
        name: 'Created',
        width: '100px',
        right: true,
        cell: (customer: any) => (
            <Text variant='medium'>{new Date(customer.created).toLocaleDateString()}</Text>
        )
    }, {
        name: 'Domain',
        width: '250px',
        cell: (customer: any) => (
            <a href={`https://${customer.domain}`} target="_blank" rel="noreferrer"><Text variant='medium'>{customer.domain}</Text></a>
        )
    },
    ];

    return <>
        <div>
            <Section title="Global Billing">
                <div className="marginTopL displayFlex alignItemsCenter gapM">
                    <Text variant="medium">Run billing for all customers for period</Text>
                    <DatePicker
                        styles={{ root: { width: 200 } }}
                        value={period}
                        onSelectDate={(date: Date | null | undefined) => {
                            if (date) {
                                setPeriod(date);
                            }
                        }}
                    />
                    <PrimaryButton onClick={async () => {
                        try {
                            console.log(await runBillingGlobal(new Date(period)));
                        } catch (error) {
                            pushError(error);
                        }
                    }}>
                        Run global Billing
                    </PrimaryButton>
                </div>
                <div className="marginTopL">
                    <Text variant="medium">Billing run is reentrant. If called multiple times for the same period it is a no-op.</Text>
                </div>
            </Section>
            <Section title="Customers">
                <div className="marginTopL displayFlex gapM">
                    <div className="displayFlex gapS">
                        <Label>Filter:</Label>
                        <TextField
                            value={customerFilter}
                            onChange={(e: any, value: string | undefined) => setCustomerFilter(value)}
                            styles={{ root: { maxWidth: '200px' } }}
                        />
                    </div>
                    <div className="flexGrow1 displayFlex alignItemsCenter justifyContentEnd gapM">
                        <DatePicker
                            styles={{ root: { width: 200 } }}
                            value={period}
                            onSelectDate={(date: Date | null | undefined) => {
                                if (date) {
                                    setSelectedPeriod(date);
                                }
                            }}
                        />
                        <PrimaryButton disabled={selectedCustomers.length === 0}
                            onClick={async () => {
                                try {
                                    console.log(await runBilling(selectedCustomers, new Date(period)));
                                } catch (error) {
                                    pushError(error);
                                }
                            }}>
                            Run Billing
                        </PrimaryButton>
                    </div>
                </div>
                <DataTable
                    data={customers}
                    columns={customerColumns}
                    customStyles={dataTableStyles}
                    pagination
                    selectableRows
                    onSelectedRowsChange={(selection: any) => {
                        setSelectedCustomers(selection.selectedRows);
                    }}
                    clearSelectedRows={clearRows}
                />
            </Section>
        </div>
    </>;
}