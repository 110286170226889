import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, FontIcon, IconButton, IDropdownOption, Label, Pivot, PivotItem, PrimaryButton, Rating, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import DataTable from 'react-data-table-component';
import { Address, LegalPerson, EntityQuery, Project, Query, LinkedEntityQuery, RelationshipDirection, is, Building, Floor, Role, RoleType, Customer, Site, and, Partnership, from, Entitlement, CustomerTheme, DocumentTypeI18N, DocumentDetails, DocumentType, MIMEType } from '@the-real-insight/tri-model';
import { dataTableStyles } from '../styles';
import { useAdministrationAPI } from '../AdministrationAPI';
import { FileUpload } from '../components/FileUpload';
import AddressPicker from '../components/AddressPicker';
import LegalPersonPicker from '../components/LegalPersonPicker';
import LoadingIndicator from '../components/LoadingIndicator';
import AssetList from '../components/AssetList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { IoMdArrowDropright } from "react-icons/io";
import cx from "classnames";
import RoleList from '../components/RoleList';
import { useCustomerTheme } from '../Theme';
import truncate from 'truncate';
import RoleLabel from '../components/RoleLabel';
import InvoicePanel from '../components/InvoicePanel';
import { useAuthorization } from '../hooks/Autorization';
import Zoom from 'react-medium-image-zoom'
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import dayjs from 'dayjs';
import { useModalContext } from '../components/ModalProvider';
import MIMEIcon from '../components/MIMEIcon';

const iconStyle = {
    fontSize: 30,
    height: 30,
    width: 30,
    margin: '0 15px',
};

interface Properties {
}

const numberFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

enum BuildingPart {
    wholeBuilding = 'wholeBuilding',
    partOfBuilding = 'partOfBuilding'
}

export default function ProjectPanel(properties: Properties) {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { loggedInUser, isLoggedInUserEntitled } = useAuthorization();
    const { getProjects, createProject, updateProject, deleteProjectEntitlement } = useAdministrationAPI();
    const [projects, setProjects] = useState([]) as any;
    const [selectedProject, setSelectedProject] = useState() as any;
    const [projectOwner, setProjectOwner] = useState() as any;
    const [partnerships, setPartnerships] = useState([]) as any;
    const [emailPrimaryContact, setEmailPrimaryContact] = useState('') as any;
    const [documents, setDocuments] = useState([]) as any;
    const [deletedDocuments, setDeletedDocuments] = useState([]) as any;
    const [order, setOrder] = useState() as any;
    const [loadingProjects, setLoadingProjects] = useState(false) as any;
    const [loadingSites, setLoadingSites] = useState(false) as any;
    const [loadingEntitlements, setLoadingEntitlements] = useState(false) as any;
    const [tab, setTab] = useState(searchParams.get("tab") || 'project') as any;
    const [documentTitle, setDocumentTitle] = useState('');
    const [documentType, setDocumentType] = useState('unknown');
    const [documentVisibility, setDocumentVisibility] = useState(['_self']);
    const [objectSelectDialogOpen, setObjectSelectDialogOpen] = useState(false);
    const [partnerSelectDialogOpen, setPartnerSelectDialogOpen] = useState(false);
    const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false);
    const [address, setAddress] = useState() as any;
    const [client, setClient] = useState() as any;
    const [selectedEntitlement, setSelectedEntitlement] = useState() as any;
    const [newSupplier, setNewSupplier] = useState() as any;
    const [ownEntitlement, setOwnEntitlement] = useState() as any;
    const [buildingPart, setBuildingPart] = useState(BuildingPart.wholeBuilding) as any;
    const [selectedFile, setSelectedFile] = useState() as any;
    const [building, setBuilding] = useState([]) as any;
    const [floors, setFloors] = useState([]) as any;
    const [selectedFloors, setSelectedFloors] = useState([]) as any;
    const [siteName, setSiteName] = useState('') as any;
    const [floorName, setFloorName] = useState('') as any;
    const [selectedSite, setSelectedSite] = useState() as any;
    const [expandedIds, setExpandedIds] = useState([]) as any;
    const [roles, setRoles] = useState([]) as any;
    const [sites, setSites] = useState([]) as any;
    const [siteTreeData, setSiteTreeData] = useState(flattenTree({ name: '', children: [] })) as any;
    const [partnerTreeData, setPartnerTreeData] = useState(flattenTree({ name: '', children: [] })) as any;
    const [projectModified, setProjectModified] = useState(false);
    const [uploadingDocument, setUploadingDocument] = useState(false);
    const { showDeleteDialog, pushError } = useModalContext() as any;

    const refreshRoles = async (sites: Site[]) => {
        const newRoles = await userAPI.query(from(Role.type));

        setRoles(newRoles);
        setSiteTreeData(await buildSiteTree(sites, newRoles));
    };

    const buildSiteTree = async (sites: any, roles: Role[]) => {
        const generalNode = {
            name: 'Allgemein',
            children: roles.filter((role: Role) => role.type === RoleType.role)
                .map((role: Role) => ({
                    name: role.name,
                    children: [],
                    metadata: role,
                })),
            metadata: {
                nodeType: 'global',
                name: 'Allgemein',
            }
        };
        const projectNode = {
            name: selectedProject.name,
            children: [] as any,
            metadata: {
                nodeType: 'project',
                name: selectedProject.name,
            }
        };
        const siteMap = sites.reduce((map: any, site: any) => {
            map[site._id] = {
                name: `${site.name}`,
                children: [],
                metadata: { ...site, nodeType: 'site' }
            }

            return map;
        }, {});

        const roleMap: any = {};

        roles.filter((role: Role) => role.type === RoleType.parameterizedRole)
            .forEach((role: Role) => {
                if (!!role._id && !!role.parameterizedBy['Site'] && !!siteMap[role.parameterizedBy['Site']._id]) {
                    const roleNode = {
                        name: `${role.name}`,
                        children: [],
                        metadata: { ...role, nodeType: 'role' }
                    };
                    roleMap[role._id] = roleNode;
                    siteMap[role.parameterizedBy['Site']._id].children.push(roleNode);
                } else if (!!role._id && !!role.parameterizedBy['Project']) {
                    const roleNode = {
                        name: `${role.name}`,
                        children: [],
                        metadata: { ...role, nodeType: 'role' }
                    };
                    roleMap[role._id] = roleNode;
                    projectNode.children.push(roleNode);
                }
            });

        // TODO Possibly server-side function

        for (const role of roles.filter((role: Role) => role.type === RoleType.partnerDelegation)) {
            role.delegate = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(role.delegate)));
            role.issuer = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(role.issuer)));

            if (!!role._id && !!roleMap[role.principal]) {
                roleMap[role.principal].children.push({
                    name: `${role.name}`,
                    children: [],
                    metadata: { ...role, nodeType: 'partner' }
                });
            }
        }

        const flatTree = flattenTree({
            name: '',
            children: [generalNode, projectNode, ...Object.values(siteMap) as any]
        });

        return flatTree;
    }

    const buildPartnerTree = (partnerships: Partnership[]) => {
        return flattenTree({
            name: "",
            children: partnerships.map((partnership: Partnership) => ({
                // @ts-ignore
                name: partnership.supplier.name,
                metadata: {
                    customer: partnership.customer,
                    supplier: partnership.supplier,
                } as any
            })),
        });
    };

    const addRoleToProject = async (role: any) => {
        console.log(role, selectedProject);

        const result = await userAPI.createRole(authentication.customer, Role.fromTemplate(role, 'Project', selectedProject, selectedProject.name));

        console.log(result);

        refreshRoles(sites);
    };

    const addRoleToSite = async (role: any, site: any) => {
        console.log(role, site);

        const result = await userAPI.createRole(authentication.customer, Role.fromTemplate(role, 'Site', site, site.name));

        console.log(result);

        refreshRoles(sites);
    };

    const addPartnerToRole = async (partner: Partnership, role: any) => {
        const result = await userAPI.createRole(authentication.customer, Role.partnerDelegation(role,
            new Customer(partner.customer),
            new Customer(partner.supplier)));

        console.log(result);

        refreshRoles(sites);
    };

    const removeRole = async (role: any) => {
        await userAPI.deleteRole(authentication.customer, role);

        refreshRoles(sites);
    };

    interface DraggableProperties {
        children?: any;
        item?: any;
        type?: any;
    }

    const Draggable = (properties: DraggableProperties) => {
        const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
            type: properties.type || 'BOX',
            item: properties.item,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }))

        return <>
            <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <div ref={drag} >
                    {properties && properties.children
                        ?
                        (properties.children)
                        :
                        <></>
                    }
                </div>
            </div>
        </>;
    }

    interface DroppableProperties {
        accept?: any;
        children?: any;
        theme?: any;
        onDrop: (payload: any) => void
    }

    const Droppable = (properties: DroppableProperties) => {
        const [{ isOver }, drop] = useDrop(() => ({
            accept: properties.accept || 'BOX',
            drop: properties.onDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }));

        return <div
            ref={drop}
            style={{ backgroundColor: isOver ? 'rgba(255, 0, 0, 0.05)' : 'transparent' }}
        >
            {properties && properties.children
                ?
                (properties.children)
                :
                <></>
            }
        </div>;
    }

    const columns = [{
        name: '',
        width: '50px',
        cell: (project: any) => <ActionButton iconProps={{ iconName: 'Delete' }}
            onClick={async () => {
                showDeleteDialog('Projekt löschen', project.name, async () => {
                });
            }}></ActionButton>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.name'),
        selector: (project: Project) => project.name,
        width: '300px',
        cell: (product: any) => <span>{product.name}</span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.ownership'),
        selector: (project: Project) => project.name,
        width: '120px',
        cell: (project: any) => (authentication.customer === project._tenant ?
            <span className='textS colorPrimary fontWeightBold'>EIGENTÜMER</span>
            :
            <span className='textS colorContrast fontWeightBold'>PARTNER</span>),
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.customer'),
        width: '300px',
        cell: (project: any) => project._company ? <div>
            <div>{project._company.name}</div><div>{project._company.headquarters.city}</div></div> : <span></span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.objects'),
        width: '150px',
        cell: (product: Project) => <span></span>,
        sortable: true,
    }];

    const partnerColumns = [{
        name: '',
        width: '50px',
        cell: (partnership: any) => <div className='displayFlex gapS'>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    showDeleteDialog('Partner löschen', `${partnership.supplier.name}`, async () => {
                        setPartnerships(partnerships.filter((entry: any) => entry._id !== partnership._id));
                    });
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.partner'),
        width: '300px',
        cell: (partnership: any) => <span>{partnership.supplier.name}</span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.address'),
        selector: (partnership: any) => partnership.supplier.headquarters.street,
        width: '300px',
        cell: (partnership: any) => <div>{partnership.supplier.headquarters.street} {partnership.supplier.headquarters.streetNumber}, {partnership.supplier.headquarters.postalCode} {partnership.supplier.headquarters.city}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.primaryContact'),
        selector: (partnership: any) => partnership.emailPrimaryContact,
        width: '150px',
        cell: (partnership: any) => <div><a href={`mailto:${partnership.emailPrimaryContact}`}>{partnership.emailPrimaryContact}</a></div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.addedBy'),
        width: '200px',
        cell: (partner: any) => <div></div>,
        sortable: true,
    }];

    const siteColumns = [{
        name: '',
        width: '50px',
        cell: (site: any) => <div>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    showDeleteDialog('Liegenschaft löschen', `${site.street} ${site.streetNumber}, ${site.postalCode} ${site.city}`, async () => {
                        setSites(sites.filter((checkSite: Site) => checkSite._id !== site._id));
                    });
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.address'),
        selector: (site: any) => site.street,
        width: '300px',
        cell: (site: Site) => <div>{site.street} {site.streetNumber}, {site.postalCode} {site.city}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.space'),
        width: '350px',
        cell: (site: any) => <div><>{site.buildings.map((building: Building) => <div className='displayFlex alignItemsCenter'>
            <span>{t('settingsPanel.projectsPanel.wholeBuilding')}</span><span className='colorGrey'>&nbsp;({building.id_osm})</span>
            <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Building&selectedEntity=${building._id}`)} />
        </div>
        )}
        </>
            {/* <>{site.floors.map((floor: Floor) => <div className='displayFlex alignItemsCenter'>
                <span>{floor.name}</span>
                <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Floor&selectedEntity=${floor._id}`)} />

            </div>
            )}</> */}
        </div>,
        sortable: true,
    }];

    const documentColumns = [{
        width: '50px',
        cell: (document: DocumentDetails) => <div className='displayFlex'>
            <IconButton className="alertColor" iconProps={{ iconName: "Delete", color: theme.primary }} onClick={() =>
                showDeleteDialog('Dokument löschen', document.title, async () => {
                    try {
                        const newDocuments = documents.filter((otherDocument: DocumentDetails) => document._id !== otherDocument._id);

                        selectedProject.documents = newDocuments.map((document: DocumentDetails) => document._id);

                        await updateProject(selectedProject);

                        setDocuments(newDocuments);
                    } catch (error) {
                        pushError(error);
                    }
                })}></IconButton>
        </div>,
    }, {
        name: t('settingsPanel.projectsPanel.content'),
        width: '120px',
        selector: (document: DocumentDetails) => document.mimeType,
        cell: (document: any) => <div>
            {(() => {
                switch (document.mimeType) {
                    case MIMEType.imagePNG:
                    case MIMEType.imageJPEG:
                        return <div className='paddingS'>
                            <Zoom>
                                <img style={{ width: 100, height: 'auto' }} src={userAPI.getFileURL(authentication.customer, document.url)} />
                            </Zoom>
                        </div>;
                    case MIMEType.imageSVG:
                        return <div className='paddingS'>
                            <Zoom>
                                <img style={{ width: 100, height: 'auto' }} src={userAPI.getFileURL(authentication.customer, document.url)} />
                            </Zoom>
                        </div>;
                    case MIMEType.applicationPDF:
                    case MIMEType.textPlain:
                    case MIMEType.textCSV:
                        return <div className='paddingS width100 displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'><a href={userAPI.getFileURL(authentication.customer, document.url)} target='_blank'>
                            <MIMEIcon mimeType={document.mimeType}></MIMEIcon>
                        </a></div>
                }
            })()}
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.title'),
        width: '350px',
        selector: (document: DocumentDetails) => document.title,
        cell: (document: DocumentDetails) => <div>{document.title}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.created'),
        selector: (document: DocumentDetails) => document.created.getTime(),
        width: '150px',
        cell: (document: DocumentDetails) => <div>{document.created.toLocaleDateString()}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.type'),
        selector: (document: DocumentDetails) => document.documentType,
        width: '150px',
        cell: (document: DocumentDetails) => <div>{DocumentTypeI18N['de'][document.documentType]}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.summary'),
        width: '500px',
        cell: (document: DocumentDetails) => <div className='textXS'>
            <Markdown rehypePlugins={[rehypeRaw] as any} remarkPlugins={[remarkGfm]}>{document.synopsis}</Markdown>
        </div>,
        sortable: true,
    }];

    const selectProject = async (project: any) => {
        if (!project) {
            setSelectedProject(null);

            return;
        }

        setSelectedProject(project);

        // TODO All graph traversal should be done server-side via mongoose 8 as long as it is in 

        setLoadingSites(true);

        const newSites = [];

        for (const id of project.sites) {
            const newSite: any = await userAPI.queryOne(from(Site.type).where(is('_id').equal(id)));

            console.log('Site >>>', newSite);

            newSite.buildings = await userAPI.query(from(Building.type).where(
                is('id').equal(`${newSite.street} ${newSite.streetNumber}, ${newSite.postalCode} ${newSite.city}, ${newSite.country}`)));

            console.log('Buildings >>>', newSite.buildings);

            newSites.push(newSite);
        }

        setSites(newSites);
        setLoadingSites(false);

        const newPartnerships: any[] = [];

        for (const id of project.partnerships) {
            const newPartnership: any = await userAPI.queryOne(from(Partnership.type).where(is('_id').equal(id)));

            newPartnership.customer = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(newPartnership.customer)).link('headquarters', RelationshipDirection.out, Address.type));
            newPartnership.supplier = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(newPartnership.supplier)).link('headquarters', RelationshipDirection.out, Address.type));

            newPartnerships.push(newPartnership);
        }

        setPartnerships(newPartnerships);
        setPartnerTreeData(buildPartnerTree(newPartnerships));

        if (project._id) {
            setProjectParam(project._id);
        }
    };

    // TODO Needed?

    useEffect(() => {
        if (!selectedSite) {
            return;
        }

        const call = async () => {
            refreshRoles(sites);
        };

        call();
    }, [selectedSite]);

    useEffect(() => {
        if (selectedProject && selectedProject._id) {
            setLoadingEntitlements(true);

            const call = async () => {
                try {
                    const newProjectOwner = await userAPI.queryOne(from(Customer.type).where(is('id').equal(selectedProject._tenant)));

                    setProjectOwner(newProjectOwner);

                    const documentMap: any = {};

                    for (const id of (selectedProject.documents || [])) {
                        const document = await userAPI.queryOne(from(DocumentDetails.type).where(is('_id').equal(id)));

                        console.log('Document >>>', id, document);

                        // @ts-ignore
                        documentMap[document.url] = { ...document };
                    }

                    setDocuments(Object.values(documentMap));
                    refreshRoles(sites);

                    // RESTfulness

                    setSelectedSite(sites.find((site: any) => site._id === searchParams.get("site")));

                    // Retrieve an order to check, whether billing can be done for this project

                    const newOrder = await userAPI.fullOrderForProject(authentication.customer, selectedProject._id as any);

                    console.log('Order >>>', newOrder);

                    setOrder(newOrder);
                } catch (error) {
                    pushError(error);
                } finally {
                    setLoadingEntitlements(false);
                }
            };

            call();
        } else {
            setLoadingProjects(true);

            const call = async () => {
                try {
                    console.log('Authentication >>>', authentication);

                    //const projects = await getProjects();
                    const projects = await userAPI.query(from(Project.type));

                    for (const project of projects) {
                        const companies = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
                            is('_id').equal(project.customer)), [new LinkedEntityQuery('headquarters', RelationshipDirection.out, Address.type)]));
                        project._company = companies[0];
                    }

                    setProjects(projects);

                    const projectId = searchParams.get("project");

                    if (!!projectId) {
                        selectProject(projects.find((project: any) => project._id === projectId));
                    }
                } catch (error) {
                    pushError(error);
                } finally {
                    setLoadingProjects(false);
                }
            };

            call();
        }
    }, [selectedProject]);

    const documentTypeOptions: IDropdownOption[] = Object.keys(DocumentType).map((key: any) => ({
        key,
        // @ts-ignore
        text: DocumentTypeI18N['de'][key],
    })).sort((x: IDropdownOption, y: IDropdownOption) => x.text.localeCompare(y.text));

    const onDocumentsUpload = async (files: FileList) => {
        if (!files || files.length === 0) {
            return;
        }

        console.log('File >>>', files[0]);

        setSelectedFile(files[0]);
        setDocumentTitle(files[0].name.split('.')[0]);
        setAddDocumentDialogOpen(true);
    }

    const setTabParam = (value: any) => {
        searchParams.set('tab', value.key.substring(1));
        setSearchParams(searchParams);
        setTab(value.key.substring(1));
    }

    const setProjectParam = (id: any) => {
        searchParams.set('project', id);
        setSearchParams(searchParams);
    }

    const setSiteParam = (id: any) => {
        searchParams.set('site', id);
        setSearchParams(searchParams);

    }

    const isValidEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isValid = () => {
        return selectedProject.name && selectedProject.name.trim().length > 0 &&
            selectedProject.description && selectedProject.description.trim().length > 0 &&
            selectedProject.startDate &&
            selectedProject.customer/* &&
            client*/;
    }

    const isNewSupplierValid = () => {
        return newSupplier &&
            emailPrimaryContact && emailPrimaryContact.length > 0 && isValidEmail(emailPrimaryContact);
    }

    const ownsSelectedProject = () => {
        return !selectedProject._tenant || selectedProject._tenant === authentication.customer;
    }

    const canAdministrateSelectedProject = () => {
        return ownEntitlement && ownEntitlement.roles.indexOf('administration') >= 0;
    }

    const isBuildingPartComplete = () => {
        return !!address && (buildingPart === BuildingPart.partOfBuilding ? floorName || selectedFloors.length > 0 : true);
    }

    const iconStyle = {
        fontSize: 20,
        height: 20,
        width: 20,
        margin: '0 10px',
    };

    const saveProject = async () => {
        selectedProject.documents = documents.map((document: DocumentDetails) => document._id);
        selectedProject.sites = sites.map((site: Site) => site._id);
        selectedProject.partnerships = partnerships.map((partnership: Partnership) => partnership._id);

        await updateProject(selectedProject);
    }

    return <><div>
        {selectedProject
            ?
            <div>
                <div className='displayFlex alignItemsCenter'>
                    <div className='flexGrow1 displayFlex textL'>
                        {selectedProject.name}
                    </div>
                </div>
                <div className="marginTopM ">
                    <Pivot aria-label="" onLinkClick={setTabParam} selectedKey={tab}>
                        <PivotItem id="project" headerText={t('settingsPanel.projectsPanel.general')}>
                            <div className='width1200'>
                                <div className='marginTopL displayFlex alignItemsCenter justifyContentEnd'>
                                    <div className='displayFlex gapM'>
                                        {ownsSelectedProject() || canAdministrateSelectedProject()
                                            ?
                                            (selectedProject && selectedProject._id
                                                ?
                                                <PrimaryButton disabled={!isValid() || !projectModified} onClick={async () => {
                                                    try {
                                                        await saveProject();
                                                        setSelectedProject(null);
                                                        setProjectModified(false);
                                                        searchParams.delete('project');
                                                        setSearchParams(searchParams);
                                                    } catch (error) {
                                                        pushError(error);
                                                    }
                                                }}>
                                                    {t('global.save')}
                                                </PrimaryButton>
                                                :
                                                <PrimaryButton disabled={!isValid()} onClick={async () => {
                                                    try {
                                                        selectedProject.documents = documents.map((document: DocumentDetails) => document._id);
                                                        selectedProject.sites = sites.map((site: Site) => site._id);
                                                        selectedProject.partnerships = partnerships.map((partnership: Partnership) => partnership._id);

                                                        await createProject(selectedProject);

                                                        searchParams.delete('project');
                                                        setSearchParams(searchParams);
                                                        setProjectModified(false);
                                                    } catch (error) {
                                                        pushError(error);
                                                    }
                                                }}>
                                                    {t('global.create')}
                                                </PrimaryButton>
                                            )
                                            :
                                            <></>
                                        }
                                        <DefaultButton
                                            onClick={() => {
                                                setSelectedProject(null);
                                                searchParams.delete('project');
                                                setSearchParams(searchParams);
                                            }}>
                                            {t('global.close')}
                                        </DefaultButton>
                                    </div>
                                </div>
                                <div className="width500">
                                    <TextField required disabled={!ownsSelectedProject()} className="marginTopM" label={t('settingsPanel.projectsPanel.name')} value={selectedProject.name} onChange={(e: any, name: any) => setSelectedProject({ ...selectedProject, name })}
                                        style={{ width: '300px' }} />
                                    <TextField required disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.description')} value={selectedProject.description} onChange={(e: any, description: any) => setSelectedProject({ ...selectedProject, description })}
                                        style={{ width: '300px' }} multiline />
                                    <div className="displayFlex gapM">
                                        <DatePicker disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.startDate')}
                                            styles={{ root: { width: 300 } }}
                                            value={new Date(selectedProject.startDate)}
                                            onSelectDate={(date: any) => setSelectedProject({ ...selectedProject, startDate: new Date(date) })}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        //strings={defaultDatePickerStrings}
                                        />
                                        <DatePicker disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.endDate')}
                                            styles={{ root: { width: 300 } }}
                                            value={selectedProject.endDate}
                                            onSelectDate={(date: any) => setSelectedProject({ ...selectedProject, endDate: new Date(date) })}
                                        />
                                    </div>
                                    <div>
                                        <div className='marginTopL textL'>{t('settingsPanel.projectsPanel.customer')}</div>
                                        {client
                                            ?
                                            <div className='marginTopM'>
                                                <div className='textM fontWeightBold'>{client.name}</div>
                                                <div className='textM'>{client.headquarters.street} {client.headquarters.streetNumber}</div>
                                                <div className='textM'>{client.headquarters.postalCode} {client.headquarters.city}, {client.headquarters.country}</div>
                                                {client.tradeRegisterId && client.tradeRegisterCity
                                                    ?
                                                    <div className='textM'>{client.tradeRegisterId}, Handelsregister {client.tradeRegisterCity}</div>
                                                    :
                                                    <></>
                                                }
                                                <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Edit' }} onClick={() => {
                                                    setClient(null);
                                                    setSelectedProject({ ...selectedProject, customer: undefined });
                                                }}>
                                                    {t('global.change')}
                                                </ActionButton>
                                            </div>
                                            :
                                            <LegalPersonPicker className='marginTopM' setLegalPerson={(legalPerson: any) => {
                                                console.log(legalPerson);
                                                setClient(legalPerson);

                                                if (legalPerson) {
                                                    setSelectedProject({ ...selectedProject, customer: legalPerson._id });
                                                } else {
                                                    setSelectedProject({ ...selectedProject, customer: undefined });
                                                }
                                            }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="objects" headerText={t('settingsPanel.projectsPanel.sites')}>
                            <div>
                                <div className='marginTopL'>{t('settingsPanel.projectsPanel.buildingsSpacesDetails')}:</div>
                                <div className='marginTopL'>
                                    {<ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setBuilding(null);
                                            setFloorName('');
                                            setSelectedFloors([]);
                                            setObjectSelectDialogOpen(true);
                                        }}>
                                        {t('settingsPanel.projectsPanel.addSite')}
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={sites || []}
                                        columns={siteColumns}
                                        customStyles={dataTableStyles}
                                        selectableRows
                                        selectableRowsSingle
                                        onSelectedRowsChange={({ selectedRows }) => {
                                            if (selectedRows && selectedRows.length > 0) {
                                                setSelectedSite(selectedRows[0]);
                                                setSiteParam(selectedRows[0]._id);
                                            } else {
                                                setSelectedSite(null);
                                            }
                                        }}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={loadingSites} text={t('settingsPanel.projectsPanel.noObjectsDefined')}></LoadingIndicator>}
                                    />
                                </div>
                                {selectedSite
                                    ?
                                    <div>
                                        <div className="marginTopL textL">{t('settingsPanel.projectsPanel.assets')}</div>
                                        <AssetList className="marginTopM" scopeSites={selectedSite ? [selectedSite] : []}></AssetList>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PivotItem>
                        <PivotItem id="documents" headerText={t('settingsPanel.projectsPanel.documents')}>
                            <div>
                                {isLoggedInUserEntitled(selectedProject, Entitlement.projectManagement)
                                    ?
                                    <div className="marginTopL">
                                        <FileUpload
                                            accept="text/*,image/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            onUpload={onDocumentsUpload}></FileUpload>
                                    </div>
                                    :
                                    <></>}
                                <div className='marginTopL textS'>{t('settingsPanel.projectsPanel.documentAccessDetails')}</div>
                                <div className='marginTopS borderNeutral'>
                                    <DataTable
                                        data={documents}
                                        columns={documentColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={true ? <div className="paddingL">{t('settingsPanel.projectsPanel.noDocumentsUploaded')}</div> : <LoadingIndicator loading={true}></LoadingIndicator>}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                        {isLoggedInUserEntitled(selectedProject, Entitlement.projectManagement)
                            ?
                            <PivotItem id="partners" headerText={t('settingsPanel.projectsPanel.partners')}>
                                <div className='marginTopL textHeader'>Partners</div>
                                <div>
                                    <div className='marginTopM'>
                                        {<ActionButton disabled={!ownsSelectedProject() && !canAdministrateSelectedProject()} iconProps={{ iconName: 'Add' }}
                                            onClick={() => {
                                                setSelectedEntitlement(null);
                                                setPartnerSelectDialogOpen(true);
                                            }}>
                                            {t('settingsPanel.projectsPanel.addPartner')}
                                        </ActionButton>}
                                    </div>
                                    <div className='borderNeutral'>
                                        <DataTable
                                            data={partnerships}
                                            columns={partnerColumns}
                                            customStyles={dataTableStyles}
                                            pagination
                                            paginationPerPage={5}
                                            noDataComponent={<LoadingIndicator loading={loadingEntitlements} text={'Keine Berechtigungen verfügbar.'}></LoadingIndicator>}
                                        />
                                    </div>
                                </div>
                            </PivotItem>
                            :
                            <></>
                        }
                        {isLoggedInUserEntitled(selectedProject, Entitlement.projectManagement)
                            ?
                            <PivotItem id="entitlements" headerText={t('settingsPanel.projectsPanel.entitlements')}>
                                <div className='marginTopM displayFlex gapM'>
                                    <div>
                                        <div className='textSubHeading'>Rollen/Gewerke</div>
                                        <div className='marginTopS width300 height600 borderNeutral'>
                                            <RoleList></RoleList>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='textSubHeading'>{t('settingsPanel.projectsPanel.entitlements')}</div>
                                        <div className='marginTopS width400 height600 borderNeutral'>
                                            <TreeView
                                                data={siteTreeData}
                                                aria-label="Controlled expanded node tree"
                                                expandedIds={expandedIds}
                                                nodeRenderer={({
                                                    element: site,
                                                    isBranch,
                                                    isExpanded,
                                                    isDisabled,
                                                    getNodeProps,
                                                    level,
                                                    handleExpand,
                                                }) => {
                                                    return (
                                                        <div
                                                            {...getNodeProps({ onClick: handleExpand })}
                                                            className='displayFlex alignItemsCenter'
                                                            style={{
                                                                marginLeft: 40 * (level - 1),
                                                                opacity: isDisabled ? 0.5 : 1,
                                                            }}
                                                        >
                                                            {(() => {
                                                                switch (site.metadata?.nodeType) {
                                                                    case 'global': return <>
                                                                        <div className='width10'>
                                                                            {isBranch ?
                                                                                <ArrowIcon isOpen={isExpanded} className='' />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                        <div className="displayFlex gapS alignItemsCenter">
                                                                            <Droppable accept={'role'} onDrop={(role: any) => {
                                                                                console.log('Dropping >>>', role, site);

                                                                                // if (expandedIds.indexOf(element.id) < 0) {
                                                                                //     setExpandedIds([...expandedIds, element.id]);
                                                                                // }

                                                                                addRoleToSite(role.metadata, site.metadata);
                                                                            }}>
                                                                                <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                                    <FontIcon iconName="Globe" />
                                                                                    <div className='textSubHeading'>{site.metadata.name}</div>
                                                                                </div>
                                                                            </Droppable>
                                                                        </div>
                                                                    </>;
                                                                    case 'project': return <>
                                                                        <div className='width10'>
                                                                            {isBranch ?
                                                                                <ArrowIcon isOpen={isExpanded} className='' />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                        <div className="displayFlex gapS alignItemsCenter">
                                                                            <Droppable accept={'role'} onDrop={(role: any) => {
                                                                                console.log('Dropping >>>', role, site);

                                                                                // if (expandedIds.indexOf(element.id) < 0) {
                                                                                //     setExpandedIds([...expandedIds, element.id]);
                                                                                // }

                                                                                addRoleToProject(role);
                                                                            }}>
                                                                                <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                                    <FontIcon iconName="CalendarSettings" />
                                                                                    <div className='textSubHeading'>{site.metadata.name}</div>
                                                                                </div>
                                                                            </Droppable>
                                                                        </div>
                                                                    </>;
                                                                    case 'site': return <>
                                                                        <div className='width10'>
                                                                            {isBranch ?
                                                                                <ArrowIcon isOpen={isExpanded} className='' />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                        <div className="displayFlex gapS alignItemsCenter">
                                                                            <Droppable accept={'role'} onDrop={(role: any) => {
                                                                                console.log('Dropping >>>', role, site);

                                                                                // if (expandedIds.indexOf(element.id) < 0) {
                                                                                //     setExpandedIds([...expandedIds, element.id]);
                                                                                // }

                                                                                addRoleToSite(role, site.metadata);
                                                                            }}>
                                                                                <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                                    <FontIcon iconName="MapPin" />
                                                                                    <div className='textSubHeading'>{site.metadata.name}</div>
                                                                                </div>
                                                                            </Droppable>
                                                                        </div>
                                                                    </>;
                                                                    case 'partner': return <>
                                                                        <div className='width10'>
                                                                            {isBranch ?
                                                                                <ArrowIcon isOpen={isExpanded} className='' />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                        <div className="displayFlex alignItemsCenter">
                                                                            <div className="flexGrow1 paddingXS displayFlex gapXS alignItemsCenter">
                                                                                <FontIcon iconName="FollowUser" style={{ ...iconStyle, color: theme.primaryDark }} />
                                                                                <div className="name">
                                                                                    <div>
                                                                                        <div>
                                                                                            <div className="textS" style={{ color: theme.primary }}>
                                                                                                {(site.metadata.delegate as any).name}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <ActionButton iconProps={{ iconName: 'Delete' }}
                                                                                onClick={() => {
                                                                                    removeRole(site.metadata);
                                                                                }}>
                                                                            </ActionButton>
                                                                        </div>
                                                                    </>
                                                                    default: return <>
                                                                        <div className='width10'>
                                                                            {isBranch ?
                                                                                <ArrowIcon isOpen={isExpanded} className='' />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                        <div className="displayFlex alignItemsCenter">
                                                                            <Droppable accept={'partner'} onDrop={(item: any) => {
                                                                                // if (expandedIds.indexOf(element.id) < 0) {
                                                                                //     setExpandedIds([...expandedIds, element.id]);
                                                                                // }

                                                                                addPartnerToRole(item, site.metadata);
                                                                            }}>
                                                                                <div className="paddingXS displayFlex gapXS alignItemsCenter">
                                                                                    {(() => {
                                                                                        const role = new Role(site.metadata);
                                                                                        const iconStyle = {
                                                                                            fontSize: 20,
                                                                                            height: 20,
                                                                                            width: 20,
                                                                                            margin: '0 10px',
                                                                                        };
                                                                                        return <FontIcon iconName="UserSync" style={{ ...iconStyle, color: theme.ternary }} />;
                                                                                    })()}
                                                                                    <div className="name">
                                                                                        {(() => {
                                                                                            const role = new Role(site.metadata);

                                                                                            return <div>
                                                                                                <div className="fontWeightBold">{role.getRoleName()}</div>
                                                                                            </div>;
                                                                                        })()}
                                                                                    </div>
                                                                                </div>
                                                                            </Droppable>
                                                                            {site.metadata?.type !== RoleType.role
                                                                                ?
                                                                                <ActionButton iconProps={{ iconName: 'Delete' }}
                                                                                    onClick={() => {
                                                                                        removeRole(site.metadata);
                                                                                    }}>
                                                                                </ActionButton>
                                                                                :
                                                                                <></>
                                                                            }

                                                                        </div>
                                                                    </>;
                                                                }
                                                            })()
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='textSubHeading'>Partner</div>
                                        <div className='marginTopS width400 height600 borderNeutral'>
                                            <TreeView
                                                data={partnerTreeData}
                                                aria-label="Controlled expanded node tree"
                                                expandedIds={expandedIds}
                                                defaultExpandedIds={[1]}
                                                nodeRenderer={({
                                                    element,
                                                    isBranch,
                                                    isExpanded,
                                                    isDisabled,
                                                    getNodeProps,
                                                    level,
                                                    handleExpand,
                                                }) => {
                                                    return (
                                                        <div
                                                            {...getNodeProps({ onClick: handleExpand })}
                                                            className="displayFlex"
                                                            style={{
                                                                marginLeft: 40 * (level - 1),
                                                                opacity: isDisabled ? 0.5 : 1,
                                                            }}
                                                        >
                                                            {isBranch && <ArrowIcon isOpen={isExpanded} className='' />}
                                                            <Draggable type={'partner'} item={element.metadata}>
                                                                <div className="paddingS displayFlex gapM alignItemsCenter">
                                                                    <FontIcon aria-label="Compass" style={{ ...iconStyle, color: theme.secondary }} iconName="Group" />
                                                                    <div>
                                                                        <div className="fontWeightBold">{element.name}</div>
                                                                        <div className='marginTopXXS textXXS colorContrast'>{truncate((element.metadata as any).supplier.purpose, 150)}</div>
                                                                        <div className='marginTopXS textXS'>{(element.metadata as any).supplier.headquarters.street} {(element.metadata as any).supplier.headquarters.streetNumber}, {(element.metadata as any).supplier.headquarters.postalCode} {(element.metadata as any).supplier.headquarters.city}</div>
                                                                        <div>
                                                                            <Rating defaultRating={1} max={5} rating={new Date().getTime() % 4} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Draggable>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            :
                            <></>
                        }
                        {order /*&& isLoggedInUserEntitled(selectedProject, 'ProjectBilling')*/
                            ?
                            <PivotItem id="invoicing" headerText={t('settingsPanel.projectsPanel.invoicing')}>
                                <div className='marginTopM'>
                                    <InvoicePanel project={selectedProject} projectChanged={setSelectedProject}></InvoicePanel>
                                </div>
                            </PivotItem>
                            :
                            <></>
                        }
                    </Pivot>
                </div>
            </div>
            :
            <div>
                <div className='textHeader'>
                    {t('settingsPanel.projectsPanel.projects')}
                </div>
                <div className='marginTopM'>
                    <ActionButton iconProps={{ iconName: 'AddToShoppingList' }}
                        onClick={() => {
                            setSelectedProject(new Project({ name: `Project ${new Date().toLocaleDateString()}`, startDate: new Date() }));
                            setPartnerships([]);
                            setSites([]);
                            setClient(null);
                        }}>
                        {t('settingsPanel.projectsPanel.createProject')}
                    </ActionButton>
                    <div className='marginTopL borderNeutral'>
                        <DataTable
                            data={projects}
                            columns={columns}
                            customStyles={dataTableStyles}
                            selectableRows
                            selectableRowsSingle
                            onSelectedRowsChange={({ selectedRows }) => {
                                if (selectedRows && selectedRows.length > 0) {
                                    setClient((selectedRows[0] as any)._company);
                                    selectProject(selectedRows[0]);
                                } else {
                                    selectProject(null);
                                }
                            }}
                            pagination
                            paginationPerPage={5}
                            noDataComponent={<LoadingIndicator loading={loadingProjects} text='Keine Projekte verfügbar.'></LoadingIndicator>}
                        />
                    </div>
                </div>
            </div>
        }
    </div >
        <Dialog
            hidden={!objectSelectDialogOpen}
            onDismiss={() => setObjectSelectDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.objectSelection'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>{t('settingsPanel.projectsPanel.addressBuilding')}</Label>
                    <AddressPicker setAddress={async (address: any) => {
                        console.log('Set address >>>', address);

                        setAddress(address);

                        if (!address) {
                            return;
                        }

                        const buildings = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
                            is('id').equal(`${address.street} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.country}`))));

                        setSiteName(`${address.street}, ${address.city}`);

                        if (buildings.length) {
                            const newBuilding = buildings[0];

                            newBuilding.address = address;

                            setBuilding(newBuilding);

                            const buildingsWithFloors = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
                                is('id').equal(`${address.street} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.country}`)), [new LinkedEntityQuery('floors', RelationshipDirection.out, Floor.type)]));

                            if (buildingsWithFloors.length > 0 && buildingsWithFloors[0].floors && buildingsWithFloors[0].floors.length) {
                                setFloors(buildingsWithFloors[0].floors);
                            } else {
                                setFloors([]);
                            }
                        }
                    }} />
                </div>
                <div className='marginTopS'>
                    <TextField label="Site name" value={siteName} onChange={(_: any, siteName: any) => setSiteName(siteName)}
                        onGetErrorMessage={(siteName: any) => {
                            return siteName ? '' : t('settingsPanel.projectsPanel.enterSiteName');
                        }}
                        validateOnFocusOut></TextField>
                </div>
                <div className='marginTopS'>
                    <ChoiceGroup disabled={!address} selectedKey={buildingPart} options={[{ key: BuildingPart.wholeBuilding, text: t('settingsPanel.projectsPanel.wholeBuilding') }, { key: BuildingPart.partOfBuilding, text: t('settingsPanel.projectsPanel.partOfBuilding') }]}
                        onChange={(_: any, option: any) => setBuildingPart(option.key)} label={t('settingsPanel.projectsPanel.space')} />
                </div>
                <div className="marginTopM height100">
                    {buildingPart === BuildingPart.wholeBuilding
                        ?
                        <></>
                        :
                        <>
                            {floors.length > 0
                                ?
                                <Dropdown disabled={!address}
                                    label="Floor"
                                    options={floors.map((floor: any) => {
                                        return {
                                            key: floor._id,
                                            text: floor.name
                                        }
                                    })}
                                    multiSelect
                                    selectedKeys={selectedFloors.map((floor: any) => floor._id)}
                                    onChange={(e: any, item: any) => {
                                        if (selectedFloors.find((floor: any) => floor._id === item.key)) {
                                            setSelectedFloors(selectedFloors.filter((floor: any) => floor._id !== item.key));
                                        } else {
                                            setSelectedFloors([...selectedFloors, floors.find((floor: any) => floor._id === item.key)]);
                                        }

                                        console.log('Selected floors >>>', selectedFloors);
                                    }}
                                    styles={{
                                        dropdown: { width: 300 },
                                    }}
                                />
                                :
                                <TextField label="Floor name" value={floorName} onChange={(_: any, floorName: any) => setFloorName(floorName)}
                                    onGetErrorMessage={(floorName: any) => {
                                        return floorName ? '' : t('settingsPanel.projectsPanel.enterFloorName');
                                    }}
                                    validateOnFocusOut></TextField>
                            }

                        </>
                    }
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!isBuildingPartComplete()} text={t('global.add')} onClick={async () => {
                    let newSites: Site[] = [];

                    if (buildingPart === BuildingPart.wholeBuilding) {
                        let newSite: any = await userAPI.queryOne(new Query(new EntityQuery(Site.type, [], undefined,
                            and(is('street').equal(address.street), is('streetNumber').equal(address.streetNumber), is('postalCode').equal(address.postalCode), is('city').equal(address.city), is('country').equal(address.country)))));

                        if (!newSite) {
                            newSite = await userAPI.createSite(authentication.customer, new Site({ name: siteName, street: address.street, streetNumber: address.streetNumber, postalCode: address.postalCode, city: address.city, country: address.country }));
                        }

                        newSite.buildings = await userAPI.query(from(Building.type).where(is('id').equal(`${newSite.street} ${newSite.streetNumber}, ${newSite.postalCode} ${newSite.city}, ${newSite.country}`)));

                        newSites = [...sites, newSite];

                        selectedProject.sites = newSites.map((site: Site) => site._id);

                        await updateProject(selectedProject);
                    } else {
                        // TODO Create floor from floor name
                        // const floors = floorName ? [{ name: floorName }] : selectedFloors;
                        //object = { buildingPart, building, floors, address };
                    }

                    // TODO Check duplicates

                    setSites(newSites);
                    refreshRoles(newSites);
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} />
                <DefaultButton onClick={() => {
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!partnerSelectDialogOpen}
            onDismiss={() => setPartnerSelectDialogOpen(!partnerSelectDialogOpen)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.selectPartner'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>{t('settingsPanel.projectsPanel.company')}</Label>
                    <LegalPersonPicker setLegalPerson={(legalPerson: any) => setNewSupplier(legalPerson)} />
                </div>
                <div className='marginTopM textL'>
                    {t('settingsPanel.projectsPanel.primaryContact')}
                </div>
                <div className='marginTopM textS'>
                    {t('settingsPanel.projectsPanel.primaryContactDetails')}
                </div>
                <div className='marginTopM'>
                    <TextField required label={t('settingsPanel.projectsPanel.primaryContactEmail')} value={emailPrimaryContact} onChange={(_: any, emailPrimaryContact: any) => {
                        setEmailPrimaryContact(emailPrimaryContact);
                    }} onGetErrorMessage={(emailPrimaryContact: any) => {
                        return emailPrimaryContact && isValidEmail(emailPrimaryContact) ? '' : t('settingsPanel.projectsPanel.enterValidEmail');
                    }}
                        validateOnFocusOut></TextField>
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!newSupplier || !isNewSupplierValid()} text={t('global.add')} onClick={async () => {
                    const customer: any = await userAPI.queryOne(from(Customer.type).where(is('id').equal(authentication.
                        customer)));
                    const legalPerson: any = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(customer.legalPerson)));
                    let newPartnership = await userAPI.queryOne(from(Partnership.type).where(and(is('customer').equal(legalPerson._id), is('supplier').equal(newSupplier._id))));

                    if (!newPartnership) {
                        newPartnership = await userAPI.createPartnership(authentication.customer, new Partnership({
                            customer: legalPerson._id,
                            supplier: newSupplier._id,
                            emailPrimaryContact,
                        }));
                    }

                    // TODO Patch should be done via relationships on server

                    // @ts-ignore
                    newPartnership.customer = legalPerson;
                    // @ts-ignore
                    newPartnership.supplier = newSupplier;

                    setPartnerships([...partnerships, newPartnership]);
                    setNewSupplier(null);
                    setPartnerSelectDialogOpen(false);
                }} />
                <DefaultButton onClick={() => {
                    setPartnerSelectDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!addDocumentDialogOpen}
            onDismiss={() => setAddDocumentDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.addDocument'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                {selectedFile ?
                    <div>
                        <div className="displayFlex gapL">
                            <MIMEIcon mimeType={selectedFile.type}></MIMEIcon>
                            <div>
                                <div className='textS fontWeightBold'>{selectedFile.name}</div>
                                <div className='textS colorGrey'>{dayjs(selectedFile.lastModifiedDate).format(t('global.dateShort'))}</div>
                                <div className='textS colorPrimary'>{numberFormat.format(selectedFile.size / 1000000)} MB</div>
                            </div>
                        </div>
                        <div className='marginTopL'>
                            <TextField required label={t('settingsPanel.projectsPanel.title')} value={documentTitle} onChange={(e: any, title: any) => setDocumentTitle(title)}
                            />

                            <Dropdown
                                label={t('settingsPanel.projectsPanel.documentType')}
                                options={documentTypeOptions}
                                selectedKey={documentType}
                                onChange={(e: any, item: any) => setDocumentType(item.key)}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            />
                        </div>
                        {/* <div className='marginTopM'>
                            <Dropdown
                                options={[{ key: '_self', text: 'Nur eigenes Unternehmen' }, { key: '_all', text: 'All' }, ...partnerships.map((entitlement: any) => { return { key: entitlement.partner._id, text: entitlement.partner.name } })]}
                                selectedKeys={documentVisibility}
                                multiSelect
                                onChange={(e: any, item: any) => {
                                    if (documentVisibility.indexOf(item.key) >= 0) {
                                        if (item.key !== '_self' && item.key !== '_all') {
                                            setDocumentVisibility(documentVisibility.filter((key: string) => key !== item.key));
                                        }
                                    } else {
                                        if (item.key === '_self' || item.key === '_all') {
                                            setDocumentVisibility([item.key]);
                                        } else {
                                            setDocumentVisibility([...documentVisibility.filter((key: string) => key !== '_self' && key !== '_all'), item.key]);
                                        }
                                    }
                                }}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            /></div> */}
                    </div>
                    :
                    <></>
                }
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton onClick={async () => {
                    setUploadingDocument(true);

                    try {
                        const document = await userAPI.uploadDocument(authentication.customer, selectedFile, documentTitle, documentType as DocumentType);
                        const newDocuments = [...documents, document];

                        selectedProject.documents = newDocuments.map((document: DocumentDetails) => document._id);

                        await updateProject(selectedProject);

                        setDocuments(newDocuments);
                    } finally {
                        setUploadingDocument(false);
                        setAddDocumentDialogOpen(false);
                    }
                }}><span>{t('global.add')}</span><span className='marginLeftS'>{uploadingDocument ? <Spinner size={SpinnerSize.xSmall} /> : false}</span></PrimaryButton>
                <DefaultButton onClick={() => {
                    setAddDocumentDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}

const ArrowIcon = ({ isOpen, className }: { isOpen: any, className: string }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );

    return <IoMdArrowDropright className={classes} />;
};