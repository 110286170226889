import { ActionButton } from "@fluentui/react";
import { useState } from "react";
import { useCustomerTheme } from "../Theme";

export enum ItemType {
    item = "item",
    heading = "heading",
}
export interface SideBarItem {
    type: ItemType;
    key: string;
    text: String;
    iconName?: string;
    action?: () => void;
}

interface Properties {
    items: SideBarItem[];
    setItem?: (item: string) => void;
    shown: boolean;
    shownChanged?: (shown: boolean) => void;
}

export default function SideBar(properties: Properties) {
    const { theme } = useCustomerTheme();
    const [openPanel, setOpenPanel] = useState(true);
    const [selectedItem, setSelectedItem] = useState(properties.items.length > 0 ? properties.items[0] : undefined) as any;

    return <>{properties.shown
        ?
        <>
            <div className="sideBar borderRightNeutral" style={{ backgroundColor: theme.primaryDark, color: 'white' }}>
                <div className="displayFlex justifyContentEnd">
                    <ActionButton iconProps={{ iconName: 'Pinned', styles: { root: { color: 'white' } } }} onClick={() => {
                        if (properties.shownChanged) {
                            properties.shownChanged(!properties.shown);
                        }
                    }}>
                    </ActionButton>
                </div>
                {properties.items.map((item: SideBarItem, index: number) => {
                    return <>{item.type === ItemType.heading
                        ?
                        <div id={`sideBarItem${index}`} key={`sideBarItem${index}`} className="paddingS fontWeightBold">{item.text}</div>
                        :
                        <div id={`sideBarItem${index}`} key={`sideBarItem${index}`} className={`sideBarItem`}
                            onMouseEnter={() => {
                                // @ts-ignore
                                document.getElementById(`sideBarItem${index}`).style.backgroundColor = theme.primary;
                            }}
                            onMouseLeave={() => {
                                // @ts-ignore
                                document.getElementById(`sideBarItem${index}`).style.backgroundColor = item.key === selectedItem.key ? theme.primary : theme.primaryDark;
                            }}
                            style={{ backgroundColor: item.key === selectedItem.key ? theme.primary : theme.primaryDark }}
                            onClick={() => {
                                setSelectedItem(item);

                                if (properties.setItem) {
                                    properties.setItem(item.key);
                                }

                                if (item.action) {
                                    item.action();
                                }
                            }}>
                            <ActionButton iconProps={{ iconName: item.iconName, styles: { root: { color: 'white' } } }}>
                                <span className="colorWhite">{item.text}</span>
                            </ActionButton>
                        </div>
                    }</>
                })}
            </div></>
        :
        <></>}</>;
}