import { makeStyles, shorthands } from "@fluentui/react-components";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { IconButton } from '@fluentui/react/lib/Button';
import { mergeStyles } from '@fluentui/react';
import { useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { userAPI } from "../UserAPI";

const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', 'lightgrey'),
        ...shorthands.padding('7px'),
        backgroundColor: 'white',
    },
    chart: {
        width: '100%',
    }
});

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

export const ImageResource = ({ resource }) => {
    const styles = useStyles();
    const [expanded, setExpanded] = useState(false);

    // Apply color scheme

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Image" iconName="ImagePixel" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
        </div>
        {expanded
            ?
            <div className="paddingM">
                <div className="displayFlex justifyContentCenter">
                    <img src={userAPI.getResourceURL(resource.url)} />
                </div>
                <div className="marginTopM textM">
                    <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{resource.details}</Markdown>
                    </div>
            </div>
            :
            <></>
        }
    </div>;
}
