import { makeStyles, shorthands } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { IconButton } from '@fluentui/react/lib/Button';
import Map from '../Map';
import { useAtom } from 'jotai'
import { mapQueryAtom } from "../State";
import { mapDataAtom } from "../State";
import { mapOptionsAtom } from "../State";
import { selectedEntityAtom } from "../State";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { userAPI } from '../UserAPI';
import { PlotOfLand, Building, LocalGovernment, Area } from "@the-real-insight/tri-model";

const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', '#EFEFEF'),
        ...shorthands.padding('10px'),
        backgroundColor: 'white',
    },
    titleBar: {
        display: 'flex',
        alignItems: 'center'
    },
    expandButton: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'end'
    },
    table: {
        fontSize: '10px',
        borderCollapse: 'collapse',
        width: '100%'
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'black'),
        textAlign: 'right',
    },
    cell: {
        textAlign: 'right',
    }
});

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

export const MapResource = ({ resource }) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const [, setMapQuery] = useAtom(mapQueryAtom);
    const [, setMapData] = useAtom(mapDataAtom);
    const [, setMapOptions] = useAtom(mapOptionsAtom);
    const [, setSelectedEntity] = useAtom(selectedEntityAtom);
    const [expanded, setExpanded] = useState(false);

    // TODO More on client-side queries

    useEffect(() => {
        // TODO We do typing here but the real solution is to ship the queries from the server and perform those here.

        if (resource.data.plotsOfLand) {
            resource.data.plotsOfLand = resource.data.plotsOfLand.map((properties) => new PlotOfLand(properties));
        }

        if (resource.data.buildings) {
            // TODO Hack as we ship untyped data from client. Need concept.

            resource.data.buildings = resource.data.buildings.map((properties) => {
                const requiredFireExtinguishers = properties.requiredFireExtinguishers;
                const building = new Building(properties);

                building.requiredFireExtinguishers = requiredFireExtinguishers;

                return building;
            });
        }

        if (resource.data.localGovernments) {
            resource.data.localGovernments = resource.data.localGovernments.map((properties) => new LocalGovernment(properties));
        }

        if (resource.data.areas) {
            resource.data.areas = resource.data.areas.map((properties) => new Area(properties));
        }

        const process = async () => {
            if (resource && resource.queries && resource.queries.length > 0) {
                // const result = await userAPI.query(resource.queries[0]);

                // console.log('QQQQQQQUUUUERY >>>', result);
            }
        };

        // TODO Hack
        resource.options.title = resource.title;

        process();
    }, [resource]);

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Map" iconName="MapPin" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
            <div className={styles.expandButton}>
                <IconButton iconProps={{ iconName: 'MiniExpand' }} title="Expand" ariaLabel="Expand" onClick={() => { setMapData(resource.data); setMapQuery(resource.query); setMapOptions(resource.options); navigate("/map") }} />
            </div>
        </div>
        {expanded
            ?
            <div className="marginTopL">
                <Map objectClass={resource.query.objectClass} data={resource.data} query={resource.query} options={resource.options}
                    setObject={(entity) => {
                        setMapData(resource.data);
                        setMapQuery(resource.query);
                        setMapOptions(resource.options);
                        setSelectedEntity(entity);
                        navigate("/map")
                    }}></Map>
            </div>
            :
            <></>
        }
    </div>
}
