import dayjs from "dayjs";
import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import Section from './Section';
import { useTranslation } from "react-i18next";
import { pdf, PDFDownloadLink, Document, Page, Image, Text as PDFText, View, StyleSheet, Font } from '@react-pdf/renderer';
import { pdfStyles } from '../pdf/PDF';
import { useEffect, useState } from 'react';
import { useAdministrationAPI } from '../AdministrationAPI';
import { authenticationAtom } from '../State';
import { useAtom } from 'jotai';
import { Fragment } from "react/jsx-runtime";
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { userAPI } from '../UserAPI';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Address, Customer, from, Invoice, InvoiceStatus, InvoiceType, is, LegalPerson, Plan, Project, RelationshipDirection } from "@the-real-insight/tri-model";
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import LoadingIndicator from "./LoadingIndicator";
import { useModalContext } from "./ModalProvider";

interface Properties {
    project: Project,
    projectChanged: (project: Project) => void,
}

const mergeStyles = (...styles: any[]) => {
    let result = {};

    styles.forEach((style: any) => {

        result = { ...result, ...style };
    });

    return result;
}

const PDFInvoice = ({ invoice, customer }: { invoice: Invoice, customer: Customer }) => {
    const { t } = useTranslation();
    const styles = StyleSheet.create({
        logo: {
            width: 70,
        },
        marginTopL: {
            marginTop: '20px',
        },
        header1: {
            marginTop: '20px',
            marginBottom: '20px',
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'Lato Bold',
        },
        normal: {
            fontSize: 12,
            fontFamily: 'Lato',
            color: 'black',
        },
        textAlignRight: {
            textAlign: 'right',
        },
        bold: {
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'Lato Bold',
            color: 'black',
        },
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: 16,
        },
        row: {
            flexDirection: 'row',
            width: '100%',
        },
        tableHeaderRow: {
            flexDirection: 'row',
            width: '100%',
            borderBottom: "1px solid grey"
        },
        itemGroupRow: {
            flexDirection: 'row',
            width: '100%',
            marginTop: "10px",
            marginBottom: "10px",
            paddingLeft: '5px',
            paddingRight: '5px',
        },
        totalRow: {
            flexDirection: 'row',
            width: '100%',
            borderTop: "1px solid grey"
        },
        footerRow: {
            flexDirection: 'row',
            width: '100%',
            borderBottom: "1px solid grey"
        },
        width10: {
            width: '10%',
        },
        width15: {
            width: '15%',
        },
        width20: {
            width: '20%',
        },
        width30: {
            width: '30%',
        },
        width40: {
            width: '40%',
        },
        width50: {
            width: '50%',
        },
        width70: {
            width: '70%',
        },
        column50: {
            width: '50%',
        },
        width85: {
            width: '85%',
        },
        width90: {
            width: '90%',
        },
        nameCell: {
            width: '30%',
        },
        valueCell: {
            width: '30%',
        },
        cell: {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '2px',
            paddingBottom: '2px',
        },
        columnEnd: {
        },
        address: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    });

    const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    const numberFormat = new Intl.NumberFormat('de-DE');
    const amountFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 4, maximumFractionDigits: 4 });

    let pos = 0;

    try {
        return <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View style={styles.row}>
                    <View style={styles.width70}>
                        <Image style={styles.logo} src={'/logo.png'}></Image>
                    </View>
                    <View style={styles.width30}>
                        <View style={styles.columnEnd}>
                            <View style={styles.address}>
                                <PDFText style={styles.bold}>{invoice.seller.name}</PDFText>
                                <PDFText style={styles.normal}>{invoice.seller.headquarters.street} {invoice.seller.headquarters.streetNumber}</PDFText>
                                <PDFText style={styles.normal}>{invoice.seller.headquarters.postalCode} {invoice.seller.headquarters.city}</PDFText>
                                {/* <PDFText style={styles.normal}>Steuernummer 11</PDFText>
                                <PDFText style={styles.normal}>UStId 4711</PDFText> */}
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ ...styles.row, ...styles.marginTopL }}>
                    <PDFText style={styles.bold}>{invoice.buyer.name}</PDFText>
                </View>
                <View style={styles.row}>
                    <PDFText style={styles.normal}>{invoice.buyer.headquarters.street} {invoice.buyer.headquarters.streetNumber}</PDFText>
                </View>
                <View style={styles.row}>
                    <PDFText style={styles.normal}>{invoice.buyer.headquarters.postalCode} {invoice.buyer.headquarters.city}</PDFText>
                </View>
                <View style={styles.row}>
                    <PDFText style={styles.normal}>UStId DE124356789</PDFText>
                </View>
                <PDFText style={styles.header1}>Rechnung {invoice.periodFromDate.getMonth() + 1}/{invoice.periodFromDate.getFullYear()}</PDFText>
                <View style={styles.row}>
                    <View style={styles.width30}>
                        <PDFText style={styles.bold}>
                            Rechnungsnummer:
                        </PDFText>
                    </View>
                    <View style={styles.width30}>
                        <PDFText style={styles.normal}>
                            {invoice.id}
                        </PDFText>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.width30}>
                        <PDFText style={styles.bold}>
                            Kundennummer:
                        </PDFText>
                    </View>
                    <View style={styles.valueCell}>
                        <PDFText style={styles.normal}>
                            {customer.id}
                        </PDFText>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.width30}>
                        <PDFText style={styles.bold}>
                            Abrechnungszeitraum:
                        </PDFText>
                    </View>
                    <View style={styles.width30}>
                        <PDFText style={styles.normal}>
                            {dayjs(invoice.periodFromDate).format(t('global.dateShort'))} - {dayjs(invoice.periodToDate).format(t('global.dateShort'))}
                        </PDFText>
                    </View>
                </View>
                <View style={{ ...styles.tableContainer, ...styles.marginTopL }}>
                    <View style={styles.tableHeaderRow}>
                        <View style={mergeStyles(styles.width10, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Pos.</PDFText></View>
                        <View style={mergeStyles(styles.width40, styles.cell)}><PDFText style={styles.bold}>Beschreibung</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Menge</PDFText></View>
                        <View style={mergeStyles(styles.width20, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Betrag</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Gesamt</PDFText></View>
                    </View>
                    <Fragment>
                        {invoice.itemGroups.map((itemGroup: any) => {
                            return <Fragment>
                                <View style={styles.itemGroupRow}>
                                    <PDFText style={styles.bold}>{itemGroup.name}</PDFText>
                                </View>
                                {itemGroup.items.map((item: any) => {
                                    ++pos;

                                    return <View style={pdfStyles.row}>
                                        <View style={mergeStyles(styles.width10, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{pos}</PDFText></View>
                                        <View style={mergeStyles(styles.width40, styles.cell)}><PDFText style={styles.normal}>{item.description}</PDFText></View>
                                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{numberFormat.format(item.volume)}</PDFText></View>
                                        <View style={mergeStyles(styles.width20, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{amountFormat.format(item.amount)}</PDFText></View>
                                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{currencyFormat.format(item.totalAmount)}</PDFText></View>
                                    </View>;
                                })}
                            </Fragment>;
                        })}
                    </Fragment>
                    <View style={styles.totalRow}>
                        <View style={mergeStyles(styles.width85, styles.cell)}><PDFText style={styles.bold}>Gesamtbetrag (netto)</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>{currencyFormat.format(invoice.subtotal())}</PDFText></View>
                    </View>
                    <View style={styles.row}>
                        <View style={mergeStyles(styles.width85, styles.cell)}><PDFText style={styles.normal}>MwSt.</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{currencyFormat.format(invoice.grandTotal(0.19) - invoice.subtotal())}</PDFText></View>
                    </View>
                    <View style={styles.footerRow}>
                        <View style={mergeStyles(styles.width85, styles.cell)}><PDFText style={styles.bold}>Gesamtbetrag (brutto)</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>{currencyFormat.format(invoice.grandTotal(0.19))}</PDFText></View>
                    </View>
                </View>
                <View style={mergeStyles(styles.row, styles.marginTopL)}>
                    <PDFText style={styles.normal}>Zahlbar innerhalb von 14 Tagen ohne Abzug.</PDFText>
                </View>
                <View style={mergeStyles(styles.row, styles.marginTopL)}>
                    <PDFText style={styles.normal}>Bankverbindung: Konto IBAN 47011100001, Sparkasse Limburg</PDFText>
                </View>
            </Page>
        </Document >
    } catch (error) {
        console.log(error);

        return <Document><Page><PDFText>Error in Invoice Creation.</PDFText></Page></Document>
    }
};

const generateEInvoice = (invoice: Invoice) => {
    console.log('Invoice >>>', invoice);

    let xml = `<?xml version="1.0" encoding="UTF-8"?>
<ubl:Invoice xmlns:ubl="urn:oasis:names:specification:ubl:schema:xsd:Invoice-2"
             xmlns:cac="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2"
             xmlns:cbc="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">
    <cbc:CustomizationID>urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0</cbc:CustomizationID>
    <cbc:ProfileID>urn:fdc:peppol.eu:2017:poacc:billing:01:1.0</cbc:ProfileID>
    <cbc:ID>${invoice.id}</cbc:ID>
    <cbc:IssueDate>${invoice.creationDate}</cbc:IssueDate>
    <cbc:InvoiceTypeCode>380</cbc:InvoiceTypeCode>
    <cbc:Note>#ADU#Wir liefern Ihnen ausschließlich zu unseren allgemeinen Geschäftsbedingungen (Stand Februar 2025). Diese werden Ihnen jederzeit auf Wunsch übersandt. Bei Aufträgen, basierend auf öffentlichen Vergaben, gelten die ggf. vereinbarten abweichenden Liefer- und Zahungsbedingungen des Auftraggebers.</cbc:Note>
    <cbc:DocumentCurrencyCode>EUR</cbc:DocumentCurrencyCode>
    <cbc:BuyerReference>04011000-12350-85</cbc:BuyerReference>
    <cac:OrderReference>
        <cbc:ID>Dummywert</cbc:ID>
        <cbc:SalesOrderID>Auftragsnummer</cbc:SalesOrderID>
    </cac:OrderReference>
    <cac:AccountingSupplierParty>
        <cac:Party>
            <cbc:EndpointID schemeID="EM">invoicing@fireiq.it</cbc:EndpointID>
            <cac:PartyName>
                <cbc:Name>${invoice.seller.name}</cbc:Name>
            </cac:PartyName>
            <cac:PostalAddress>
                <cbc:StreetName>${(invoice.seller as any).headquarters.street}</cbc:StreetName>
                <cbc:CityName>${(invoice.seller.headquarters as any).city}</cbc:CityName>
                <cbc:PostalZone>${(invoice.seller.headquarters as any).postalCode}</cbc:PostalZone>
                <cac:Country>
                    <cbc:IdentificationCode>DE</cbc:IdentificationCode>
                </cac:Country>
            </cac:PostalAddress>
            <cac:PartyTaxScheme>
                <cbc:CompanyID>DE123456789</cbc:CompanyID>
                <cac:TaxScheme>
                    <cbc:ID>VAT</cbc:ID>
                </cac:TaxScheme>
            </cac:PartyTaxScheme>
            <cac:PartyLegalEntity>
                <cbc:RegistrationName>${invoice.seller.name}</cbc:RegistrationName>
                <cbc:CompanyID>${invoice.seller.tradeRegisterId}</cbc:CompanyID>
                <cbc:CompanyLegalForm>Sitz der Gesellschaft: ${invoice.seller.tradeRegisterCity}, Registergericht: ${invoice.seller.tradeRegisterCity}, Geschäftsführer: </cbc:CompanyLegalForm>
            </cac:PartyLegalEntity>
            <cac:Contact>
                <cbc:Name>Berater / Sachbearbeiter</cbc:Name>
                <cbc:Telephone>+49(0)1234-0</cbc:Telephone>
                <cbc:ElectronicMail>info@seller.de</cbc:ElectronicMail>
            </cac:Contact>
        </cac:Party>
    </cac:AccountingSupplierParty>
    <cac:AccountingCustomerParty>
        <cac:Party>
            <cbc:EndpointID schemeID="EM">judith@null-isiko.de</cbc:EndpointID>
            <cac:PostalAddress>
                <cbc:StreetName>${invoice.billingAddress.street} ${invoice.billingAddress.streetNumber}</cbc:StreetName>
                <cbc:CityName>${invoice.billingAddress.city}</cbc:CityName>
                <cbc:PostalZone>${invoice.billingAddress.postalCode}</cbc:PostalZone>
                <cac:Country>
                    <cbc:IdentificationCode>DE</cbc:IdentificationCode>
                </cac:Country>
            </cac:PostalAddress>
            <cac:PartyLegalEntity>
                <cbc:RegistrationName>${invoice.buyer.name}</cbc:RegistrationName>
            </cac:PartyLegalEntity>
            <cac:Contact>
                <cbc:Name>Ansprechpartner Käufer</cbc:Name>
            </cac:Contact>
        </cac:Party>
    </cac:AccountingCustomerParty>
    <cac:Delivery>
        <cac:DeliveryLocation>
            <cac:Address>
                <cbc:StreetName>${invoice.billingAddress.street} ${invoice.billingAddress.streetNumber}</cbc:StreetName>
                <cbc:CityName>${invoice.billingAddress.city}</cbc:CityName>
                <cbc:PostalZone>${invoice.billingAddress.postalCode}</cbc:PostalZone>
                <cac:Country>
                    <cbc:IdentificationCode>DE</cbc:IdentificationCode>
                </cac:Country>
            </cac:Address>
        </cac:DeliveryLocation>
        <cac:DeliveryParty>
            <cac:PartyName>
                <cbc:Name>Außenstelle XY</cbc:Name>
            </cac:PartyName>
        </cac:DeliveryParty>
    </cac:Delivery>
    <cac:PaymentMeans>
        <cbc:PaymentMeansCode>58</cbc:PaymentMeansCode>
        <cac:PayeeFinancialAccount>
            <cbc:ID>DE75512108001245126199</cbc:ID>
        </cac:PayeeFinancialAccount>
    </cac:PaymentMeans>
    <cac:PaymentTerms>
        <cbc:Note></cbc:Note>
    </cac:PaymentTerms>
    <cac:TaxTotal>
        <cbc:TaxAmount currencyID="EUR">414.2</cbc:TaxAmount>
        <cac:TaxSubtotal>
            <cbc:TaxableAmount currencyID="EUR">2180</cbc:TaxableAmount>
            <cbc:TaxAmount currencyID="EUR">414.2</cbc:TaxAmount>
            <cac:TaxCategory>
                <cbc:ID>S</cbc:ID>
                <cbc:Percent>19</cbc:Percent>
                <cac:TaxScheme>
                    <cbc:ID>VAT</cbc:ID>
                </cac:TaxScheme>
            </cac:TaxCategory>
        </cac:TaxSubtotal>
    </cac:TaxTotal>
    <cac:LegalMonetaryTotal>
        <cbc:LineExtensionAmount currencyID="EUR">${invoice.subtotal()}</cbc:LineExtensionAmount>
        <cbc:TaxExclusiveAmount currencyID="EUR">2180</cbc:TaxExclusiveAmount>
        <cbc:TaxInclusiveAmount currencyID="EUR">2594.2</cbc:TaxInclusiveAmount>
        <cbc:PayableAmount currencyID="EUR">${invoice.grandTotal(0.19)}</cbc:PayableAmount>
    </cac:LegalMonetaryTotal>`;

    for (const itemGroup of invoice.itemGroups) {
        for (const item of itemGroup.items) {
            xml += `<cac:InvoiceLine>
        <cbc:ID>1</cbc:ID>
        <cbc:InvoicedQuantity unitCode="XPP">${item.volume}</cbc:InvoicedQuantity>
        <cbc:LineExtensionAmount currencyID="EUR">2180</cbc:LineExtensionAmount>
        <cac:Item>
            <cbc:Description>${item.description}</cbc:Description>
            <cbc:Name></cbc:Name>
            <cac:SellersItemIdentification>
                <cbc:ID>AL281982</cbc:ID>
            </cac:SellersItemIdentification>
            <cac:ClassifiedTaxCategory>
                <cbc:ID>S</cbc:ID>
                <cbc:Percent>19</cbc:Percent>
                <cac:TaxScheme>
                    <cbc:ID>VAT</cbc:ID>
                </cac:TaxScheme>
            </cac:ClassifiedTaxCategory>
        </cac:Item>
        <cac:Price>
            <cbc:PriceAmount currencyID="EUR">${item.amount}</cbc:PriceAmount>
        </cac:Price>
    </cac:InvoiceLine>`;
        }
    }

    xml += `</ubl:Invoice>`;

    return xml;
}

export default function InvoicePanel(properties: Properties) {
    const { t } = useTranslation();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { pushError } = useModalContext() as any;
    const { getInvoiceForecast, getInvoices } = useAdministrationAPI();
    const [customer, setCustomer] = useState() as any;
    const [order, setOrder] = useState() as any;
    const [currentBillingPeriodFrom, setCurrentBillingPeriodFrom] = useState(dayjs().startOf('M').toDate()) as any;
    const [currentBillingPeriodTo, setCurrentBillingPeriodTo] = useState(dayjs().endOf('M').toDate()) as any;
    const [currentInvoice, setCurrentInvoice] = useState() as any;
    const [currentInvoicePDF, setCurrentInvoicePDF] = useState() as any;
    const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    const [planDialogOpen, setPlanDialogOpen] = useState(false);
    const [orders, setOrders] = useState([]) as any;
    const [invoices, setInvoices] = useState([]) as any;
    const [plans, setPlans] = useState() as any;
    const [portalAndAppUsagePlan, setPortalAndAppUsagePlan] = useState() as any;
    const [privateDataIngestionPlan, setPrivateDataIngestionPlan] = useState() as any;

    const portalAndAppUsageItems = (): IDropdownOption[] => plans && plans.portalAndAppUsage ? plans.portalAndAppUsage.map((plan: any) => {
        return {
            key: plan.id,
            text: plan.name
        };
    }) : [];

    const privateDataIngestionItems = (): IDropdownOption[] => plans && plans.privateDataIngestion ? [{ key: null, text: t('global.none') }, ...plans.privateDataIngestion.map((plan: any) => {
        return {
            key: plan.id,
            text: plan.name
        };
    })] : [];

    const planSelectionComplete = (): boolean => {
        return portalAndAppUsagePlan !== undefined;
    };

    const downloadInvoice = (invoice: Invoice) => {
        const element = document.createElement("a");
        const file = new Blob([generateEInvoice(invoice)], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "Abrechnung.xml";
        document.body.appendChild(element);
        element.click();
    };

    const findPlan = (plans: any, id: string) => plans.find((plan: any) => plan.id === id);

    useEffect(() => {
        if (!properties.project) {
            return;
        }

        const call = async () => {
            const newCustomer = await userAPI.getCustomer(authentication.customer);

            newCustomer.address = newCustomer.address || {};

            setCustomer(newCustomer);

            // Current service orders

            const newOrder = await userAPI.fullOrderForProject(authentication.customer, properties.project._id as any);

            console.log('Order >>>', newOrder);

            if (!newOrder) {
                throw 'No order for project';
            }

            const periodFromDate = newOrder.startOfBillingPeriod();
            const periodToDate = newOrder.endOfBillingPeriod();

            console.log('Period from >>>', periodFromDate);
            console.log('Period to >>>', periodToDate);

            setOrder(newOrder);

            console.log('Project >>>', properties.project);
            console.log('Service Orders >>>', newOrder);

            setCurrentBillingPeriodFrom(periodFromDate);
            setCurrentBillingPeriodTo(periodToDate);

            const invoiceForecast = properties.project ? await userAPI.getInvoiceForecastForProject(authentication.customer, properties.project._id as any,
                periodFromDate, periodToDate) : await getInvoiceForecast(authentication.customer,
                    periodFromDate, periodToDate);

            console.log('Invoice forecast >>>', invoiceForecast);

            setCurrentInvoice(invoiceForecast);

            if (customer && invoiceForecast) {
                setCurrentInvoicePDF(<PDFInvoice invoice={invoiceForecast} customer={customer}></PDFInvoice>);
            }

            const newInvoices = (await getInvoices(authentication.customer, new Date('2024-05-01T00:00:00'), new Date()))
                .sort((a: Invoice, b: Invoice) => b.periodFromDate.getTime() - a.periodFromDate.getTime());

            console.log('Invoices >>>', newInvoices);

            setInvoices(newInvoices);
        };

        try {
            call();
        } catch (error: any) {
            pushError(error);
        }
    }, [properties.project]);

    const togglePlanDialogOpen = () => {
        setPlanDialogOpen(!planDialogOpen);
    }

    const downloadFile = (blob: Blob, fileName: string) => {
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Some browser needs the anchor to be in the doc

        document.body.append(link);
        link.click();
        link.remove();

        // In case the Blob uses a lot of memory

        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    const columns = [{
        name: t('billingPanel.paymentStatusColumn'),
        width: '100px',
        cell: (invoice: Invoice) => (
            <div className="width50 paddingS displayFlex">
                <div className={(invoice.status === InvoiceStatus.paid ? 'colorGreen' : 'colorRed') + ' fontWeightBold textM'}>{invoice.status === InvoiceStatus.paid ? 'Bezahlt' : 'Offen'}</div>
            </div>
        ),
    }, {
        name: t('billingPanel.invoiceDateColumn'),
        width: '150px',
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{dayjs(invoice.creationDate).format(t('global.dateShort'))}</Text>
        ),
    }, {
        name: t('billingPanel.invoicePeriodColumn'),
        width: '200px',
        cell: (invoice: Invoice) => (
            <Text variant="medium">{dayjs(invoice.periodFromDate).format(t('global.dateShort'))} - {dayjs(new Date(invoice.periodToDate)).format(t('global.dateShort'))}</Text>
        ),
    }, {
        name: t('billingPanel.amountColumn'),
        width: '120px',
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{currencyFormat.format(invoice.subtotal())}</Text>
        ),
    }, {
        name: t('billingPanel.downloadColumn'),
        width: '100px',
        cell: (invoice: any) => (
            <div className='displayFlex'>
                <TooltipHost content="Download invoice forecast PDF">
                    <ActionButton iconProps={{ iconName: 'FileCode' }} onClick={() => downloadInvoice(invoice)}>
                    </ActionButton>
                </TooltipHost>
                <TooltipHost content="Download invoice forecast as E-Rechnung">
                    <ActionButton iconProps={{ iconName: 'PDF' }} onClick={async () => {
                        const blob = await pdf(<PDFInvoice invoice={invoice} customer={customer}></PDFInvoice>).toBlob();

                        console.log(blob);

                        downloadFile(blob, `Rechnung ${invoice.id}`);
                    }}>
                    </ActionButton>
                </TooltipHost>
            </div>
        ),
    },
    ];

    return <><div>
        <div className='displayFlex gapL'>
            <div className="displayFlex flexDirectionColumn width800 gapL">
                <Section title={`Aktueller Abrechnungszeitraum ${dayjs(currentBillingPeriodFrom).format(t('global.dateShort'))} - ${dayjs(currentBillingPeriodTo).format(t('global.dateShort'))}`}>
                    {currentInvoice
                        ?
                        <>
                            <div className='displayFlex justifyContentEnd'>
                                <TooltipHost content="Download invoice forecast PDF">
                                    <ActionButton iconProps={{ iconName: 'PDF' }} onClick={async () => {
                                        const blob = await pdf(<PDFInvoice invoice={currentInvoice} customer={customer}></PDFInvoice>).toBlob();

                                        console.log(blob);

                                        downloadFile(blob, `Rechnung ${currentInvoice.id}`);
                                    }}>
                                    </ActionButton>
                                </TooltipHost>
                                <TooltipHost content="Download invoice forecast as E-Rechnung">
                                    <ActionButton iconProps={{ iconName: 'FileCode' }} onClick={() => downloadInvoice(currentInvoice)}>
                                    </ActionButton>
                                </TooltipHost>
                            </div>
                            <div className='displayFlex alignItemsCenter gapS'>
                                <Text className="fontWeightBold" variant="medium">Bisheriger Betrag</Text>
                                <Text variant="xxLarge">{currencyFormat.format(currentInvoice.grandTotal(0.19))}</Text>
                            </div>
                            <div className='marginTopL displayFlex'>
                                {invoices && invoices.length > 0
                                    ?
                                    <div className='flexGrow1'>
                                        <div>
                                            <Text className="fontWeightBold" variant="medium">Letzte Rechnung bis</Text>
                                        </div>
                                        <div>
                                            <Text variant="medium">{dayjs(invoices[0].periodToDate).format(t('global.dateShort'))}</Text>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='marginTopL displayFlex justifyContentEnd'>
                                {currentInvoicePDF ?
                                    <PDFDownloadLink document={currentInvoicePDF} fileName="Abrechnung Juli 2024.pdf">
                                        <>{({ blob, url, loading, error }: any) =>
                                            loading
                                                ?
                                                'Loading document...'
                                                :
                                                <PrimaryButton iconProps={{ iconName: 'Download' }}>
                                                    Rechnungsvorschau
                                                </PrimaryButton>
                                        }</>
                                    </PDFDownloadLink>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                        :
                        <></>}
                </Section>
                <Section title={t('billingPanel.invoices')}>
                    <div className='displayFlex justifyContentEnd'>
                        <ActionButton onClick={async () => {
                            console.log('Creating missing imnvoices');
                            // @ts-ignore
                            await userAPI.createMissingInvoicesForProject(authentication.customer, properties.project._id);
                        }}>
                            {t('billingPanel.createInvoice')}
                        </ActionButton>
                    </div>
                    <DataTable
                        columns={columns}
                        data={invoices}
                        customStyles={dataTableStyles}
                        pagination
                        noDataComponent={<LoadingIndicator loading={false} text={t('settingsPanel.projectsPanel.noObjectsDefined')}></LoadingIndicator>}
                    />
                </Section>
            </div>
            <div className='displayFlex flexDirectionColumn gapL width30vw'>
                <Section title="Abrechnungsmodalitäten">
                    <Dropdown
                        label="Rechnungsart"
                        options={[
                            { key: 'direct', text: 'Direkt an Endkunden' },
                            { key: 'indirect', text: 'Indirekt über Partner' }]}
                        selectedKey={properties.project.invoiceType}
                        onChange={(_: any, option: any) => { properties.projectChanged(new Project({ ...properties.project, invoiceType: option.key })) }}
                        styles={{
                            dropdown: { width: 300 },
                        }}
                    />
                    <div className="marginTopM">
                        <Checkbox label="E-Mail an Kundenkontakt" onChange={() => { }} />
                        <Checkbox className="marginTopS" label="E-Mail an Nutzende des Partners in Rolle Abrechnung" onChange={() => { }} />
                    </div>
                </Section>
                <Section title="Aktuelle Lizenzen">
                    {order && properties.project
                        ?
                        <>
                            <ul>
                                {order.orderItems.map((orderItem: any) =>
                                    <>
                                        <li>
                                            <Text><span className='fontWeightBold'>{orderItem.service.name}:</span> {orderItem.plan.description}</Text>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </>
                        :
                        <>
                            <ul>
                                <li>
                                    <Text><div className='fontWeightBold'>Installation Digitaler Feuerlöscher</div> 120,00€</Text>
                                </li>
                                <li>
                                    <Text><div className='fontWeightBold'>Wartung Digitaler Feuerlöscher</div> 24,00€/Quartal</Text>
                                </li>
                            </ul>
                        </>
                    }
                    <div className='marginTopL displayFlex justifyContentEnd'>
                        <PrimaryButton text="Ändern" onClick={() => { togglePlanDialogOpen() }} />
                    </div>
                </Section>
            </div>
        </div>
    </div>
        <Dialog hidden={!planDialogOpen}
            onDismiss={togglePlanDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.billingPanel.licenses'),
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <Dropdown
                placeholder="Select an option"
                label="Nutzung von Portal und App"
                options={portalAndAppUsageItems()}
                styles={{
                    dropdown: { width: 400 },
                    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
                    dropdownItem: { height: 'auto' },
                }}
                selectedKey={portalAndAppUsagePlan ? portalAndAppUsagePlan.id : undefined}
                onChange={(event: any, item: any) => {
                    if (plans.portalAndAppUsage) {
                        setPortalAndAppUsagePlan(plans.portalAndAppUsage.find((plan: any) => plan.id === item.key));
                    }
                }}
            />
            <div className="marginTopS height50">
                <Text variant='small'>{portalAndAppUsagePlan ? portalAndAppUsagePlan.description : ''}</Text>
            </div>
            <Dropdown
                placeholder="Select an option"
                label="Management privater Daten"
                options={privateDataIngestionItems()}
                styles={{
                    dropdown: { width: 400 },
                    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
                    dropdownItem: { height: 'auto' },
                }}
                selectedKey={privateDataIngestionPlan ? privateDataIngestionPlan.id : undefined}
                onChange={(event: any, item: any) => {
                    if (plans.privateDataIngestion) {
                        setPrivateDataIngestionPlan(plans.privateDataIngestion.find((plan: any) => plan.id === item.key));
                    }
                }}
            />
            <div className="marginTopS  height50">
                <Text variant='small'>{privateDataIngestionPlan ? privateDataIngestionPlan.description : ''}</Text>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!planSelectionComplete()} text={t('global.change')} onClick={async () => {
                }} />
                <DefaultButton onClick={togglePlanDialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog></>;
}