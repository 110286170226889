import { Asset, EntityQuery, is, Query } from "@the-real-insight/tri-model";
import { useTranslation } from "react-i18next";
import { userAPI } from "../../UserAPI";
import { useEffect, useState } from "react";
import { TreeState, TreeTable } from "cp-react-tree-table";
import { ActionButton, SpinButton, TextField } from "@fluentui/react";
import ReactApexChart from "react-apexcharts";
import { useCustomerTheme } from "../../Theme";
import Metric from "../../components/Metric";

class Properties {
  building: any;
}

const numberFormat = new Intl.NumberFormat('de');
const numberFormat2 = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const numberFormat0 = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

export default function EnergyAndCO2Properties(properties: Properties) {
  const { t } = useTranslation();
  const [din276Metadata, setDIN276Metadata] = useState([]) as any;
  const { theme } = useCustomerTheme();
  const [totalArea, setTotalArea] = useState(33214) as any;
  const [totalEnergyConsumption, setEnergyConsumption] = useState(0) as any;
  const [totalEnergyIntensity, setEnergyIntensity] = useState(0) as any;
  const [totalCO2Consumption, setCO2Consumption] = useState(0) as any;
  const [totalCO2Intensity, setCO2Intensity] = useState(0) as any;
  const [assets, setAssets] = useState([]) as any;
  const [tree, setTree] = useState([]/*MOCK_DATA*/) as any;
  const [treeData, setTreeData] = useState(TreeState.create([]/*MOCK_DATA*/)) as any;
  const [expanded, setExpanded] = useState(false);
  const [co2Equivalent, setCO2Equivalent] = useState(0.38);
  const [colors] = useState([theme.secondary, theme.tertiary, theme.primary, theme.secondaryLight, theme.tertiaryLight, theme.primaryLight]);
  const [seriesLabels, setSeriesLabels] = useState([]) as any;
  const [totalSeries, setTotalSeries] = useState([]) as any;
  const [perCategorySeries, setPerCategorySeries] = useState([]) as any;

  useEffect(() => {
    const call = async () => {
      const din276Metadata = await userAPI.getDIN276Metadata();

      din276Metadata.forEach((category: any) => {
        if (category.code === '423.20') {
          category.energyConsumptionPerUnit = 1300;
        } else if (category.code === '431') {
          category.energyConsumptionPerArea = 210;
        } else if (category.code === '434') {
          category.energyConsumptionPerArea = 210;
        } else if (category.code === '434.10') {
          category.energyConsumptionPerArea = 210;
        } else if (category.code === '442.10') {
          category.energyConsumptionPerArea = 210;
        } else if (category.code === '445.20') {
          category.energyConsumptionPerArea = 33;
        } else if (category.code === '456') {
          category.energyConsumptionPerArea = 10;
        } else if (category.code === '456.10') {
          category.energyConsumptionPerArea = 10;
        } else if (category.code === '456.18') {
          category.energyConsumptionPerArea = 10;
        } else if (category.code === '456.50') {
          category.energyConsumptionPerArea = 55;
        } else if (category.code === '456.91') {
          category.energyConsumptionPerArea = 10;
        }
      });

      setDIN276Metadata(din276Metadata);

      const assets = await userAPI.query(new Query(new EntityQuery(Asset.type, [], undefined,
        // TODO Complete address filter
        is('postalCode').equal(properties.building.address.postalCode))));

      assets.forEach((asset: any) => {
        const category = din276Metadata.find((category: any) => category.code === asset.schemaCategory);

        if (!category) {
          asset.energyConsumption = 0;
        } else if (category.energyConsumptionPerUnit) {
          asset.energyConsumption = category.energyConsumptionPerUnit;
        } else if (category.energyConsumptionPerArea) {
          asset.energyConsumption = category.energyConsumptionPerArea * totalArea;
        } else {
          asset.energyConsumption = 0;
        }
      });

      setAssets(assets);

      const tree: any = buildTree(assets, din276Metadata);

      setTree(tree);
      setTreeData(TreeState.create(tree));
    };

    call();
  }, [properties]);

  const buildTree = (assets: any, din276Metadata: any) => {
    let newConsumptionPerCategory: any = {};
    let totalConsumption = 0;
    let totalCO2Equivalent = 0;

    const tree = assets.reduce((tree: any, asset: any) => {
      const category = asset.schemaCategory.split('.')[0];
      const categoryMetadata = din276Metadata.find((metadata: any) => metadata.code === category);

      // TODO Could also truncate
      const categoryName = categoryMetadata && categoryMetadata.name ? categoryMetadata.name.split(' ')[0] : category;

      tree[category] = tree[category] || { data: { name: `${category} ${categoryName}`, consumption: 0, co2: co2Equivalent * 4711 }, children: [] };

      let slot = tree[category].children.find((slot: any) => slot.data.category === asset.schemaCategory);

      if (!slot) {
        const subCategoryMetadata = din276Metadata.find((metadata: any) => metadata.code === asset.schemaCategory);

        if (!subCategoryMetadata) {
          console.log('Cannot find category >>>', asset.schemaCategory);

          return tree;
        }

        // TODO Could also truncate
        const subCategoryName = subCategoryMetadata.name.split(' ')[0];

        slot = { data: { category: asset.schemaCategory, name: `${asset.schemaCategory} ${subCategoryName}`, consumption: 0, accuracy: 1, co2: 0, count: 1 } };

        tree[category].children.push(slot);
      } else {
        ++slot.data.count;
      }

      slot.data.consumption += asset.energyConsumption;
      slot.data.co2 = slot.data.consumption * co2Equivalent;
      tree[category].data.consumption += asset.energyConsumption;
      tree[category].data.co2 = tree[category].data.consumption * co2Equivalent;
      totalConsumption += asset.energyConsumption;
      totalCO2Equivalent = totalConsumption * co2Equivalent;

      setEnergyConsumption(totalConsumption / 1000);
      setEnergyIntensity(totalConsumption / totalArea);
      setCO2Consumption(totalConsumption * co2Equivalent / 1000);
      setCO2Intensity(totalConsumption * co2Equivalent / totalArea);

      if (asset.energyConsumption > 0) {
        newConsumptionPerCategory[category] = newConsumptionPerCategory[category] || {
          label: categoryMetadata.name,
          consumption: 0,
          count: 0
        };

        newConsumptionPerCategory[category].count += 1;
        newConsumptionPerCategory[category].consumption += asset.energyConsumption;
      }

      return tree;
    }, {});

    setSeriesLabels(Object.keys(newConsumptionPerCategory).map((key: any) => newConsumptionPerCategory[key].label));
    setTotalSeries(Object.keys(newConsumptionPerCategory).map((key: string) => newConsumptionPerCategory[key].consumption));
    setPerCategorySeries(Object.keys(newConsumptionPerCategory).map((key: string) => newConsumptionPerCategory[key].consumption / newConsumptionPerCategory[key].count));

    return [{ data: { total: true, consumption: totalConsumption, co2: totalCO2Equivalent } }, ...Object.values(tree)];
  }

  const applyCO2Equivalent = (equivalent: number) => {
    for (const category of tree) {
      category.data.co2 = equivalent * category.data.consumption;

      if (category.children) {
        for (const slot of category.children) {
          slot.co2 = equivalent * slot.consumption;
        }
      }
    }

    setTree(tree);
    setTreeData(TreeState.create(tree));
  };

  const handleOnExpandAll = () => {
    setTreeData(TreeState.expandAll(treeData));
    setExpanded(true);
  };

  const handleOnCollapseAll = () => {
    setTreeData(TreeState.collapseAll(treeData));
    setExpanded(false);
  };

  const handleOnChange = (newValue: any) => {
    setTreeData(newValue);
  }

  const renderIndexCell = (row: any) => {
    if (row.data.total) {
      return <div className="marginTopS marginBottomS fontWeightBold">Gesamt</div>
    } else {
      return <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px' }}
        className={row.metadata.hasChildren ? 'width300 with-children' : 'width400 without-children'}>

        {(row.metadata.hasChildren)
          ? (
            <button className="toggle-button" onClick={row.toggleChildren}></button>
          )
          : ''
        }

        <span>{row.data.name}</span>
      </div>
    }
  };

  const renderProductCell = (row: any) => {
    if (row.children) {
      return <div></div>;
    } else {
      return <span>{row.data.product || '-'}</span>;
    }
  }

  const renderCountCell = (row: any) => {
    return <div className={`flexGrow1 textAlignRight ${row.data.total ? 'marginTopS marginBottomS fontWeightBold' : ''}`}>{row.data.count}</div>
  }

  const renderConsumptionCell = (row: any) => {
    const showAccuracy = !row.children && row.data.accuracy > 0;

    return <div className={`flexGrow1 textAlignRight ${row.data.total ? 'marginTopS marginBottomS fontWeightBold' : ''}`}>{numberFormat0.format(row.data.consumption)} {showAccuracy ? <sup>{row.data.accuracy}</sup> : <sup>&nbsp;</sup>}</div>;
  }

  const renderCO2Cell = (row: any) => {
    return <div className={`flexGrow1 textAlignRight ${row.data.total ? 'marginTopS marginBottomS fontWeightBold' : ''}`}>{numberFormat0.format(row.data.co2)}</div>;
  }

  console.log('Labels >>>', seriesLabels);
  console.log('Total Series >>>', totalSeries);
  console.log('Per Category Series >>>', perCategorySeries);

  return <div>
    <div className="headerL">Energie- und CO₂-Bilanz aus Gerätekomponenten</div>
    <div className="marginTopM displayFlex alignItemsCenter gapM">
      <div className="displayFlex flexDirectionColumn">
        <div className="textL fontWeightBold">Gesamt</div>
        <div>
          <ReactApexChart options={{ colors, chart: {}, legend: { offsetX: -10 }, labels: seriesLabels }} series={totalSeries} type="pie" height={250} width={500} />
        </div>
      </div>
      <div className="displayFlex flexDirectionColumn">
        <div className="textL fontWeightBold">Pro Einheit in Geräteklasse</div>
        <div>
          <ReactApexChart options={{ colors, chart: {}, legend: { offsetX: -10 }, labels: seriesLabels }} series={perCategorySeries} type="pie" height={250} width={500} />
        </div>
      </div>
    </div>
    <div className='marginTopM displayFlex gapM'>
      <Metric title={'Gebäudefläche'} value={numberFormat0.format(totalArea)} unit={'m²'} color={theme.tertiaryDarker}></Metric>
      <Metric title={'Gesamtenergieverbrauch'} value={numberFormat0.format(totalEnergyConsumption)} unit={'MWh/a'} color={theme.secondaryLight}></Metric>
      <Metric title={'Energieintensität'} value={numberFormat0.format(totalEnergyIntensity)} unit={'kWh/m²/a'} color={theme.tertiary}></Metric>
      <Metric title={'CO₂-Ausstoß'} value={numberFormat0.format(totalCO2Consumption)} color={theme.primary} unit={'kWh/a'}></Metric>
      <Metric title={'CO₂-Intensität'} value={numberFormat0.format(totalCO2Intensity)} unit={'kg/m²/a'} color={theme.secondary}></Metric>
    </div>
    <div className="marginTopL displayFlex gapS">
      <div className="displayFlex">
        {expanded
          ?
          <ActionButton iconProps={{ iconName: 'FolderHorizontal' }} onClick={handleOnCollapseAll}></ActionButton>
          :
          <ActionButton iconProps={{ iconName: 'OpenFolderHorizontal' }} onClick={handleOnExpandAll}></ActionButton>
        }
      </div>
      <div className="displayFlex flexGrow1">
        <TextField placeholder="Filter eingeben" underlined styles={{ root: { width: 250 } }} />
      </div>
      <div className="displayFlex justifyContentEnd alignItemsCenter gapS">
        <SpinButton
          label="CO₂-Äquivalent"
          step={0.1}
          value={`${co2Equivalent}`}
          onChange={(_: any, value: any) => {
            console.log('Value', value);
            setCO2Equivalent(Number(value));
            applyCO2Equivalent(Number(value));
          }}
          styles={{ spinButtonWrapper: { width: 75 } }}
        />
        <div className="textM">kg/kWh</div>
      </div>
    </div>
    <div className="marginTopM">
      <TreeTable
        value={treeData}
        onChange={handleOnChange}>
        <TreeTable.Column
          renderCell={renderIndexCell}
          renderHeaderCell={() => <span>Verbrauchertyp</span>}
          grow={1}
          basis={'500px'} />
        <TreeTable.Column
          renderCell={renderProductCell}
          renderHeaderCell={() => <span>Produkt</span>}
          grow={1}
          basis={'230px'} />
        <TreeTable.Column
          renderCell={renderCountCell}
          renderHeaderCell={() => <span>Anzahl</span>}
          grow={0}
          basis={'100px'} />
        <TreeTable.Column
          renderCell={renderConsumptionCell}
          renderHeaderCell={() => <span>Verbrauch (kWh/a)</span>}
          grow={0}
          basis={'180px'} />
        <TreeTable.Column
          renderCell={renderCO2Cell}
          renderHeaderCell={() => <span>CO₂ (kg/a)</span>}
          grow={0}
          basis={'180px'} />
      </TreeTable>
    </div>
    <div className="marginTopM textS"><sup>1</sup> Geschätzt aus Gerätetyp&nbsp;&nbsp;<sup>2</sup> Geschätzt aus Typ/Produktangaben&nbsp;&nbsp;<sup>3</sup> Gemessen</div>
  </div >;
}
