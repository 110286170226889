import '../App.sass';
import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import Section from '../components/Section';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Text } from '@fluentui/react/lib/Text';
import { settingsAtom } from '../State';
import { useAtom } from "jotai";
import { useTranslation } from 'react-i18next';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { TextField } from '@fluentui/react/lib/TextField';
import { authenticationAtom } from '../State';
import { useAdministrationAPI } from '../AdministrationAPI';
import { userAPI } from '../UserAPI';
import { ColorPicker, Pivot, PivotItem, ThemeProvider, Toggle } from '@fluentui/react';
import tinycolor from 'tinycolor2';
import SideBar, { ItemType, SideBarItem } from '../components/SideBar';
import { CustomerTheme } from '@the-real-insight/tri-model';
import { styleCalculator, useCustomerTheme } from '../Theme';
import Zoom from 'react-medium-image-zoom'
import { useModalContext } from '../components/ModalProvider';

interface Properties {
}

const testSideBarItems: SideBarItem[] = [
    { type: ItemType.item, key: 'test1', text: 'Item 1', iconName: "UserOptional" },
    { type: ItemType.item, key: 'test2', text: 'Item 2', iconName: "PageLock" },
    { type: ItemType.item, key: 'test3', text: 'Item 3', iconName: "CityNext" },
];

export default function CompanyPanel(properties: Properties) {
    const { t } = useTranslation();
    const { theme, setTheme } = useCustomerTheme();
    const { pushError } = useModalContext() as any;
    const { createOrUpdateCustomerTheme } = useAdministrationAPI();
    const { updateCustomerLogo, updateCustomer } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [logoImages, setLogoImages] = useState([]) as any;
    const [customer, setCustomer] = useState({ address: {} }) as any;
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const [editTheme, setEditTheme] = useState(null) as any;
    const [editedAddress, setEditedAddress] = useState({ address: {} }) as any;
    const [editField, setEditField] = useState(null) as any;
    const [editColor, setEditColor] = useState(null) as any;
    const [sidebarShown, setSidebarShown] = useState(true);

    const onChange = async (imageList: any, addUpdateIndex: any) => {
        try {
            console.log(imageList, addUpdateIndex);

            const formData = new FormData();

            formData.append("logo", imageList[0].file);

            const result = await updateCustomerLogo(authentication.customer, formData);

            console.log('Result >>>', result);

            setCustomer({ ...customer, logoURL: result.logoURL });
            setLogoImages([]);
        } catch (error: any) {
            pushError(error);
        }
    };

    const toggleAddressDialogOpen = () => {
        setEditedAddress(customer.address);
        setAddressDialogOpen(!addressDialogOpen);
    };

    useEffect(() => {
        const call = async () => {
            try {
                const newCustomer = await userAPI.getCustomer(authentication.customer);

                newCustomer.address = newCustomer.address || {};

                setCustomer(newCustomer);
            } catch (error: any) {
                pushError(error);
            }
        };

        call();
    }, []);

    const isStreetValid = () => {
        return editedAddress.street && editedAddress.street.trim().length > 0;
    }
    const isStreetNumberValid = () => {
        return editedAddress.streetNumber && editedAddress.streetNumber.trim().length > 0;
    }
    const isPostalCodeValid = () => {
        return editedAddress.postalCode && editedAddress.postalCode.trim().length > 0;
    }
    const isCityValid = () => {
        return editedAddress.city && editedAddress.city.trim().length > 0;
    }
    const isAddressValid = () => {
        return isStreetValid() &&
            isStreetNumberValid() &&
            isPostalCodeValid() &&
            isCityValid();
    }

    return <div>
        <div className="displayFlex flexDirectionColumn gapL width1000">
            <div className='displayFlex gapL'>
                <Section title={t('settingsPanel.companyPanel.logo')}>
                    <div className="marginTopL displayFlex flexDirectionColumn">
                        <ImageUploading
                            value={logoImages}
                            onChange={async (images: any, addUpdateIndex: any) => {
                                console.log(images, addUpdateIndex);

                                const formData = new FormData();

                                formData.append("file", images[0].file);

                                try {
                                    const result = await userAPI.uploadFile(formData);

                                    console.log('Result >>>', result);

                                    const newCustomerTheme = new CustomerTheme({ ...theme });

                                    newCustomerTheme.logoURL = result.path;

                                    console.log('Customer Theme >>>', newCustomerTheme);

                                    await createOrUpdateCustomerTheme(newCustomerTheme);
                                    await setTheme(newCustomerTheme);
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                            maxNumber={1}
                            maxFileSize={8000000}
                        // dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                // write your building UI
                                <div className="displayFlex gapM">
                                    {theme.logoURL
                                        ?
                                        <div className="displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter">
                                            <Zoom>
                                                <div role="img" className="width200 height300 borderNeutral"
                                                    style={{ width: 300, height: 200, backgroundImage: `url(${userAPI.getResourceURL(theme.logoURL)})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
                                            </Zoom>
                                            <ActionButton iconProps={{ iconName: 'Delete' }} onClick={async () => {
                                                const newCustomerTheme = { ...theme };

                                                console.log('Customer Theme >>>', newCustomerTheme);

                                                try {
                                                    delete newCustomerTheme.logoUrl;

                                                    await createOrUpdateCustomerTheme(new CustomerTheme(newCustomerTheme));
                                                } catch (error) {
                                                    pushError(error);
                                                }
                                            }}>
                                                Delete
                                            </ActionButton>
                                        </div>
                                        :
                                        <div className="width300 height200 displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter borderNeutralDashed"
                                            style={isDragging ? { borderColor: "red" } : undefined}
                                            {...dragProps}>
                                            <ActionButton iconProps={{ iconName: 'Photo2Add' }} onClick={onImageUpload}>
                                                Drop file or select
                                            </ActionButton>
                                        </div>}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                </Section>
                <Section title={'Theming'}>
                    <div className="width500">
                        <div className='displayFlex justifyContentEnd'>
                            <DefaultButton text={t('global.change')} onClick={() => setEditTheme({ ...theme })} />
                        </div>
                        <div className="marginTopL displayFlex gapM flexWrap">
                            {styleCalculator.map((parameters: any, index: number) =>
                                <div className='displayFlex alignItemsCenter flexDirectionColumn gapS'>
                                    <div className='width80 height80' style={{ backgroundColor: theme[parameters.field] }}></div>
                                    <div className='textXS'>{parameters.name}</div>
                                </div>)}
                        </div>
                    </div>
                </Section>
            </div>
            <div className='displayFlex'>
                <Section className="width400" title={t('settingsPanel.companyPanel.address')}>
                    <div className='displayFlex justifyContentEnd'>
                        <DefaultButton text={t('global.change')} onClick={toggleAddressDialogOpen} />
                    </div>
                    <div className="marginTopM">
                        <div>
                            <Text variant="medium">{customer.name}</Text>
                        </div>
                        <div>
                            <Text variant="medium">{customer.address.street} {customer.address.streetNumber}</Text>
                        </div>
                        <div>
                            <Text variant="medium">{customer.address.postalCode} {customer.address.city}</Text>
                        </div>
                    </div>
                </Section>
            </div>
        </div>
        <Dialog hidden={!addressDialogOpen}
            onDismiss={toggleAddressDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.companyPanel.changeAddress'),
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <TextField label={t('settingsPanel.companyPanel.street')} value={editedAddress.street} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, street: value })}
                errorMessage={isStreetValid() ? undefined : t('settingsPanel.companyPanel.streetNotValid')} />
            <TextField label={t('settingsPanel.companyPanel.streetNumber')} value={editedAddress.streetNumber} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, streetNumber: value })}
                errorMessage={isStreetNumberValid() ? undefined : t('settingsPanel.companyPanel.streetNumberNotValid')} />
            <TextField label={t('settingsPanel.companyPanel.postalCode')} value={editedAddress.postalCode} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, postalCode: value })}
                errorMessage={isPostalCodeValid() ? undefined : t('settingsPanel.companyPanel.postalCodeNotValid')} />
            <TextField label={t('settingsPanel.companyPanel.city')} value={editedAddress.city} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, city: value })}
                errorMessage={isCityValid() ? undefined : t('settingsPanel.companyPanel.cityNotValid')} />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!isAddressValid()} text={t('global.change')} onClick={async () => {
                    try {
                        const updatedCustomer = { ...customer, address: editedAddress }

                        await updateCustomer(updatedCustomer);

                        setCustomer(updatedCustomer);
                        toggleAddressDialogOpen();
                    } catch (error: any) {
                        pushError(error);
                    }
                }} />
                <DefaultButton onClick={toggleAddressDialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={editTheme === null}
            onDismiss={() => setEditTheme(null)}
            maxWidth={1100}
            minWidth={1100}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Change Theme',
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <div className='paddingM displayFlex gapM'>
                <div className='width600'>
                    <ThemeProvider theme={{
                        palette: {
                            themePrimary: editTheme ? editTheme.primary : '#FFFFFF',
                        },
                    }}>
                        <div>
                            <div className='textL'>Samples</div>
                            <div className='marginTopL displayFlex gapM'>
                                <div>
                                    <SideBar items={testSideBarItems} setItem={() => { }} shown={sidebarShown} shownChanged={setSidebarShown}></SideBar>
                                </div>
                                <div>
                                    <div>
                                        <DefaultButton>Default Button</DefaultButton>
                                    </div>
                                    <div className='marginTopM'>
                                        <PrimaryButton>Primary Button</PrimaryButton>
                                    </div>
                                    <div className='marginTopL'>
                                        <Pivot linkSize="large">
                                            <PivotItem headerText="Tab 1">
                                            </PivotItem>
                                            <PivotItem headerText="Tab 2">
                                            </PivotItem>
                                            <PivotItem headerText="Tab 3">
                                            </PivotItem>
                                        </Pivot>
                                    </div>
                                    <div className='marginTopL'>
                                        <Toggle label="Toggle" onText="On" offText="Off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ThemeProvider>
                </div>
                <div className="width500">
                    <div className='textL'>Theme Colors</div>
                    <div className="marginTopL displayFlex gapM flexWrap">
                        {styleCalculator.map((parameters: any) =>
                            <div className='displayFlex alignItemsCenter flexDirectionColumn gapS'>
                                <div className='width80 height80' style={{ backgroundColor: editTheme ? editTheme[parameters.field] : '#FFFFFF' }}
                                    onClick={() => {
                                        setEditColor(editTheme[parameters.field]);
                                        setEditField(parameters.field);
                                    }}></div>
                                <div className='textXS'>{parameters.name}</div>
                            </div>)}
                    </div>
                </div>
            </div>
            <DialogFooter className="marginTopL">
                <PrimaryButton text={t('global.save')} onClick={async () => {
                    try {
                        await createOrUpdateCustomerTheme(new CustomerTheme({ ...editTheme }));

                        setEditTheme(null);

                        // Reload page for the changed theme to become effective
                        window.location.reload();
                    } catch (error) {
                        pushError(error);
                    }
                }} />
                <DefaultButton onClick={() => {
                    setEditTheme(null);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={editField === null}
            onDismiss={() => setEditField(null)}
            maxWidth={450}
            minWidth={450}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Change Color',
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <div className='displayFlex flexDirectionColumn alignItemsCenter'>
                <ColorPicker
                    color={editColor}
                    onChange={(x: any, color: any) => { console.log(color); setEditColor(color.hex) }}
                    alphaType={'none'}
                    showPreview={true}
                    styles={{
                        panel: { padding: 12 },
                        root: {
                            maxWidth: 352,
                            minWidth: 352,
                        },
                        colorRectangle: { height: 268 },
                    }}
                    // The ColorPicker provides default English strings for visible text.
                    // If your app is localized, you MUST provide the `strings` prop with localized strings.
                    strings={{
                        // By default, the sliders will use the text field labels as their aria labels.
                        // Previously this example had more detailed instructions in the labels, but this is
                        // a bad practice and not recommended. Labels should be concise, and match visible text when possible.
                        hueAriaLabel: 'Hue',
                    }}
                />
            </div>
            <DialogFooter className="marginTopL">
                <PrimaryButton text={t('global.save')} onClick={async () => {
                    const newTheme = { ...editTheme };

                    newTheme[editField] = `#${editColor}`;

                    setEditTheme({ ...newTheme });
                    setEditField(null);
                }} />
                <DefaultButton onClick={() => {
                    setEditField(null);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </div>;
}