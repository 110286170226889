import Header from "../Header";
import { Pivot, PivotItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import CustomerAndUserManagementPanel from "./CustomerAndUserManagementPanel";
import BillingPanel from "./BillingPanel";
import InvoicingPanel from "./InvoicingPanel";
import SideBar, { ItemType, SideBarItem } from "../components/SideBar";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GatewayPanel from "./GatewayPanel";
import ContentPanel from "../components/ContentPanel";
import { useModalContext } from "../components/ModalProvider";

export default function AdministrationPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pushError } = useModalContext() as any;
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [selectedPanel, setSelectedPanel] = useState(searchParams.get("panel") || 'customerManagement');
    const [sidebarShown, setSidebarShown] = useState(true);

    const sideBarItems: SideBarItem[] = [
        { type: ItemType.item, key: 'customerManagement', text: t('administrationPage.customerManagement'), iconName: "CompanyDirectory" },
        { type: ItemType.item, key: 'email', text: t('administrationPage.email'), iconName: "EditMail" },
        { type: ItemType.item, key: 'billing', text: t('administrationPage.billing'), iconName: "Money" },
        { type: ItemType.item, key: 'teamManagement', text: t('settingsPanel.teamManagement'), iconName: "People" },
        { type: ItemType.item, key: 'invoicing', text: t('administrationPage.invoicing'), iconName: "PaymentCard" },
        { type: ItemType.item, key: 'telemetry', text: t('administrationPage.telemetry'), iconName: "ProjectCollection" },
    ];

    const selectPanel = (panel: string) => {
        setSelectedPanel(panel);
        searchParams.set("panel", panel);
        navigate(`/administration?${searchParams.toString()}`)
    }

    return (
        <div>
            <Header showHome={true} title="Administration"></Header>
            <div className="displayFlex">
                <SideBar items={sideBarItems} setItem={selectPanel} shown={sidebarShown} shownChanged={setSidebarShown}></SideBar>
                <ContentPanel>
                    {(() => {
                        switch (selectedPanel) {
                            case 'customerManagement': return <CustomerAndUserManagementPanel></CustomerAndUserManagementPanel>;
                            case 'email': return <CustomerAndUserManagementPanel></CustomerAndUserManagementPanel>;
                            case 'billing': return <BillingPanel></BillingPanel>;
                            case 'teamManagement': return <CustomerAndUserManagementPanel></CustomerAndUserManagementPanel>;
                            case 'invoicing': return <InvoicingPanel></InvoicingPanel>
                            case 'telemetry': return <GatewayPanel></GatewayPanel>;
                        }
                    })()}
                </ContentPanel>
            </div>
        </div>
    );
}