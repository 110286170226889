import { Text, TextField } from "@fluentui/react";
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { useEffect, useState } from "react";
import { userAPI } from "../UserAPI";

interface Properties {
  readonly?: boolean;
  type: string;
  properties: any;
  setProperties: any;
}

export default function DIN276AssetProperties(properties: Properties) {
  const { t } = useTranslation();
  const [type, setType] = useState() as any;


  useEffect(() => {
    const call = async () => {
      const metadata = await userAPI.getDIN276Metadata();

      if (properties.type) {
        setType(metadata.find((type: any) => type.code === properties.type))
      }
    };

    call();
  }, [properties.type]);

  const mapQualityState = (id: string) => {
    switch (id) {
      case 'notAcceptable': return t('DIN276AssetProperties.notAcceptable');
      case 'bad': return t('DIN276AssetProperties.bad');
      case 'acceptable': return t('DIN276AssetProperties.acceptable');
      case 'good': return t('DIN276AssetProperties.good');
      case 'veryGood': return t('DIN276AssetProperties.veryGood');
      default: return t('Global.notEntered');
    }
  }

  return <>{type && type.fields && properties.properties
    ?
    <div>
      {properties.readonly
        ?
        <>
          <div className="displayFlex marginBottomS">
            <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">Quality state:</Text></div>
            <div><Text variant="medium">{properties && properties.properties['qualityState'] ? mapQualityState(properties.properties['qualityState']) : '-'}</Text></div>
          </div>
          {type.fields.map((field: any) => <div>
            {(() => {
              switch (field.type) {
                case 'enum': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties && properties.properties[field.name] ? properties.properties[field.name] : '-'}</Text></div>
                  </div>
                </>;
                case 'number': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.properties && properties.properties[field.name] ? properties.properties[field.name] : '-'}</Text></div>
                  </div>
                </>;
                case 'boolean': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.properties && properties.properties[field.name] ? properties.properties[field.name] : '-'}</Text></div>
                  </div>
                </>;
                case 'string':
                default: return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.properties && properties.properties[field.name] ? properties.properties[field.name] : '-'}</Text></div>
                  </div>
                </>;
              }
            })()}
          </div>)}
        </>
        :
        <>
          <Dropdown
            label={'Quality state'}
            placeholder="Please select ..."
            options={[{
              key: 'notAcceptable',
              text: t('DIN276AssetProperties.notAcceptable'),
            }, {
              key: 'bad',
              text: t('DIN276AssetProperties.bad'),
            },
            {
              key: 'acceptable',
              text: t('DIN276AssetProperties.acceptable'),
            }, {
              key: 'good',
              text: t('DIN276AssetProperties.good'),
            }, {
              key: 'veryGood',
              text: t('DIN276AssetProperties.veryGood'),
            }]}
            selectedKey={properties.properties['qualityState']}
            onChange={(e: any, item: any) => {
              const newProperties = { ...properties.properties } as any;

              newProperties['qualityState'] = item.key;

              properties.setProperties(newProperties)
            }}
            styles={{
              dropdown: { width: 300 },
            }}
          />
          {type.fields.map((field: any) => <div>
            {(() => {
              switch (field.type) {
                case 'enum': return <>
                  <Dropdown
                    label={field.name}
                    placeholder="Bitte auswählen ..."
                    options={[...field.values.map((x: any) => {
                      return {
                        key: x,
                        text: x,
                      }
                    })]}
                    selectedKey={properties.properties[field.name]}
                    onChange={(e: any, item: any) => {
                      const newProperties = { ...properties.properties } as any;

                      newProperties[field.name] = item.key;

                      properties.setProperties(newProperties)
                    }}
                    styles={{
                      dropdown: { width: 300 },
                    }}
                  />
                </>;
                case 'number': return <>
                  <TextField type="number" label={field.name} value={properties.properties[field.name]} onChange={(e: any, value: any) => {
                    const newProperties = { ...properties.properties } as any;

                    newProperties[field.name] = value;

                    properties.setProperties(newProperties)
                  }} />
                </>;
                case 'boolean': return <>
                  <TextField type="number" label={field.name} value={properties.properties[field.name]} onChange={(e: any, value: any) => {
                    const newProperties = { ...properties.properties } as any;

                    newProperties[field.name] = value;

                    properties.setProperties(newProperties)
                  }} />
                </>;
                case 'string':
                default: return <>
                  <TextField label={field.name} value={properties.properties[field.name]} onChange={(e: any, value: any) => {
                    const newProperties = { ...properties.properties } as any;

                    newProperties[field.name] = value;

                    properties.setProperties(newProperties)
                  }} />
                </>;
              }
            })()}
          </div>)}
        </>}
    </div>
    :
    <div></div>
  }
  </>;
}
