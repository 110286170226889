import { AndCondition, ComparisonCondition, ComparisonOperator, ComparisonType, Condition, ConjunctionCondition, MeasurementType, MeasurementTypeI18N, MeasurementUnit, OrCondition } from "@the-real-insight/tri-model";
import { useState } from "react";
import { ActionButton, Dropdown, IDropdownOption, TextField } from '@fluentui/react';

interface ConditionProperties {
    condition: Condition;
    setCondition: (condition: Condition) => void;
}

const operator = [
    { key: ComparisonOperator.equal, text: 'ist gGleich als =' },
    { key: ComparisonOperator.less, text: 'ist kleiner als <' },
    { key: ComparisonOperator.lessEqual, text: 'ist kleiner als oder gleich ≤' },
    { key: ComparisonOperator.greater, text: 'ist größer als >' },
    { key: ComparisonOperator.greaterEqual, text: 'ist größer als oder gleich ≥' },
];

const comparisonType = [
    { key: ComparisonType.field, text: 'der Wert im Feld' },
    { key: ComparisonType.value, text: 'ein expliziter Wert von' },
];

export default function ConditionSection(properties: ConditionProperties) {
    const [conditions, setConditions] = useState(properties.condition instanceof ConjunctionCondition ? (properties.condition as ConjunctionCondition).conditions : []) as any;

    const addCondition = (condition: Condition) => {
        setConditions([...conditions, condition]);
    };

    const removeCondition = (condition: Condition) => {
        console.log('Delete', conditions, conditions.filter((entry: Condition) => condition !== entry));
        setConditions(conditions.filter((entry: Condition) => condition !== entry));
    };

    const menuProps = {
        shouldFocusOnMount: true,
        items: [
            {
                key: 'comparison',
                text: 'Vergleich',
                onClick: () => addCondition(new ComparisonCondition()),
            },
            {
                key: 'and',
                text: 'UND-Verknüpfung',
                onClick: () => addCondition(new AndCondition()),
            },
            {
                key: 'or',
                text: 'ODER-Verknüpfung',
                onClick: () => addCondition(new OrCondition()),
            },
        ],
    };

    return <div>
        {(() => {
            if (properties.condition instanceof ComparisonCondition) {
                return <div className='displayFlex alignItemsCenter gapS'>
                    <Dropdown
                        //onChange={onPOITypesSelectionChange}
                        options={Object.keys(MeasurementType).map((key: any) => ({
                            key,
                            // @ts-ignore
                            text: MeasurementTypeI18N['de'][key],
                        })).sort((x: IDropdownOption, y: IDropdownOption) => x.text.localeCompare(y.text))}
                        selectedKey={properties.condition.field}
                        onChange={(event: any, item: any) => {
                            const newCondition = { ...properties.condition, field: item.key };

                            properties.setCondition(Condition.unmarshal(newCondition));
                        }}
                        styles={{ dropdown: { width: 200 } }}
                    />
                    <Dropdown
                        options={operator}
                        selectedKey={(properties.condition as ComparisonCondition).operator}
                        onChange={(event: any, item: any) => {
                            const newCondition = { ...properties.condition, operator: item.key };

                            properties.setCondition(Condition.unmarshal(newCondition));
                        }}
                        styles={{ dropdown: { width: 200 } }}
                    />
                    {/* <Dropdown
                        options={comparisonType}
                        selectedKey={(properties.condition as ComparisonCondition).comparisonType}
                        onChange={(event: any, item: any) => {
                            const newCondition = { ...properties.condition, comparisonType: item.key };

                            properties.setCondition(Condition.unmarshal(newCondition));
                        }}
                        styles={{ dropdown: { width: 200 } }}
                    /> */}
                    {(properties.condition as ComparisonCondition).comparisonType === ComparisonType.value
                        ?
                        <TextField placeholder="Wert eingeben" suffix={properties.condition.field ? MeasurementUnit['de'][properties.condition.field] : ''}
                            value={(properties.condition as ComparisonCondition).comparisonValue}
                            onChange={(event: any, value: string | undefined) => {
                                // TODO Check number

                                const newCondition = { ...properties.condition, comparisonValue: Number(value) };

                                properties.setCondition(Condition.unmarshal(newCondition));
                            }} />
                        :
                        <TextField suffix={properties.condition.field ? MeasurementUnit['de'][properties.condition.field] : ''} value={(properties.condition as ComparisonCondition).comparisonField} />
                    }
                </div>
            } else if (properties.condition instanceof ConjunctionCondition) {
                return <div className="borderLeftNeutral displayFlex">
                    <div className='displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter paddingS textOrientationUp'>
                        {properties.condition instanceof AndCondition
                            ?
                            <span className='textOrientationUp fontWeightBold'>UND</span>
                            :
                            <span className='textOrientationUp fontWeightBold'>ODER</span>
                        }
                    </div>
                    <div>
                        <ActionButton menuProps={menuProps}>
                            Bedingung ergänzen
                        </ActionButton>
                        {conditions.map((condition: Condition, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                            <ActionButton iconProps={{ iconName: 'Delete' }}
                                onClick={() => {
                                    removeCondition(condition);
                                }}>
                            </ActionButton>
                            <ConditionSection condition={condition} setCondition={() => {
                                const newCondition = Condition.unmarshal({ ...properties.condition });

                                (newCondition as ConjunctionCondition).conditions[index] = condition;

                                properties.setCondition(newCondition);
                            }}></ConditionSection>
                        </div>)}</div>
                </div>
            }
        })()}
    </div>;
}