import './App.sass';
import { ChatBot } from './ChatBot';
import Header from './Header';
import { useAtom } from 'jotai'
import { requestsAtom } from "./State";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


interface Properties {
  tenant?: string;
}

export default function ChatBotPage(properties: Properties) {
  const location = useLocation();
  const [requests] = useAtom(requestsAtom) as any;

  return (
    <div className="App">
      <Header showSidePanel={!properties.tenant} showMapItem={!properties.tenant} showLogo={requests && requests.length > 0}></Header>
      <ChatBot></ChatBot>
    </div>
  );
}
