import { useAtom } from "jotai";
import { authenticationAtom } from "../State";
import { Entitlement, Entity, Project, Role, RoleAssignment, RoleType } from "@the-real-insight/tri-model";

export const useAuthorization = () => {
    const [authentication] = useAtom(authenticationAtom) as any;

    // TODO >>> tri-model

    const isEntitled = (loggedInUser: any, entity: Entity, entitlement: string): boolean => {
        if (!authentication) {
            return false;
        }

        const rawEntity = entity as any;

        switch (entitlement) {
            case Entitlement.projectBilling: return !!loggedInUser.roleAssignments
                .map((roleAssignment: RoleAssignment) => roleAssignment.role)
                .find((role: Role) => role.type === RoleType.parameterizedRole && role.parameterizedBy.Project === entity._id /*&& role.getRoleID() === 'Bla'*/);
            case Entitlement.projectManagement: return loggedInUser.customer === rawEntity._tenant;
        }
        return false;
    }

    const isLoggedInUserEntitled = (entity: Entity, entitlement: string): boolean => {
        if (!authentication) {
            return false;
        }

        return isEntitled(authentication, entity, entitlement);
    }

    // TODO Maybe 
    const isLoggedInUserProjectOwner = (project: Project) => {
        console.log('isLoggedInUserProjectOwner >>>', authentication, project);

        return authentication.customer === project.customer;

    }

    const isLoggedInUserProjectCustomer = (project: Project) => {
        console.log('isLoggedInUserProjectCustomer >>>', authentication, project);

        return authentication.customer === project.customer;
    }

    return {
        loggedInUser: authentication,
        isLoggedInUserEntitled
    }
}