import { useEffect, useRef, useState } from 'react';
import '../../App.sass';
import { useTranslation } from 'react-i18next';
import mapboxgl, { FillLayer, LineLayer } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, { Source, Layer } from 'react-map-gl';
import { userAPI } from '../../UserAPI';
import { Building, Floor, from, is, RelationshipDirection } from '@the-real-insight/tri-model';
import { ActionButton, Toggle } from '@fluentui/react';
import { booleanPointInPolygon } from "@turf/boolean-point-in-polygon";
import { area, polygon } from '@turf/turf';
import InfoList from '../InfoList';
import DataTable from 'react-data-table-component';
import { useAdministrationAPI } from '../../AdministrationAPI';
import { useNavigate } from 'react-router-dom';

class Properties {
    object: any;
}

const numberFormatArea = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 });

export default function BuildingLocationProperties(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showSatelliteImage, setShowSatelliteImage] = useState(false);
    const [buildings, setBuildings] = useState([]) as any;
    const [selectedBuilding, setSelectedBuilding] = useState([]) as any;
    const [floors, setFloors] = useState([]) as any;
    const { createFloor } = useAdministrationAPI();

    const floorColumns = [{
        name: '',
        width: '50px',
        cell: (floor: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <ActionButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => {
                    navigate(`/entity?selectedEntityType=Floor&selectedEntity=${floor._id}`);
                }}></ActionButton>
            </div>
        ),
        sortable: false,

    }, {
        name: 'Ebene',
        minWidth: '80px',
        maxWidth: '00px',
        right: true,
        cell: (floor: any) => (
            <span>{floor.level}</span>
        )
    }, {
        name: 'Name',
        minWidth: '120px',
        maxWidth: '120px',
        right: true,
        cell: (floor: any) => (
            <span>{floor.name}</span>
        )
    }, {
        name: 'Fläche/㎡',
        minWidth: '100px',
        maxWidth: '100px',
        right: true,
        cell: (floor: any) => (
            <span>{floor.area}</span>
        )
    }];

    const loadFloors = async () => {
        if (!selectedBuilding) {
            return;
        }

        const buildingWithFloors: any = await userAPI.queryOne(from(Building.type).where(is('_id').equal(`${selectedBuilding._id}`)).link('floors', RelationshipDirection.out, Floor.type));

        if (buildingWithFloors) {
            setFloors(buildingWithFloors.floors);
        } else {
            setFloors([]);
        }
    }

    const shapeFromBuilding = (building: any) => {
        return {
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        building.shape.map((location: any) => [location.lon, location.lat])
                    ],
                },
            }],
        };
    };

    const buildingArea = (building: Building) => {
        if (!building || !building.shape) {
            return 0;
        }

        return area(polygon([(building.shape as any).map((location: any) => [location.lon, location.lat])]));
    }

    useEffect(() => {
        if (!properties.object || !properties.object.address) {
            return;
        }

        const call = async () => {
            const newBuildings = await userAPI.query(from(Building.type).where(is('id').equal(properties.object.id)));

            setSelectedBuilding(newBuildings && newBuildings.length > 0 ? newBuildings[0] : null);
            setBuildings(newBuildings);
        };

        call();

    }, [properties.object]);

    useEffect(() => {
        loadFloors();
    }, [selectedBuilding]);

    return <div>
        <Toggle
            label="Show satellite image"
            checked={showSatelliteImage}
            onChange={(_: any, showSatelliteImage?: boolean) => setShowSatelliteImage(!!showSatelliteImage)}
            role="checkbox"
        />
        <div className={'marginTopM'}>
            <div className='displayFlex gapM'>
                <Map mapboxAccessToken="pk.eyJ1IjoibWFyY2dpbGxlc2VwZWhyaSIsImEiOiJjbTFoOWszeHQwYzR6MmpzYXh0OWo0cWF1In0.oCmn9LnN9lZavqGgDL_Y2w"
                    initialViewState={{
                        longitude: 0.5 * (properties.object.minLon + properties.object.maxLon),
                        latitude: 0.5 * (properties.object.minLat + properties.object.maxLat),
                        zoom: 15
                    }}
                    style={{ width: 800, height: 600 }}
                    mapStyle={showSatelliteImage ? 'mapbox://styles/mapbox/satellite-streets-v9' : 'mapbox://styles/mapbox/streets-v9'}
                    onClick={(event: any) => {
                        const match = buildings.find((building: Building) => booleanPointInPolygon([event.lngLat.lng, event.lngLat.lat], polygon([(building.shape as any).map((location: any) => [location.lon, location.lat])])));

                        setSelectedBuilding(match);
                    }}>
                    {buildings.map((building: Building) => {
                        const lineLayer: LineLayer = {
                            id: `${building._id}-line`,
                            type: 'line',
                            paint: {
                                'line-color': '#FFFFFF',
                                'line-width': 1,
                            }
                        };

                        const fillLayer: FillLayer = {
                            id: `${building._id}-fill`,
                            type: 'fill',
                            paint: {
                                // Example of color interpolation using zoom
                                // more info: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
                                'fill-color': ['interpolate', ['linear'], ['zoom'], 0, '#eeddbb', 2, '#0daa00', 3, building === selectedBuilding ? 'rgba(187, 0, 0, 0.4)' : 'rgba(187, 187, 238, 0.6)'],
                            }
                        };
                        return <Source id={`${building._id}-shape`} type="geojson" data={shapeFromBuilding(building) as any}>
                            <Layer {...lineLayer} />
                            <Layer {...fillLayer} />
                        </Source>;
                    })
                    }
                </Map>
                <div className='flexGrow1'>
                    {selectedBuilding
                        ?
                        <div>
                            <div className='textHeader'>{t('buildingProperties.buildingLocationProperties.building')} {selectedBuilding.id_osm} ({buildings.indexOf(buildings.find((building: Building) => selectedBuilding._id === building._id)) + 1}/{buildings.length})</div>
                            <div className='marginTopM'>
                                <InfoList list={[{
                                    label: t('buildingProperties.buildingLocationProperties.area'),
                                    value: `${numberFormatArea.format(buildingArea(selectedBuilding))} ㎡`,
                                },
                                ]}></InfoList>
                            </div>
                            <div>
                                <div className='marginTopM textSubHeading'>Etagen</div>
                                <ActionButton className='marginTopM' iconProps={{ iconName: 'Add' }}
                                    onClick={async () => {
                                        const result = await createFloor(selectedBuilding, new Floor({ name: `${floors.length}. Etage`, level: floors.length }));

                                        loadFloors();
                                    }}>
                                    Etage anlegen
                                </ActionButton>
                                <div className="marginTopM borderNeutral">
                                    <DataTable
                                        data={floors}
                                        columns={floorColumns}
                                        pagination
                                        paginationPerPage={5}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    </div>;
}