import { MIMEType } from "@the-real-insight/tri-model";
import { useCustomerTheme } from "../Theme";
import { FontIcon } from "@fluentui/react";
import { useState } from "react";

interface Properties {
    mimeType: MIMEType;
    size?: number;
}

export default function MIMEIcon(properties: Properties) {
    const { theme } = useCustomerTheme();
    const [size] = useState(properties.size || 50);

    return <div>
        {(() => {
            switch (properties.mimeType) {
                case MIMEType.imagePNG:
                case MIMEType.imageJPEG:
                    case MIMEType.imageSVG:
                        return <FontIcon iconName="FileImage" style={{ color: theme.ternary, fontSize: size, height: size, width: size, }} />;
                case MIMEType.applicationPDF:
                    return <FontIcon iconName="PDF" style={{ color: theme.primary, fontSize: size, height: size, width: size }} />;
                case MIMEType.textPlain:
                case MIMEType.textCSV:
                    return <FontIcon iconName="TextDocument" style={{ color: theme.secondary, fontSize: size, height: size, width: size, }} />;
                default:
                    return <FontIcon iconName="Document" style={{ color: theme.primary, fontSize: size, height: size, width: size, }} />;
            }
        })()}
    </div>;
}