import { useEffect, useMemo, useState } from "react";
import { PrimaryButton, TextField, Text, DefaultButton, ActionButton } from "@fluentui/react";
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { settingsAtom } from '../State';
import { useLocation, useNavigate } from "react-router-dom";
import { userAPI } from "../UserAPI";
import { useTranslation } from "react-i18next";
import { ApplicationError } from "../general/Errors";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import PasswordChecklist from "react-password-checklist"
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import AddressEntry from "../components/AddressEntry";
import { useModalContext } from "../components/ModalProvider";

enum PageMode {
  emailEntry = 'emailEntry',
  otpEntry = 'otpEntry',
  passwordEntry = 'passwordEntry',
  companyDataEntry = 'companyDataEntry',
  licenseSelection = 'licenseSelection',
  paymentMethod = 'paymentMethod',
  welcome = 'welcome'
};

enum PaymentMethod {
  invoice = 'invoice"',
  creditCard = 'creditCard',
};

export const SignUpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pushError } = useModalContext() as any;
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [userID, setUserID] = useState();
  const [email, setEmail] = useState(searchParams.get("email") || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState({}) as any;
  const [tradeRegisterId, setTradeRegisterId] = useState('');
  const [tradeRegisterCity, setTradeRegisterCity] = useState('');
  const [taxId, setTaxId] = useState('');
  const [, setAuthentication] = useAtom(authenticationAtom);
  const [, setSettings] = useAtom(settingsAtom);
  const [pageMode, setPageMode] = useState(PageMode.emailEntry);
  const [plans, setPlans] = useState() as any;
  const [error, setError] = useState() as any;
  const [portalAndAppUsagePlan, setPortalAndAppUsagePlan] = useState() as any;
  const [privateDataIngestionPlan, setPrivateDataIngestionPlan] = useState() as any;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethod.invoice);
  const [waiting, setWaiting] = useState(false);

  const paymentMethods: IChoiceGroupOption[] = [
    { key: PaymentMethod.invoice, text: t('signupPage.invoicePayment') },
    { key: PaymentMethod.creditCard, text: t('signupPage.creditCardPayment'), disabled: true }
  ];
  const findPlan = (plans: any, id: string) => plans.find((plan: any) => plan.id === id);

  useEffect(() => {
    const call = async () => {
      const plans = await userAPI.getPlans();

      console.log('Plans >>>', plans);

      setPlans(plans);
      setPortalAndAppUsagePlan(findPlan(plans.portalAndAppUsage, 'base3'));
      setPrivateDataIngestionPlan(findPlan(plans.privateDataIngestion, 'standard'))
    };

    call();
  }, []);

  const isEmptyString = (value: string): boolean => {
    return !value || value.trim().length === 0;
  };

  const isValidEmail = (email: string): boolean => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null;
  };

  const isValidUUIDV4 = (uuid: string): boolean => {
    console.log('Match >>>', uuid, String(uuid)
      .toUpperCase()
      .match(
        /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/
      ));
    return String(uuid)
      .toUpperCase()
      .match(
        /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/
      ) !== null;
  };

  const isMobileNumberValid = (mobileNumber: string): boolean => {
    return isPossiblePhoneNumber(mobileNumber || '');
  }

  return <div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className="zIndex900">
      <div className="marginTopXL displayFlex justifyContentCenter">
        <img onClick={() => navigate('/')} className='width100 height100' src="/logo.png" alt="logo"></img>
      </div>
      <div className="marginTopXL displayFlex flexDirectionColumn alignItemsCenter">
        {(() => {
          switch (pageMode) {
            case PageMode.emailEntry: return <>
              <div className="width600 displayFlex flexDirectionColumn alignItemsCenter">
                <div className="width400">
                  <div className="textXL">
                    {t('signupPage.personalData')}
                  </div>
                  <div className="marginTopM textM">
                    {t('signupPage.pleaseEnterYourData')}
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      id="emailField"
                      label={t('signupPage.email')}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      value={email}
                      onChange={(event, value: any) => {
                        setEmail(value);
                      }}
                      onGetErrorMessage={(value: string) => isValidEmail(value) ? '' : t('signupPage.invalidEmailFormat')}
                      validateOnLoad={false}
                      validateOnFocusOut
                    />
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      id="firstNameField"
                      label={t('signupPage.firstName')}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      value={firstName}
                      onChange={(event, value: any) => {
                        setFirstName(value);
                      }}
                    />
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      id="lastNameField"
                      label={t('signupPage.lastName')}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      value={lastName}
                      onChange={(event, value: any) => {
                        setLastName(value);
                      }}
                    />
                  </div>
                  <div className="marginTopL displayFlex alignItemsCenter">{t('signupPage.dataProtectionLink')} <a href="/markup/legal/Datenschutzbestimmungen.md" target="_blank"><ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }}>
                  </ActionButton></a></div>
                  <div className="marginTopM height50">
                    <div className="textM overflowWrapBreakWord colorError">{error}</div>
                  </div>
                </div>
                <div className="marginTopM displayFlex justifyContentCenter">
                  <PrimaryButton disabled={isEmptyString(email) || isEmptyString(firstName) || isEmptyString(lastName) || !isValidEmail(email)} onClick={async () => {
                    const call = async () => {
                      try {
                        // @ts-ignore
                        const result = await userAPI.checkEmail(email, firstName, lastName);

                        setUserID(result.id)
                        setPageMode(PageMode.otpEntry);
                      } catch (error) {
                        if (error instanceof ApplicationError) {
                          // @ts-ignore
                          setError(t(`errors.${error.message}`, { email, domain: email.substring(email.indexOf('@') + 1) }));
                        } else {
                          pushError(error);
                        }
                      } finally {
                        setWaiting(false);
                      }
                    };

                    setError(null);
                    setWaiting(true);
                    call();
                  }}>
                    <div className="displayFlex gapS">
                      {waiting ? <Spinner styles={{ circle: { color: 'white' } }} size={SpinnerSize.xSmall} /> : <></>}
                      <div>{t('global.next')}</div>
                    </div>
                  </PrimaryButton>
                </div>
              </div>
            </>;
            case PageMode.otpEntry: return <>
              <div className="width600 displayFlex flexDirectionColumn alignItemsCenter">
                <div className="width500">
                  <div className="textXL">
                    {t('signupPage.emailValidation', { email })}
                  </div>
                  <div className="marginTopL textM">
                    {t('signupPage.weSentYouAValidationCode', { email })}
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField id="validationCodeField"
                      label={t('signupPage.validationCode')}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      value={validationCode}
                      onChange={(event, value: any) => {
                        setValidationCode(value);
                      }}
                      onGetErrorMessage={(value: string) => isValidUUIDV4(value) ? '' : t('signupPage.invalidValidationCodeFormat')}
                      validateOnLoad={false}
                    />
                  </div>
                  <div className="marginTopM height50">
                    <div className="textM overflowWrapBreakWord colorError">{error}</div>
                  </div>
                </div>
                <div className="marginTopXL displayFlex gapM">
                  <DefaultButton text={t('signupPage.resendValidationCode')} onClick={async () => {
                  }}></DefaultButton>
                  <PrimaryButton text={t('global.next')} disabled={isEmptyString(validationCode) || !isValidUUIDV4(validationCode)} onClick={async () => {
                    const call = async () => {
                      try {
                        // @ts-ignore
                        await userAPI.validateEmail(email, validationCode);

                        setPageMode(PageMode.passwordEntry);
                      } catch (error) {
                        if (error instanceof ApplicationError) {
                          // @ts-ignore
                          setError(t(`errors.${error.message}`,));
                        } else {
                          pushError(error);
                        }
                      } finally {
                        setWaiting(false);
                      }
                    };

                    setError(null);
                    setWaiting(true);
                    call();
                  }}></PrimaryButton>
                </div>
              </div>
            </>;
            case PageMode.passwordEntry: return <>
              <div className="width600 displayFlex flexDirectionColumn alignItemsCenter">
                <div className="width400">
                  <div className="textXL">
                    {t('signupPage.passwordAndMobileNumber')}
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      label={t('signupPage.password')}
                      type="password"
                      canRevealPassword
                      value={password}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      onChange={(event, value: any) => {
                        setPassword(value);
                      }}
                    />
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      label={t('signupPage.passwordRepeat')}
                      type="password"
                      canRevealPassword
                      value={repeatPassword}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      onChange={(event, value: any) => {
                        setRepeatPassword(value);
                      }}
                    />
                  </div>
                  <div className="marginTopM">
                    <PasswordChecklist
                      rules={["minLength", "specialChar", "number", "capital", "match"]}
                      minLength={5}
                      value={password || ''}
                      valueAgain={repeatPassword}
                      messages={{
                        minLength: t('common.passwordChangePanel.passwordRuleMinLength'),
                        specialChar: t('common.passwordChangePanel.passwordRuleSpecialChar'),
                        number: t('common.passwordChangePanel.passwordRuleNumber'),
                        capital: t('common.passwordChangePanel.passwordRuleCapital'),
                        match: t('common.passwordChangePanel.passwordRuleMatch'),
                      }}
                      onChange={(valid: boolean) => {
                        setPasswordValid(valid);
                      }}
                    />
                  </div>
                  <div className="marginTopL textM">
                    {t('signupPage.whyMobileNumber')}
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      label={t('signupPage.mobileNumber')}
                      value={mobileNumber}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      onChange={(event, value: any) => {
                        setMobileNumber(value);
                      }}
                      onGetErrorMessage={(value: string) => isMobileNumberValid(value) ? '' : t('signupPage.invalidMobileNumberFormat')}
                      validateOnLoad={false}
                      validateOnFocusOut
                      deferredValidationTime={2000}
                    />
                  </div>
                </div>
                <div className="marginTopXL displayFlex alignItemsCenter">
                  <PrimaryButton text={t('global.next')} disabled={isEmptyString(password) || isEmptyString(repeatPassword) || !passwordValid || isEmptyString(mobileNumber) || !isMobileNumberValid(mobileNumber)} onClick={async () => {
                    setPageMode(PageMode.companyDataEntry);
                  }}></PrimaryButton>
                </div>
              </div>
            </>;
            case PageMode.companyDataEntry: return <>
              <div>
                <div className="textXL">
                  {t('signupPage.companyData')}
                </div>
                <div className="marginTopM displayFlex">
                  <TextField
                    label={t('signupPage.companyName')}
                    value={companyName}
                    styles={{
                      fieldGroup: [
                        { width: '300px' },
                      ]
                    }}
                    onChange={(event, value: any) => {
                      setCompanyName(value);
                    }}
                  />
                </div>
                <div className="displayFlex gapM">
                  <TextField
                    label={t('signupPage.tradeRegisterId')}
                    value={tradeRegisterId}
                    styles={{
                      fieldGroup: [
                        { width: '200px' },
                      ]
                    }}
                    onChange={(event, value: any) => {
                      setTradeRegisterId(value);
                    }}
                  />
                  <TextField
                    label={t('signupPage.tradeRegisterCity')}
                    value={tradeRegisterCity}
                    styles={{
                      fieldGroup: [
                        { width: '250px' },
                      ]
                    }}
                    onChange={(event, value: any) => {
                      setTradeRegisterCity(value);
                    }}
                  />
                  <TextField
                    label={t('signupPage.taxId')}
                    value={taxId}
                    styles={{
                      fieldGroup: [
                        { width: '100px' },
                      ]
                    }}
                    onChange={(event, value: any) => {
                      setTaxId(value);
                    }}
                  />
                </div>
                <div className="marginTopL textL">{t('signupPage.companyAddress')}</div>
                <AddressEntry className="marginTopM" address={companyAddress} addressChanged={(address: any) => {
                  console.log('Address >>> ', address);
                  setCompanyAddress(address);
                }}></AddressEntry>
                <div className="marginTopXL displayFlex gapM justifyContentCenter">
                  <DefaultButton text={t('global.back')} onClick={async () => {
                    setPageMode(PageMode.passwordEntry);
                  }}></DefaultButton>
                  <PrimaryButton text={t('global.next')} disabled={isEmptyString(companyName)} onClick={async () => {
                    setPageMode(PageMode.licenseSelection);
                  }}></PrimaryButton>
                </div>
              </div>
            </>;
            case PageMode.licenseSelection: return <>
              <div className="displayFley flexDirectionColumn alignItemsCenter">
                <div className="textXL">{t('signupPage.licenses')}</div>
                {plans ?
                  <div>
                    <div className="marginTopL textM">{t('signupPage.pleaseSelect')}</div>
                    <div className="marginTopL textL">{t('signupPage.portalAndAppUsage')}</div>
                    <div className="marginTopM displayFlex gapM">
                      {plans.portalAndAppUsage.map((plan: any) => <div className={`plan paddingS${plan === portalAndAppUsagePlan ? ' selected' : ''}`}
                        onClick={() => setPortalAndAppUsagePlan(plan)}>
                        <div className="planSelected fontWeightBold textM">
                          {plan.name}
                        </div>
                        <div className="marginTopXS planSelected textS">
                          {plan.description}
                        </div>
                      </div>)}
                    </div>
                    <div className="marginTopL"><Text variant="large">{t('signupPage.privateDataIngestion')}</Text></div>
                    <div className="marginTopS displayFlex gapM">
                      {plans.privateDataIngestion.map((plan: any) => <div className={`plan paddingS${plan === privateDataIngestionPlan ? ' selected' : ''}`}
                        onClick={() => setPrivateDataIngestionPlan(plan)}>
                        <div className="planSelected fontWeightBold textM">
                          {plan.name}
                        </div>
                        <div className="marginTopXS planSelected textS">
                          {plan.description}
                        </div>

                      </div>)}
                    </div>
                    <div className="marginTopL displayFlex alignItemsCenter">{t('signupPage.orderTerms')} <a href="/markup/license/Lizenzmodelle.md" target="_blank"><ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }}>
                    </ActionButton></a></div>
                    <div className="marginTopS displayFlex justifyContentCenter gapM">
                      <DefaultButton text={t('global.back')} onClick={async () => {
                        setPageMode(PageMode.companyDataEntry);
                      }}></DefaultButton>
                      <PrimaryButton text={t('global.next')} onClick={async () => {
                        setPageMode(PageMode.paymentMethod);
                      }}></PrimaryButton>
                    </div>
                  </div>
                  :
                  <></>
                }
              </div>
            </>;
            case PageMode.paymentMethod: return <>
              <div className="width600">
                <div className="textXL">{t('signupPage.paymentMethod')}</div>
                <div className="marginTopM textM">{t('signupPage.paymentMethodText')}</div>
                <ChoiceGroup className="marginTopL" selectedKey={selectedPaymentMethod} options={paymentMethods} onChange={(e: any, value: any) => setSelectedPaymentMethod(value)} />
                <div className="marginTopXL displayFlex gapM">
                  <DefaultButton text={t('global.back')} onClick={() => {
                    setPageMode(PageMode.licenseSelection);
                  }}></DefaultButton>
                  <PrimaryButton onClick={() => {
                    const call = async () => {
                      try {
                        const user = {
                          id: userID,
                          email,
                          firstName,
                          lastName,
                          password,
                          mobileNumber,
                        };
                        const customer = {
                          name: companyName,
                        };
                        const legalPerson = {
                          tradeRegisterId,
                          tradeRegisterCity,
                          taxId,
                          headquarters: companyAddress,
                        }

                        console.log('Legal Person', legalPerson);

                        // TODO These values should already be set server-side

                        portalAndAppUsagePlan.service = 'portalAndAppUsage';
                        privateDataIngestionPlan.service = 'privateDataIngestion';

                        const plans = [portalAndAppUsagePlan, privateDataIngestionPlan];

                        // @ts-ignore
                        setAuthentication(await userAPI.registerAndOrder(user, customer, legalPerson, plans, selectedPaymentMethod));
                        setPageMode(PageMode.welcome);
                      } catch (error) {
                        if (error instanceof ApplicationError) {
                          // @ts-ignore
                          setError(t(`errors.${error.message}`));
                        } else {
                          pushError(error);
                        }
                      } finally {
                        setWaiting(false);
                      }
                    };

                    console.log('Now!!!');

                    setWaiting(true);
                    call();
                  }}>
                    <div className="displayFlex gapS">
                      {waiting ? <Spinner styles={{ circle: { color: 'white' } }} size={SpinnerSize.xSmall} /> : <></>}
                      <div className="fontWeightBold">{t('signupPage.registerAndOrder')}</div>
                    </div>
                  </PrimaryButton>
                </div>
              </div>
            </>;
            case PageMode.welcome: return <>
              <div className="width600">
                <div className="marginTopXL textXL">{t('signupPage.welcome')}</div>
                <div className="marginTopL textM">{t('signupPage.welcomeToTRI')}</div>
                <div className="marginTopXL displayFlex gapM">
                  <PrimaryButton text={t('signupPage.startAsking')} onClick={async () => {
                    navigate('/chatbot')
                  }}></PrimaryButton>
                </div>
              </div>
            </>;
          }
        })()}
      </div>
    </div>
  </div>;
};