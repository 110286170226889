import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, Callout, ComboBox, DefaultButton, FocusTrapZone, FontIcon, IComboBox, IComboBoxOption, IconButton, Label, Layer, Overlay, Popup, PrimaryButton, SelectableOptionMenuItemType, TextField, TooltipHost } from '@fluentui/react';
import Section from '../components/Section';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { Action, AndCondition, BusinessRule, BusinessRuleRuntimeStatus, ComparisonCondition, Condition, EmailNotification, EntityQuery, from, OrCondition, Product, Project, Query, SMSNotification } from '@the-real-insight/tri-model';
import { dataTableStyles } from '../styles';
import { userAPI } from '../UserAPI';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useAdministrationAPI } from '../AdministrationAPI';
import ConditionSection from '../components/ConditionSection';
import LoadingIndicator from '../components/LoadingIndicator';
import { useModalContext } from '../components/ModalProvider';
import Mustache from "mustache";
import { useFormats } from '../hooks/formats';
import { authenticationAtom } from '../State';
import { useAtom } from 'jotai';

interface Properties {
}

interface AlertPanelProperties {
    alert: BusinessRule;
    setAlert: (alert: BusinessRule | null) => void;
}

function AlertPanel(properties: AlertPanelProperties) {
    const { numberFormat1Digit } = useFormats();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { pushError } = useModalContext() as any;
    const [conditions, setConditions] = useState(properties.alert.conditions || []) as any;
    const [actions, setActions] = useState(properties.alert.actions || []) as any;
    const [products, setProducts] = useState([]) as any;
    const [productOptions, setProductOptions] = useState([]) as any;
    const [projects, setProjects] = useState([]) as any;
    const [projectOptions, setProjectOptions] = useState([]) as any;
    const [selectedProducts, setSelectedProducts] = useState([]) as any;
    const [selectedProjects, setSelectedProjects] = useState([]) as any;
    const [name, setName] = useState(properties.alert.name || '') as any;
    const [description, setDescription] = useState(properties.alert.description || '') as any;
    const [emailHintVisible, setEmailHintVisible] = useState(false);
    const [smsHintVisible, setSMSHintVisible] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const sampleContentTemplate = "Für das Gerät {{name}} - installiert/positioniert {{street}} {{streetNumber}}, {{city}} - wurde am {{date}} um {{time}} der Grenzwert von 1 bar unterschritten."

    useEffect(() => {
        const call = async () => {
            try {
                const newProjects = await userAPI.query(from(Project.type));

                setProjects(newProjects);
                setProjectOptions([{ key: '_all', text: 'Alle', itemType: SelectableOptionMenuItemType.SelectAll },
                ...projects.map((project: Project) => ({ key: project._id, text: project.name }))]);
                setSelectedProjects(properties.alert.projects);

                const newProducts = await userAPI.query(from(Product.type));

                setProducts(newProducts);
                setProductOptions([{ key: '_all', text: 'Alle', itemType: SelectableOptionMenuItemType.SelectAll },
                ...products.map((product: Product) => ({
                    key: product._id, text: product.name,
                }))]);
                setSelectedProducts(properties.alert.products);
            } catch (error) {
                pushError(error);
            }
        };

        call();
    }, [properties.alert]);

    const selectableProductOptions = productOptions.filter(
        (option: any) =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    const selectableProjectOptions = projectOptions.filter(
        (option: any) =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    const onProductChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedProducts.filter((key: any) => key !== '_all');
        const selectAllState = currentSelectedOptionKeys.length === selectableProductOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedProducts([])
                    : setSelectedProducts(['_all', ...selectableProductOptions.map((option: IComboBoxOption) => option.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter((k: any) => k !== option.key);

                if (updatedKeys.length === selectableProductOptions.length) {
                    updatedKeys.push('_all');
                }

                setSelectedProducts(updatedKeys);
            }
        }
    };

    const onProjectChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedProjects.filter((key: any) => key !== '_all');
        const selectAllState = currentSelectedOptionKeys.length === selectableProjectOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedProjects([])
                    : setSelectedProjects(['_all', ...selectableProjectOptions.map((option: IComboBoxOption) => option.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter((k: any) => k !== option.key);

                if (updatedKeys.length === selectableProjectOptions.length) {
                    updatedKeys.push('_all');
                }

                setSelectedProjects(updatedKeys);
            }
        }
    };

    const addCondition = (condition: Condition) => {
        setConditions([...conditions, condition]);
    };

    const removeCondition = (condition: Condition) => {
        setConditions(conditions.filter((entry: Condition) => condition !== entry));
    };

    const removeAction = (action: Action) => {
        setActions(actions.filter((entry: Action) => action !== entry));
    };

    const conditionMenuProps = {
        items: [
            {
                key: 'comparison',
                text: 'Comparison',
                onClick: () => {
                    console.log(conditions);
                    addCondition(new ComparisonCondition())
                },
            },
            {
                key: 'and',
                text: 'AND Operation',
                onClick: () => addCondition(new AndCondition()),
            },
            {
                key: 'or',
                text: 'OR Operation',
                onClick: () => addCondition(new OrCondition()),
            },
        ],
    };

    const reactionMenuProps = {
        shouldFocusOnMount: true,
        items: [
            {
                key: 'email',
                text: 'E-Mail',
                onClick: () => setActions([...actions, new EmailNotification({})]),
            },
            {
                key: 'sms',
                text: 'SMS',
                onClick: () => setActions([...actions, new SMSNotification({})]),
            },
        ],
    };

    return <div className='marginTopM displayFlex flexDirectionColumn gapM'>
        <div className="displayFlex gapM">
            <div className="flexGrow1 displayFlex textL">
                {name}
            </div>
            <div className='displayFlex gapM'>
                <DefaultButton text="Cancel" onClick={() => {
                    properties.setAlert(null);
                }} />
                <PrimaryButton text={properties.alert._id ? 'Save' : 'Add'} onClick={async () => {
                    try {
                        if (properties.alert._id) {
                            await userAPI.updateBusinessRule(authentication.customer, new BusinessRule({
                                ...properties.alert,
                                name,
                                products: selectedProducts,
                                projects: selectedProjects,
                                description,
                                conditions,
                                actions,
                            }));

                        } else {
                            await userAPI.createBusinessRule(authentication.customer, new BusinessRule({
                                ...properties.alert,
                                name,
                                products: selectedProducts,
                                projects: selectedProjects,
                                description,
                                conditions,
                                actions,
                            }));
                        }

                        properties.setAlert(null);
                    } catch (error) {
                        pushError(error);
                    }
                }} /></div>
        </div>
        <div className="paddingM displayFlex gapL">
            <div className="displayFlex gapS">
                <Label>Name</Label>
                <TextField value={name} onChange={(e: any, name: string | undefined) => setName(name)} styles={{ fieldGroup: { width: 300 } }} />
            </div>
            <div className="displayFlex gapS">
                <Label>Description</Label>
                <TextField value={description} onChange={(e: any, description: string | undefined) => setDescription(description)} styles={{ fieldGroup: { width: 700 } }} />
            </div>
        </div>
        <Section title="Focus">
            <div className='displayFlex gapL'>
                <ComboBox
                    label="Produkte"
                    selectedKey={selectedProducts && selectedProducts.length > 0 ? selectedProducts : ['_all']}
                    onChange={onProductChange}
                    multiSelect
                    options={productOptions}
                    styles={{ root: { width: 500 } }}
                />
                <ComboBox
                    label="Projects"
                    selectedKey={selectedProjects && selectedProjects.length > 0 ? selectedProjects : ['_all']}
                    onChange={onProjectChange}
                    multiSelect
                    options={projectOptions}
                    styles={{ root: { width: 500 } }}
                />
            </div>
        </Section>
        <Section title="Conditions">
            <ActionButton menuProps={conditionMenuProps}>
                Bedingung ergänzen
            </ActionButton>
            {conditions.map((condition: Condition, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                <ActionButton iconProps={{ iconName: 'Delete' }}
                    onClick={() => {
                        removeCondition(condition);
                    }}>
                </ActionButton>
                <ConditionSection condition={condition} setCondition={(condition: Condition) => {
                    const newConditions = [...conditions];

                    newConditions[index] = condition;

                    setConditions(newConditions);
                }}></ConditionSection>
            </div>)}
        </Section>
        <Section title="Actions">
            <ActionButton menuProps={reactionMenuProps}>
                Aktion ergänzen
            </ActionButton>
            <div className='displayFlex flexDirectionColumn gapM'>
                {actions.map((action: Action, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                    <ActionButton iconProps={{ iconName: 'Delete' }}
                        onClick={() => {
                            removeAction(action);
                        }}>
                    </ActionButton>
                    {(() => {
                        if (action instanceof EmailNotification) {
                            return <div className='borderLeftNeutral displayFlex gapM'>
                                <div className='displayFlex flexDirectionColumn  alignItemsCenter paddingS textOrientationUp'>
                                    <span className='textOrientationUp fontWeightBold primaryColor'>E-Mail</span>
                                    <IconButton
                                        id={'emailHint'}
                                        iconProps={{ iconName: 'Info' }}
                                        title="Info"
                                        ariaLabel="Info"
                                        onClick={() => setEmailHintVisible(true)}
                                        styles={{ root: { marginBottom: -3 } }}
                                    />
                                </div>
                                {emailHintVisible &&
                                    <Callout
                                        target={'#emailHint'}
                                        setInitialFocus
                                        onDismiss={() => setEmailHintVisible(false)}
                                        role="alertdialog"
                                    >
                                        <div className='paddingL'>
                                            <div>Die folgenden Variablen können in <b>Subject</b> und <b>Content</b> verwendet werden:</div>
                                            <ul>
                                                <li><b>{'{{date}}'}</b> - Datum des Alarms</li>
                                                <li><b>{'{{time}}'}</b> - Zeitpunkt des Alarns</li>
                                                <li><b>{'{{name}}'}</b> - Name des Geräts</li>
                                                <li><b>{'{{street}}'}</b> - Straße der Adresse des Geräts</li>
                                                <li><b>{'{{streetNumber}}'}</b> - Hausnummer der Adresse des Geräts</li>
                                                <li><b>{'{{city}}'}</b> - Stadt der Adresse des Geräts</li>
                                            </ul>
                                            <div className="marginTopL displayFlex alignItemsStart gapS">
                                                <div>{`Z.B. "${sampleContentTemplate}"`}</div>
                                                <IconButton
                                                    iconProps={{ iconName: 'Copy' }}
                                                    title="Copy"
                                                    ariaLabel="Copy"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(sampleContentTemplate);
                                                        setEmailHintVisible(false);
                                                    }} />
                                            </div>
                                            <DefaultButton className="marginTopM" onClick={() => setEmailHintVisible(false)}>Close</DefaultButton>
                                        </div>
                                    </Callout>}
                                <div>
                                    <div className='height20 textS colorRed'>{!action.isCompletelyDefined() && <span>Unvollständig definiert</span>}</div>
                                    <div className='displayFlex gapM'>
                                        <div>
                                            <TextField label="Addressees" value={(action as EmailNotification).to} styles={{ fieldGroup: { width: 300 } }}
                                                onChange={(event: any, value: string | undefined) => {
                                                    const newAction = Action.unmarshal({ ...action, to: value });
                                                    const newActions = [...actions];

                                                    newActions[index] = newAction;

                                                    setActions(newActions);
                                                }} />
                                            <TextField label="Subject" value={(action as EmailNotification).subject} styles={{ fieldGroup: { width: 300 } }}
                                                onChange={(event: any, value: string | undefined) => {
                                                    const newAction = Action.unmarshal({ ...action, subject: value });
                                                    const newActions = [...actions];

                                                    newActions[index] = newAction;

                                                    setActions(newActions);
                                                }} />
                                        </div>
                                        <TextField label="Content" value={(action as EmailNotification).content} multiline styles={{ fieldGroup: { width: 400, height: 100 } }}
                                            onChange={(event: any, value: string | undefined) => {
                                                const newAction = Action.unmarshal({ ...action, content: value });
                                                const newActions = [...actions];

                                                newActions[index] = newAction;

                                                setActions(newActions);
                                            }} />
                                        <div>
                                            <PrimaryButton disabled={!action.isCompletelyDefined()} onClick={async () => {
                                                const data = {
                                                    name: "FE 4512",
                                                    street: "Beilsteinerweg",
                                                    streetNumber: "22",
                                                    city: "Seck",
                                                    date: "12.12.2024",
                                                    time: "23:45",
                                                };
                                                const subject = Mustache.render((action as EmailNotification).subject, data);
                                                const text = Mustache.render((action as EmailNotification).content, data);

                                                try {
                                                    await userAPI.sendEmail({
                                                        to: (action as EmailNotification).to.split(','),
                                                        subject,
                                                        text,
                                                    });

                                                    setPopupMessage('E-Mail wurde gesendet.');
                                                    setIsPopupVisible(true);
                                                } catch (error) {
                                                }
                                            }}>
                                                Test
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } else if (action instanceof SMSNotification) {
                            return <div className='borderLeftNeutral displayFlex gapM'>
                                <div className='displayFlex flexDirectionColumn  alignItemsCenter paddingS textOrientationUp'>
                                    <span className='textOrientationUp fontWeightBold primaryColor'>SMS</span>
                                    <IconButton
                                        id={'smsHint'}
                                        iconProps={{ iconName: 'Info' }}
                                        title="Info"
                                        ariaLabel="Info"
                                        onClick={() => setSMSHintVisible(true)}
                                        styles={{ root: { marginBottom: -3 } }}
                                    />
                                </div>
                                {smsHintVisible &&
                                    <Callout
                                        target={'#smsHint'}
                                        setInitialFocus
                                        onDismiss={() => setSMSHintVisible(false)}
                                        role="alertdialog"
                                    >
                                        <div className='paddingL'>
                                            <div>Die folgenden Variablen können in <b>Content</b> verwendet werden:</div>
                                            <ul>
                                                <li><b>{'{date}'}</b> - Datum des Alarms</li>
                                                <li><b>{'{time}'}</b> - Zeitpunkt des Alarns</li>
                                                <li><b>{'{asset}'}</b> - Name des Geräts</li>
                                                <li><b>{'{street}'}</b> - Straße der Adresse des Geräts</li>
                                                <li><b>{'{streetNumber}'}</b> - Hausnummer der Adresse des Geräts</li>
                                                <li><b>{'{city}'}</b> - Stadt der Adresse des Geräts</li>
                                            </ul>
                                            <div className="marginTopL displayFlex alignItemsStart gapS">
                                                <div>{`Z.B. "${sampleContentTemplate}"`}</div>
                                                <IconButton
                                                    iconProps={{ iconName: 'Copy' }}
                                                    title="Copy"
                                                    ariaLabel="Copy"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(sampleContentTemplate);
                                                        setSMSHintVisible(false);
                                                    }} />
                                            </div>
                                            <DefaultButton className="marginTopM" onClick={() => setSMSHintVisible(false)}>Close</DefaultButton>
                                        </div>
                                    </Callout>}
                                <div>
                                    <div className='height20 textS colorRed'>{!action.isCompletelyDefined() && <span>Unvollständig definiert</span>}</div>
                                    <div className='displayFlex gapM'>
                                        <div>
                                            <TextField label="Phone number" value={(action as SMSNotification).phoneNumber} onChange={(event: any, phoneNumber: string | undefined) => {
                                                const newAction = Action.unmarshal({ ...action, phoneNumber });
                                                const newActions = [...actions];

                                                newActions[index] = newAction;

                                                setActions(newActions);
                                            }} styles={{ fieldGroup: { width: 300 } }} />
                                        </div>
                                        <TextField label="Message" value={(action as SMSNotification).content} multiline onChange={(event: any, content: string | undefined) => {
                                            const newAction = Action.unmarshal({ ...action, content });
                                            const newActions = [...actions];

                                            newActions[index] = newAction;

                                            setActions(newActions);
                                        }} styles={{ fieldGroup: { width: 400, height: 100 } }} />
                                        <div>
                                            <PrimaryButton disabled={!action.isCompletelyDefined()} onClick={async () => {
                                                const data = {
                                                    name: "FE 4512",
                                                    street: "Beilsteinerweg",
                                                    streetNumber: "22",
                                                    city: "Seck",
                                                    date: "12.12.2024",
                                                    time: "23:45",
                                                };
                                                const content = Mustache.render((action as SMSNotification).content, data);

                                                try {
                                                    await userAPI.sendSMS((action as SMSNotification).phoneNumber, content);

                                                    setPopupMessage('Message wurde gesendet.');
                                                    setIsPopupVisible(true);
                                                } catch (error) {
                                                }
                                            }}>
                                                Test
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        } else {
                            return <></>;
                        }
                    })()}
                </div>)}
            </div>
        </Section>
        {isPopupVisible && (
            <Layer>
                <Popup
                    style={{
                        background: 'rgba(0, 0, 0, 0.2)',
                        bottom: '0',
                        left: '0',
                        position: 'fixed',
                        right: '0',
                        top: '0',
                    }}
                    role="dialog"
                    aria-modal="true"
                    onDismiss={() => setIsPopupVisible(false)}
                    enableAriaHiddenSiblings={true}
                >
                    <Overlay onClick={() => setIsPopupVisible(false)} />
                    <FocusTrapZone>
                        <div role="document" className="width400 paddingL" style={{
                            background: 'white',
                            left: '50%',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            <div className='displayFlex alignItemsCenter gapL'>
                                <FontIcon aria-label="Success" iconName="Like" className="colorContrast" style={{
                                    fontSize: 30,
                                    height: 30,
                                    width: 30,
                                    margin: '0 15px',
                                }} />
                                <div>{popupMessage}</div>
                            </div>
                            <div className='marginTopL'>
                                <DefaultButton onClick={() => setIsPopupVisible(false)}>Close Popup</DefaultButton>
                            </div>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        )}
    </div>;
}

export default function AlertManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { showDeleteDialog, pushError } = useModalContext() as any;
    const { activateBusinessRule, deactivateBusinessRule } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [alerts, setAlerts] = useState([]) as any;
    const [selectedAlert, setSelectedAlert] = useState() as any;
    const [nextRefresh, setNextRefresh] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const loadRuntimes = async () => {
        setRefreshing(true);

        try {
            const alerts = await userAPI.getBusinessRules(authentication.customer);

            setAlerts(alerts);
        } finally {
            setRefreshing(false);
            setNextRefresh(60);
        }
    };

    const columns = [{
        name: '',
        width: '50px',
        cell: (alert: any) => <ActionButton iconProps={{ iconName: 'Delete' }}
            onClick={async () => {
                showDeleteDialog('Gerät löschen', alert.name, async () => {
                    await userAPI.deleteBusinessRule(authentication.customer, alert);

                    setAlerts(await userAPI.query(from(BusinessRule.type)));
                });
            }}></ActionButton>,
        sortable: true,
    }, {
        name: '',
        width: '120px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex justifyContentCenter'>
            {alert.status === BusinessRuleRuntimeStatus.stopped
                ?
                <ActionButton onClick={async () => {
                    try {
                        console.log('Activate');
                        await userAPI.activateBusinessRule(authentication.customer, alert);
                        await loadRuntimes();
                    } catch (error) {
                        pushError(error);
                    }
                }}>
                    Activate
                </ActionButton>
                :
                <ActionButton onClick={async () => {
                    try {
                        console.log('Deactivate');
                        await userAPI.deactivateBusinessRule(authentication.customer, alert);
                        await loadRuntimes();
                    } catch (error) {
                        pushError(error);
                    }
                }}>
                    Deactivate
                </ActionButton>
            }
        </div>,
        sortable: true,
    }, {
        name: 'Status',
        width: '100px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex alignItemsCenter'>
            {(() => {
                switch (alert.status) {
                    case BusinessRuleRuntimeStatus.running: return <TooltipHost content="Running">
                        <Spinner size={SpinnerSize.small} />
                    </TooltipHost>;
                    case BusinessRuleRuntimeStatus.stopped: return <TooltipHost content="Stopped">
                        <FontIcon iconName="Warning" className="colorRed" style={{
                            fontSize: 30,
                            height: 30,
                            width: 30,
                        }} /></TooltipHost>;
                    case BusinessRuleRuntimeStatus.interrupted: return <TooltipHost content="Interrupted">
                        <FontIcon iconName="LightingBolt" style={{
                            fontSize: 30,
                            height: 30,
                            width: 30,
                            margin: '0 15px',
                        }} /></TooltipHost>;
                    case BusinessRuleRuntimeStatus.unknown:
                    default: return <TooltipHost content="Interrupted">
                        <FontIcon iconName="StatusCircleQuestionMark" style={{
                            fontSize: 30,
                            height: 30,
                            width: 30,
                        }} /></TooltipHost>;
                }
            })()}
        </div>,
        sortable: true,
    }, {
        name: 'Activation',
        width: '200px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            <span>{alert.activation ? `${new Date(alert.activation).toLocaleDateString()} ${new Date(alert.activation).toLocaleTimeString()}` : '-'}</span>
        </div>,
        sortable: true,
    }, {
        name: 'Last Check',
        width: '200px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            <span>{alert.lastCheck ? `${new Date(alert.lastCheck).toLocaleDateString()} ${new Date(alert.lastCheck).toLocaleTimeString()}` : '-'}</span>
        </div>,
        sortable: true,
    }, {
        name: 'Last Execution',
        width: '200px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            <span>{alert.lastExecution ? `${new Date(alert.lastExecution).toLocaleDateString()} ${new Date(alert.lastExecution).toLocaleTimeString()}` : '-'}</span>
        </div>,
        sortable: true,
    }, {
        name: 'Name',
        selector: (alert: any) => alert.name,
        width: '200px',
        cell: (alert: any) => <span>{alert.name}</span>,
        sortable: true,
    }, {
        name: 'Description',
        selector: (alert: any) => alert.description,
        width: '300px',
        cell: (alert: any) => <span>{alert.description}</span>,
        sortable: true,
    }];

    useEffect(() => {
        loadRuntimes();

        const interval = setInterval(async () => {
            const newNextRefresh = nextRefresh - 5;

            if (newNextRefresh <= 0) {
                await loadRuntimes();
            } else {
                setNextRefresh(newNextRefresh);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [nextRefresh]);

    return <><div>
        {selectedAlert
            ?
            <AlertPanel alert={selectedAlert} setAlert={async (alert: BusinessRule | null) => {
                setSelectedAlert(null);
                setAlerts(await userAPI.query(new Query(new EntityQuery(BusinessRule.type))));
            }}></AlertPanel>
            :
            <div>
                <div className='displayFlex'>
                    <ActionButton iconProps={{ iconName: 'CommentAdd' }}
                        onClick={() => {
                            const alert = new BusinessRule({});

                            setSelectedAlert(alert);
                        }}>
                        Add alert
                    </ActionButton>
                    <div className='flexGrow1 displayFlex alignItemsCenter justifyContentEnd gapXS'>
                        <div className='textXS'>{refreshing ? 'Refreshing ...' : `Next refresh in ${nextRefresh} seconds.`}</div>
                        <ActionButton disabled={refreshing} iconProps={{ iconName: 'Refresh' }}
                            onClick={() => {
                                loadRuntimes();
                            }}>
                        </ActionButton>
                    </div>
                </div>
                <div className='marginTopL borderNeutral'>
                    <DataTable
                        data={alerts}
                        columns={columns}
                        customStyles={dataTableStyles}
                        selectableRows
                        selectableRowsSingle
                        onSelectedRowsChange={({ selectedRows }) => {
                            setSelectedAlert(selectedRows && selectedRows.length > 0 ? selectedRows[0] : null);
                        }}
                        pagination
                        paginationPerPage={5}
                        noDataComponent={<LoadingIndicator loading={true}></LoadingIndicator>}
                    />
                </div>
            </div>
        }
    </div>
    </>;
}