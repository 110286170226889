import { DefaultButton, Dialog, DialogFooter, DialogType, FontIcon, IconButton, PrimaryButton, TextField } from "@fluentui/react";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationError, UnauthorizedError } from "../general/Errors";
import { useNavigate } from "react-router-dom";

type ModalContextType = {
    showDeleteDialog: any;
    showConfirmationDialog: any;
    pushMessage: any;
    pushError: any;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalContextProvider = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [deleteDialogTitle, setDeleteDialogTitle] = useState('');
    const [deleteDialogKeyword, setDeleteDialogKeyword] = useState('');
    const [deleteDialogCallback, setDeleteDialogCallback] = useState() as any;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [keywordDelete, setKeywordDelete] = useState('');
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
    const [confirmationDialogCallback, setConfirmationDialogCallback] = useState() as any;
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const [popupMessages, setPopupMessages] = useState([]) as any;

    const showDeleteDialog = (title: string, keyword: string, deleteCallback: () => void) => {
        setKeywordDelete('');
        setDeleteDialogTitle(title);
        setDeleteDialogKeyword(keyword);
        setDeleteDialogCallback(() => deleteCallback);
        setDeleteDialogOpen(true);
    };

    const showConfirmationDialog = (title: string, message: string, confirmationCallback: () => void) => {
        setConfirmationDialogTitle(title);
        setConfirmationDialogMessage(message);
        setConfirmationDialogCallback(() => confirmationCallback);
        setConfirmationDialogOpen(true);
    };

    const pushMessage = (message: any) => {
        setPopupMessages([...popupMessages, { content: message }]);
    }

    const pushError = (error: any) => {
        console.error(error);

        if (error instanceof ApplicationError) {
            pushMessage(error.toString());
        } else if (error instanceof UnauthorizedError) {
            navigate('/login');
        } else {
            pushMessage(t('global.generalError'));
        }
    }

    return (
        <ModalContext.Provider value={{ showDeleteDialog, showConfirmationDialog, pushMessage, pushError }}>
            <div className="positionRelative">
                {popupMessages && popupMessages.length > 0
                    ?
                    <div className="messageBox">{popupMessages.map((message: any) => (
                        <div key={message.id} className="message paddingM displayFlex alignItemsCenter gapM">
                            <FontIcon iconName="WarningSolid" className="colorWhite" />
                            <div>
                                <div className="colorWhite textM">{message.content}</div>
                            </div>
                            <div className="flexGrow1 displayFlex justifyContentEnd">
                                <IconButton className="colorWhite" iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() =>
                                    setPopupMessages(popupMessages.filter((otherMessage: any) => message !== otherMessage))} />
                            </div>
                        </div>)
                    )}</div>
                    :
                    <></>
                }
                {props.children}
                <Dialog
                    hidden={!deleteDialogOpen}
                    onDismiss={() => setDeleteDialogOpen(false)}
                    maxWidth={600}
                    minWidth={600}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: deleteDialogTitle,
                    }}
                    modalProps={{
                        isBlocking: true,
                    }}
                >
                    <div className='paddingS'>
                        {/* {properties.text ?
                <div>{properties.text}</div>
                :
                <></>
            } */}
                        <div>Gib <span className='fontWeightBold'>{deleteDialogKeyword}</span> zum Löschen ein.</div>
                        <TextField className="marginTopS" value={keywordDelete} onChange={(_: any, keywordDelete: string | undefined) => setKeywordDelete(keywordDelete || '')}></TextField>
                    </div>
                    <DialogFooter className="marginTopL">
                        <PrimaryButton disabled={deleteDialogKeyword !== keywordDelete.trim()} onClick={() => {
                            deleteDialogCallback();
                            setDeleteDialogOpen(false);
                        }} >{t('global.delete')}</PrimaryButton>
                        <DefaultButton onClick={() => {
                            setDeleteDialogOpen(false);
                        }} text={t('global.cancel')} />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!confirmationDialogOpen}
                    onDismiss={() => setConfirmationDialogOpen(false)}
                    maxWidth={600}
                    minWidth={600}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: confirmationDialogTitle,
                    }}
                    modalProps={{
                        isBlocking: true,
                    }}
                >
                    <div className='paddingS'>
                        {/* {properties.text ?
                <div>{properties.text}</div>
                :
                <></>
            } */}
                        <div>{confirmationDialogMessage}</div>
                    </div>
                    <DialogFooter className="marginTopL">
                        <PrimaryButton onClick={() => {
                            confirmationDialogCallback();
                            setConfirmationDialogOpen(false);
                        }} >{t('global.confirm')}</PrimaryButton>
                        <DefaultButton onClick={() => {
                            setConfirmationDialogOpen(false);
                        }} text={t('global.cancel')} />
                    </DialogFooter>
                </Dialog>
            </div>
        </ModalContext.Provider>
    )
};

export const useModalContext = () => useContext(ModalContext);