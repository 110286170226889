import './css/website.sass';
import WebsiteHeader from './WebsiteHeader';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import { PrimaryButton, TextField } from '@fluentui/react';
import { useState } from 'react';
import { userAPI } from '../UserAPI';
import { useModalContext } from '../components/ModalProvider';

export default function SupportPage() {
  const { t } = useTranslation();
  const { pushMessage, pushError } = useModalContext() as any;
  const [email, setEmail] = useState('');
  const [request, setRequest] = useState('');

  const isEmailValid = () => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  return (
    <div className="website">
      <div className='wave'></div>
      <div className='wave'></div>
      <div className='wave'></div>
      <WebsiteHeader showSidePanel={true} showMapItem={true} showTabs={false}></WebsiteHeader>
      <div className='body'>
        <div className="content">
          <div className='marginTopXXL titleText'>{t('supportPage.title')}</div>
          <div className='marginTopL bodyText'>{t('supportPage.guidelines')}</div>
          <div className="marginTopL">
            <TextField
              label={t('supportPage.email')}
              value={email}
              placeholder={t('supportPage.emailPlaceholder')}
              styles={{
                fieldGroup: [
                  { width: '300px' },
                ]
              }}
              errorMessage={isEmailValid() ? undefined : t('supportPage.emailNotValid')}
              onChange={(_: any, email: any) => {
                setEmail(email);
              }}
            />
          </div>
          <div className="marginTopM">
            <TextField
              className='height100'
              multiline
              label={t('supportPage.request')}
              value={request}
              placeholder={t('supportPage.requestPlaceholder')}
              styles={{
                fieldGroup: [
                  { width: '300px' },
                ]
              }}
              errorMessage={request && request.trim().length > 10 ? undefined : t('supportPage.requestEmpty')}
              onChange={(_: any, request: any) => {
                setRequest(request);
              }}
            />
          </div>
          <div className="marginTopL">
            <PrimaryButton text={t('supportPage.submit')} onClick={async () => {
              try {
                await userAPI.submitSupportRequest(email, request);
                pushMessage(t('supportPage.submitSuccess'));
              } catch (error) {
                pushError(t('supportPage.submitError'));
              }
            }}></PrimaryButton>
          </div>
          <div className='height400'></div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
