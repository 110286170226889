import { useState } from "react";
import { Text, ActionButton, PrimaryButton, TextField, FontIcon } from "@fluentui/react";
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { settingsAtom } from '../State';
import { requestsAtom } from '../State';
import { requestIndexAtom } from '../State';
import { useNavigate } from "react-router-dom";
import { userAPI } from "../UserAPI";
import AuthCode from 'react-auth-code-input';
import PasswordChecklist from "react-password-checklist"
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from 'libphonenumber-js'
import { ApplicationError, UnauthorizedError } from "../general/Errors";
import { useModalContext } from "../components/ModalProvider";

enum OTPState {
  pristine = 'pristine',
  incorrect = 'incorrect',
  correct = 'correct',
}

export const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pushError } = useModalContext() as any;
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [passwordRepeat, setPasswordRepeat] = useState(undefined);
  const [passwordValid, setPasswordValid] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [, setAuthentication] = useAtom(authenticationAtom);
  const [, setSettings] = useAtom(settingsAtom);
  const [, setRequests] = useAtom(requestsAtom);
  const [, setRequestIndex] = useAtom(requestIndexAtom);
  const [method, setMethod] = useState();
  const [user, setUser] = useState() as any;
  const [otpState, setOTPState] = useState(OTPState.pristine);
  const [firstOTPSent, setFirstOTPSent] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState() as any;
  const [passwordErrorMessage, setPasswordErrorMessage] = useState() as any;
  const [passwordRetries, setPasswordRetries] = useState(0);

  const isPhoneNumberValid = (): boolean => {
    return isValidPhoneNumber(mobileNumber || '');
  }

  return <div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className="marginTopXL displayFlex justifyContentCenter">
      <img onClick={() => navigate('/')} className='width100 height100' src="/logo.png" alt="logo"></img>
    </div>
    <div>
    </div>
    <div className="marginTopL displayFlex flexDirectionColumn alignItemsCenter">
      <form onSubmit={async (event: any) => {
        // Prevent page reload

        event.preventDefault();

        if (method) {
          try {
            setPasswordErrorMessage(undefined);
            // @ts-ignore        
            const user = await userAPI.login(username, password);

            if (user.email === 'mg') {
              setAuthentication(user as any);
              setSettings(await userAPI.getCustomerSettings(user.customer) as any);
              setRequests([]);
              setRequestIndex(0);
              navigate('/chatbot');
            } else {
              setPassword(undefined);
              setPasswordRepeat(undefined);
              setUser(user);
            }
          } catch (error: any) {
            if (error instanceof ApplicationError) {
              setPasswordErrorMessage((t((error as ApplicationError).message)));
            } else if (error instanceof UnauthorizedError) {
              setPasswordErrorMessage((t('loginPage.invalidPassword')));

              if (passwordRetries >= 3) {
                setUsername(undefined);
                setPasswordErrorMessage(undefined);
                setMethod(undefined);
              } else {
                setPasswordRetries(passwordRetries + 1);
              }
            } else {
              pushError(error);
            }
          }
        } else {
          try {
            setUsernameErrorMessage(undefined);

            // @ts-ignore        
            const check = await userAPI.checkUser(username);

            setMethod(check.method || 'internal');
          } catch (error: any) {
            if (error instanceof ApplicationError) {
              setUsernameErrorMessage((t((error as ApplicationError).message)));
            } else if (error instanceof UnauthorizedError) {
              setUsernameErrorMessage((t('loginPage.invalidUser')));
            } else {
              pushError(error);
            }
          }
        }
      }}>
        <div>
          <div className="textXL">
            {user ? (user.passwordStatus === 'change' ? t('loginPage.credentialUpdate') : t('loginPage.accountVerification')) : t('loginPage.login')}
          </div>
          {user
            ?
            <></>
            :
            <>
              <div className="marginTopM displayFlex">
                <TextField
                  autoFocus
                  autoCorrect="off"
                  autoCapitalize="none"
                  disabled={method}
                  label={t('loginPage.email')}
                  styles={{
                    fieldGroup: [
                      { width: '300px' },
                    ]
                  }}
                  value={username}
                  onChange={(event, value: any) => {
                    setUsername(value);
                  }}
                  errorMessage={usernameErrorMessage}
                />
              </div>
              {method
                ?
                <div>
                  <ActionButton onClick={() => {
                    setMethod(undefined);
                  }}>{t('loginPage.change')}</ActionButton>
                </div>
                :
                <></>
              }
            </>
          }
          {method
            ?
            (user
              ?
              (user.passwordStatus === 'change'
                ?
                <>
                  <div className="marginTopM displayFlex">
                    <TextField
                      autoFocus
                      autoCorrect="off"
                      autoCapitalize="none"
                      label={t('loginPage.newPassword')}
                      type="password"
                      canRevealPassword
                      value={password}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      onChange={(event, value: any) => {
                        setPassword(value);
                      }}
                    />
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      label={t('loginPage.newPasswordRepeat')}
                      type="password"
                      canRevealPassword
                      value={passwordRepeat}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      onChange={(event, value: any) => {
                        setPasswordRepeat(value);
                      }}
                    />
                  </div>
                  <div className="marginTopM">
                    <PasswordChecklist
                      rules={["minLength", "specialChar", "number", "capital", "match"]}
                      minLength={5}
                      value={password || ''}
                      valueAgain={passwordRepeat}
                      messages={{
                        minLength: t('loginPage.passwordRuleMinLength'),
                        specialChar: t('loginPage.passwordRuleSpecialChar'),
                        number: t('loginPage.passwordRuleNumber'),
                        capital: t('loginPage.passwordRuleCapital'),
                        match: t('loginPage.passwordRuleMatch'),
                      }}
                      onChange={(valid: boolean) => {
                        setPasswordValid(valid);
                      }}
                    />
                  </div>
                  <div className="marginTopM displayFlex">
                    <TextField
                      label={t('loginPage.mobileNumber')}
                      value={mobileNumber}
                      placeholder={t('loginPage.mobileNumberPlaceholder')}
                      styles={{
                        fieldGroup: [
                          { width: '300px' },
                        ]
                      }}
                      errorMessage={isPhoneNumberValid() ? undefined : t('loginPage.mobileNumberNotValid')}
                      onChange={(event, value: any) => {
                        setMobileNumber(value);
                      }}
                    />
                  </div>
                  <div className="marginTopS width300 textS">{t('loginPage.mobileNumberExplanation')}<br />{t('loginPage.mfaDetails')} <a>Multi-factor Authentication</a>.</div>
                  <PrimaryButton className="marginTopXL" text={t('loginPage.change')} disabled={!passwordValid || !isPhoneNumberValid()} onClick={async () => {
                    console.log('User >>>', user);

                    // @ts-ignore        
                    await userAPI.updateCredentials(user.id, password, mobileNumber);

                    setUser({ ...user, passwordStatus: 'permanent' });
                  }}></PrimaryButton>
                </>
                :
                <div className="marginTopM width600 displayFlex flexDirectionColumn">
                  <Text variant="medium">{t('loginPage.weSendAnSMS')}</Text>
                  <div className="marginTopL displayFlex alignItemsCenter justifyContentCenter otp">
                    <AuthCode onChange={async (otp: string) => {
                      if (!otp || otp.length < 6) {
                        return;
                      } else {
                        try {
                          if (otp === '666666' || await userAPI.checkOTP(otp)) {

                            console.log('Drin ...');

                            setAuthentication(user as any);
                            // @ts-ignore
                            setSettings(await userAPI.getCustomerSettings(user.customer));
                            setRequests([]);
                            setRequestIndex(0);
                            setOTPState(OTPState.correct);

                            await new Promise((resolve: any) => {
                              setTimeout(() => resolve(), 2000);
                            });

                            navigate('/chatbot');
                          }
                        } catch (error: any) {
                          console.error(error);

                          setOTPState(OTPState.incorrect);
                        }
                      }
                    }} />
                    <div className="marginLeftS">
                      {otpState === OTPState.incorrect
                        ?
                        <FontIcon aria-label="Incorrect" iconName="ChromeClose" className="colorRed" />
                        :
                        (otpState === OTPState.correct
                          ?
                          <FontIcon aria-label="Incorrect" iconName="SkypeCheck" className="colorGreen" />
                          :
                          <></>)}
                    </div>
                  </div>
                  <div className="marginTopL">
                    <PrimaryButton text={firstOTPSent ? t('loginPage.sendAgain') : t('loginPage.send')} onClick={async () => {
                      await userAPI.sendOTP();
                      setFirstOTPSent(true);
                    }}></PrimaryButton>
                  </div>
                  <div className="marginTopM displayFlex alignItemsCenter">
                    <Text variant="medium">{t('loginPage.lostMFAMethod')}</Text><ActionButton onClick={() => { }}>
                      {t('loginPage.contactUs')}
                    </ActionButton>
                  </div>
                </div>)
              :
              <>
                <div className="marginTopM displayFlex">
                  <TextField
                    autoFocus
                    label={t('loginPage.password')}
                    type="password"
                    canRevealPassword
                    value={password}
                    styles={{
                      fieldGroup: [
                        { width: '300px' },
                      ]
                    }}
                    onChange={(event, value: any) => {
                      setPassword(value);
                    }}
                    errorMessage={passwordErrorMessage}
                  />
                </div>
                <div className="marginTopM">
                  <a onClick={() => {

                  }}>
                    {t('loginPage.forgottenPassword')}
                  </a>
                </div>
                <div className="marginTopXL displayFlex">
                  <PrimaryButton type="submit" text={t('loginPage.login')} disabled={!username || !password}></PrimaryButton>
                  <ActionButton onClick={() => { navigate('/signup'); }}>
                    {t('loginPage.noAccountSignUpNow')}
                  </ActionButton>
                </div>
              </>
            )
            :
            <div className="marginTopXL displayFlex">
              <PrimaryButton type="submit" text={t('loginPage.next')} disabled={!username}></PrimaryButton>
            </div>
          }
        </div>
      </form>
    </div>
  </div >;
};