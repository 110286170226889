import { PartialTheme, ThemeProvider } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import tinycolor from "tinycolor2";
import { userAPI } from "./UserAPI";
import { useAtom } from "jotai";
import { themeAtom } from './State';

export const styleCalculator = [
    { direction: -1, factor: 10, name: 'Primary Darker', color: '#649aaa', field: 'primaryDarker' },
    { direction: -1, factor: 5, name: 'Primary Dark', color: '#649aaa', field: 'primaryDark' },
    { direction: 1, factor: 0, name: 'Primary', color: '#649aaa', field: 'primary' },
    { direction: 1, factor: 40, name: 'Primary Light', color: '#649aaa', field: 'primaryLight' },
    { direction: 1, factor: 45, name: 'Primary Lighter', color: '#649aaa', field: 'primaryLighter' },
    { direction: -1, factor: 10, name: 'Secondary Darker', color: '#5F3A79', field: 'secondaryDarker' },
    { direction: -1, factor: 5, name: 'Secondary Dark', color: '#5F3A79', field: 'secondaryDark' },
    { direction: 1, factor: 0, name: 'Secondary', color: '#5F3A79', field: 'secondary' },
    { direction: 1, factor: 40, name: 'Secondary Light', color: '#5F3A79', field: 'secondaryLight' },
    { direction: 1, factor: 45, name: 'Secondary Lighter', color: '#5F3A79', field: 'secondaryLighter' },
    { direction: -1, factor: 10, name: 'Tertiary Darker', color: '#98AC4D', field: 'tertiaryDarker' },
    { direction: -1, factor: 5, name: 'Tertiary Dark', color: '#98AC4D', field: 'tertiaryDark' },
    { direction: 1, factor: 0, name: 'Tertiary', color: '#98AC4D', field: 'tertiary' },
    { direction: 1, factor: 40, name: 'Tertiary Light', color: '#98AC4D', field: 'tertiaryLight' },
    { direction: 1, factor: 45, name: 'Tertiary Lighter', color: '#98AC4D', field: 'tertiaryLighter' },
    { direction: 1, factor: 0, name: 'Accent', color: '#1E1A76', field: 'accent' },
];

const calculateThemeColor = (parameters: any) => {
    if (parameters.direction > 0) {
        return tinycolor(parameters.color).lighten(parameters.factor).toHexString();
    } else {
        return tinycolor(parameters.color).darken(parameters.factor).toHexString();
    }
}

export const defaultTheme = styleCalculator.reduce((map: any, parameters: any) => {
    map[parameters.field] = calculateThemeColor(parameters);

    return map;
}, {});

const calculatePartialTheme = (theme: any) => {
    return {
        palette: {
            themePrimary: theme.primary,
            themeDark: theme.primaryDark,
            themeDarker: theme.primaryDarker,
            themeLight: theme.primaryLighter,
            themeLighter: theme.primaryLighter,
        },
    }
}

const adjustCSSColors = (theme: any) => {
    document.body.style.setProperty('--primary-color',theme.primary);
}

interface Properties {
    children?: any;
}

export function CustomerThemeProvider(properties: Properties) {
    const [searchParams] = useSearchParams();
    const [theme, setTheme] = useAtom(themeAtom);
    const [partialTheme, setPartialTheme] = useState(calculatePartialTheme(defaultTheme));

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                if (!!searchParams.get('tenant')) {
                    if (searchParams.get('tenant') === 'null') {
                        setTheme(defaultTheme);
                        setPartialTheme(calculatePartialTheme(defaultTheme));
                        adjustCSSColors(defaultTheme);
                    } else {
                        // @ts-ignore
                        const theme = await userAPI.getTheme(searchParams.get('tenant'));

                        console.log('Customer theme retrieved >>>', theme);

                        setTheme(theme);
                        setPartialTheme(calculatePartialTheme(theme));
                        adjustCSSColors(theme);
                    }
                }
            } catch (e) {
                console.log('Failed to obtain theme', e);
            }
        }

        fetchTheme();
    }, []);

    return <div>
        <ThemeProvider theme={partialTheme}>
            {properties && properties.children
                ?
                (properties.children)
                :
                <></>
            }
        </ThemeProvider>
    </div>;
}

export const useCustomerTheme = () => {
    const [theme, setTheme] = useAtom(themeAtom) as any;

    return {
        theme: theme || defaultTheme,
        setTheme,
    }
}

