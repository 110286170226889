import { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@fluentui/react-components";
import Header from "./Header";
import Map from './Map';
import { IconButton, Text } from "@fluentui/react";
import BuildingProperties from "./object-properties/BuildingProperties";
import { useAtom } from "jotai";
import { mapQueryAtom } from "./State";
import { mapDataAtom } from "./State";
import { mapOptionsAtom } from "./State";
import { selectedEntityAtom } from "./State";
import AreaProperties from "./object-properties/AreaProperties";
import LocalGovernmentProperties from "./object-properties/LocalGovernmentProperties";
import PostalCodeProperties from "./object-properties/PostalCodeProperties";
import { useLocation, useNavigate } from "react-router-dom";
import { userAPI } from "./UserAPI";
import PlotOfLandProperties from "./object-properties/PlotOfLandProperties";
import { ChargingType, Entity, University } from "@the-real-insight/tri-model";
import UniversityProperties from "./object-properties/UniversityProperties";
import ChargingStationProperties from "./object-properties/ChargingStationProperties";
import BillboardProperties from "./object-properties/BillboardProperties";
import AdvertisementColumnProperties from "./object-properties/AdvertisementColumnProperties";
import { useTranslation } from 'react-i18next';
import { useCustomerTheme } from "./Theme";

export default function MapView() {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const [, setObjectPanelExpanded] = useState(false);
    const [pois, setPoIs] = useState([]) as any[];
    const [selectedEntity, setSelectedEntity] = useAtom(selectedEntityAtom) as any;
    const [entityHistory, setEntityHistory] = useState([]) as any;
    const [entityHistoryIndex, setEntityHistoryIndex] = useState(0) as any;
    const [mapQuery] = useAtom(mapQueryAtom);
    const [mapData] = useAtom(mapDataAtom);
    const [mapOptions] = useAtom(mapOptionsAtom) as any;
    const { search } = useLocation();
    const navigate = useNavigate();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const selectEntity = (entity: any, changeHistory: boolean = true) => {
        console.log('Set entity >>>', entity);
        setSelectedEntity(entity);

        if (changeHistory) {
            setEntityHistory([...entityHistory, entity]);
            setEntityHistoryIndex(entityHistory.length);
        }

        searchParams.set("selectedEntityType", entity.entityType || entity.class);
        searchParams.set("selectedEntity", entity._id);
        navigate(`/map?${searchParams.toString()}`)

        if (entity) {
            setObjectPanelExpanded(true);
        }
    }

    useEffect(() => {
        const call = async () => {
            const entityType = searchParams.get("selectedEntityType");
            const entityId = searchParams.get("selectedEntity");

            if (!selectEntity) {
                // TODO Need to fetch dependents like address

                // if (!entityType || !entityId) {
                //     setSelectedEntity(null);
                // } else {
                //     // @ts-ignore
                //     setSelectedEntity(await userAPI.getEntityByID(entityType, entityId));
                // }
            }
        };

        call();
    }, [searchParams]);

    const poisChanged = (pois: any[]) => {
        console.log('POIs changed >>>', pois);

        setPoIs(pois);
    }

    const propertiesTitle = (entity: any) => {
        if (!entity) {
            return <></>;
        }

        switch (entity.getTypeName ? (entity as Entity).getTypeName() : entity.class) {
            case 'Building': return <div></div>;
            case 'ChargingStation': return <div>
                <div>
                    <div className="textM">{entity.chargingType === ChargingType.normalCharging ? 'Ladesäule' : 'Schnellladesäule'}</div>
                </div>
                <div>
                    <div className="headerXL">{entity.operator}</div>
                </div>
            </div>;
            case 'Billboard': return <div>
                <div>
                    <div className="textM">Anzeigetafel</div>
                </div>
                <div>
                    <div className="headerXL">{entity.operator || 'Unbekannter Betreiber'}</div>
                </div>
            </div>;
            case 'AdvertisementColumn': return <div>
                <div>
                    <div className="textM">Litfaßsäule</div>
                </div>
                <div>
                    <div className="headerXL">{entity.operator || 'Unbekannter Betreiber'}</div>
                </div>
            </div>;
            case 'University': return <div>
                <div>
                    <div className="textM">Universität</div>
                </div>
                <div>
                    <div className="headerXL">{entity.name}</div>
                </div>
            </div>;
            case 'Area': return <div><div>
                <div className="textM">Gebiet</div>
            </div>
                <div>
                    <div className="headerXL">{entity && entity.id ? `${entity.id}` : ''}</div>
                </div>
            </div>;
            case 'Bridge':
            case 'Tile':
            case 'LocalGovernment':
            case 'PostalCode':
            case 'PlotOfLand':
            default: return <div>
                <div className="textM">{(entity as Entity).getTypeClass().type.label.de}</div>
                <div className="headerXL">{entity && (entity as any).name ? (entity as any).name : 'Unbenannt'}</div>
            </div>
        }
    }

    return (
        <div className="width100vw height100vh displayFlex flexDirectionColumn">
            <Header showHome={true} title={mapOptions && mapOptions.title ? mapOptions.title : ''}></Header>
            <div className="flex1 displayFlex paddingM gapM" style={{ backgroundColor: theme.primaryLighter }}>
                <div className="flex1">
                    <Map setObject={selectEntity} options={mapOptions} showMiniMap={true} data={mapData} objectClass={mapQuery ? (mapQuery as any).objectClass : null} pois={pois} query={mapQuery} />
                </div>
                {selectedEntity as any != null ?
                    <div className="width40vw borderNeutral backgroundColorWhite">
                        <div className="displayFlex gapM paddingXS borderBottomNeutral">
                            <IconButton disabled={entityHistory.length === 0 || entityHistoryIndex === 0} iconProps={{ iconName: 'ChevronLeft' }} title="Back" ariaLabel="Back" onClick={() => {
                                const newEntityHistoryIndex = Math.max(entityHistoryIndex - 1, 0);

                                setEntityHistoryIndex(newEntityHistoryIndex);
                                selectEntity(entityHistory[newEntityHistoryIndex], false);
                            }} />
                            <div className="flexGrow1 displayFlex gapM">
                                <div>{propertiesTitle(selectedEntity)}</div>
                                <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => {
                                    navigate(`/entity?selectedEntityType=${selectedEntity.getTypeName()}&selectedEntity=${selectedEntity._id}`);
                                }}></IconButton>
                            </div>
                            <IconButton disabled={entityHistory.length === 0 || entityHistoryIndex === entityHistory.length - 1} iconProps={{ iconName: 'ChevronRight' }} title="Back" ariaLabel="Back" onClick={() => {
                                const newEntityHistoryIndex = Math.min(entityHistoryIndex + 1, entityHistory.length - 1);

                                setEntityHistoryIndex(newEntityHistoryIndex);
                                selectEntity(entityHistory[newEntityHistoryIndex], false);
                            }} />
                            <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => setSelectedEntity(null)} />
                        </div>
                        <div className="paddingM">
                            {(() => {
                                // @ts-ignore
                                switch (selectedEntity.class) {
                                    case 'Bridge':
                                        return <div>
                                            <div>
                                                <Text variant="medium">Bridge</Text>
                                            </div>
                                            <div>
                                                <Text variant="xxLarge">{selectedEntity ? (selectedEntity as any).name : ''}</Text>
                                            </div>
                                        </div>;
                                    case 'Tile':
                                        return <AreaProperties area={selectedEntity}></AreaProperties>;
                                    case 'LocalGovernment':
                                        return <LocalGovernmentProperties area={selectedEntity}></LocalGovernmentProperties>;
                                    case 'PostalCode':
                                        return <PostalCodeProperties postalCode={selectedEntity}></PostalCodeProperties>;
                                    case 'PlotOfLand':
                                        return <PlotOfLandProperties plotOfLand={selectedEntity} poisChanged={poisChanged}></PlotOfLandProperties>;
                                    case 'Building': return <BuildingProperties building={selectedEntity} poisChanged={poisChanged}></BuildingProperties>;
                                }

                                switch ((selectedEntity as Entity).getTypeName()) {
                                    case 'University': return <UniversityProperties university={selectedEntity}></UniversityProperties>
                                    case 'ChargingStation': return <ChargingStationProperties chargingStation={selectedEntity}></ChargingStationProperties>
                                    case 'Billboard': return <BillboardProperties billboard={selectedEntity}></BillboardProperties>
                                    case 'AdvertisementColumn': return <AdvertisementColumnProperties advertisementColumn={selectedEntity}></AdvertisementColumnProperties>
                                    default: return <></>;
                                }
                            })()}
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    );
}