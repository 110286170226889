import DataTable from "react-data-table-component";
import AddressPicker from "./AddressPicker";
import { Label } from "@fluentui/react-components";
import { ActionButton, Spinner, SpinnerSize, TextField, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { userAPI } from "../UserAPI";
import { Asset, Building, EntityQuery, Floor, Product, Query, Site, and, from, is, or } from "@the-real-insight/tri-model";
import { dataTableStyles } from "../styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { pdf, Document, Page, Text as PDFText, Image, StyleSheet, View } from '@react-pdf/renderer';
import LoadingIndicator from "./LoadingIndicator";
import { Icon } from "./Icon";
import { useModalContext } from "./ModalProvider";

interface Properties {
    className?: string;
    product?: Product;
    processError?: (error: any) => void;
    scopeSites?: Site[],
    scopeBuildings?: Building[],
    scopeFloors?: Floor[],
    showOperatingState?: boolean,
}

const PDFQRCodes = ({ qrCodes }: { qrCodes: any[] }) => {
    const styles = StyleSheet.create({
        page: {
            margin: '0.5cm',
            padding: 0,
        },
        qrCodes: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        qrCode: {
            border: '1px solid #efefef',
            width: '4cm',
            height: '4cm',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'

        },
        qrCodeImage: {
            width: '2.5cm',
            height: '2.5cm',
        },
        companyText: {
            marginTop: '0.2cm',
            marginBottom: '0.2cm',
            fontSize: 9,
        },
        idText: {
            marginTop: '0.2cm',
            fontSize: 6,
        }
    });

    try {
        return <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.qrCodes}>
                    {
                        qrCodes.map((qrCode: any) =>
                            <View style={styles.qrCode}>
                                <PDFText style={styles.companyText}>FireIQ</PDFText>
                                <Image style={styles.qrCodeImage} src={qrCode.image} />
                                <PDFText style={styles.idText}>{qrCode.id}</PDFText>
                            </View>
                        )
                    }
                </View>
            </Page>
        </Document >
    } catch (error) {
        console.log(error);

        return <Document><Page><PDFText>Error in Invoice Creation.</PDFText></Page></Document>
    }
};

export default function AssetList(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showDeleteDialog, pushError } = useModalContext() as any;
    const [quickFilter, setQuickFilter] = useState() as any;
    const [assets, setAssets] = useState([]) as any;
    const [address, setAddress] = useState() as any;
    const [loading, setLoading] = useState(false) as any;
    const [downloading, setDownloading] = useState(false) as any;

    const assetColumns = [{
        name: '',
        width: '50px',
        cell: (asset: any) => (
            <div className='displayFlex alignItemsCenter'>
                <ActionButton iconProps={{ iconName: 'Delete' }}
                    onClick={async () => {
                        showDeleteDialog('Gerät löschen', asset.id, () => {

                        });
                    }}>
                </ActionButton>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Type',
        width: '300px',
        selector: (asset: any) => asset.schemaCategoryName,
        cell: (asset: any) => (
            <div className='height30 displayFlex gapM alignItemsCenter'>
                {asset.icon
                    ?
                    <div className="width30 height30" style={{ minWidth: '30px', backgroundColor: 'white', border: `1px solid ${asset.iconColor || 'grey'}` }}>
                        <Icon id={asset.icon}></Icon>
                    </div>
                    :
                    <div className="width30 height30" style={{ minWidth: '30px', backgroundColor: 'white', border: `1px solid grey` }}>
                    </div>
                }
                <div>
                    <div className='textS'>{asset.schemaCategory}</div>
                    <div className='textS overflowWrapWordWrap'>{asset.schemaCategoryName}</div>
                </div>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Name/ID',
        width: '220px',
        selector: (asset: any) => asset.name || asset.id,
        cell: (asset: any) => (
            <div className='displayFlex alignItemsCenter'>
                <div className='textS'>{asset.name || asset.id}</div>
                <ActionButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => {
                    navigate(`/entity?selectedEntityType=Asset&selectedEntity=${asset._id}`);
                }}></ActionButton>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Product/Manufacturer',
        width: '200px',
        selector: (asset: any) => asset.productName,
        cell: (asset: any) => (
            <div className='height20'>
                <div className='textS'>{asset.productName}</div>
                <div className='textS'>{asset.manufacturerName}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Address',
        width: '220px',
        selector: (asset: any) => `${asset.street} ${asset.streetNumber}, ${asset.city}`,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
                <div className='textS'>{asset.street} {asset.streetNumber}</div>
                <div className='textS'>{asset.postalCode} {asset.city}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Fotos',
        width: '100px',
        selector: (asset: any) => asset.photos ? asset.photos.length : 0,
        right: true,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <div className='textS'>{asset.photos ? asset.photos.length : 0}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Last Mod.',
        width: '120px',
        right: true,
        selector: (asset: any) => asset.lastModified ? new Date(asset.lastModified).getTime() : 0,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <div className='textS'>{asset.lastModified ? new Date(asset.lastModified).toLocaleDateString() : '-'}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Installed',
        width: '120px',
        right: true,
        selector: (asset: any) => asset.installed ? new Date(asset.installed).getTime() : 0,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <div className='textS'>{asset.installed ? new Date(asset.installed).toLocaleDateString() : '-'}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: 'Last Maint.',
        width: '120px',
        right: true,
        selector: (asset: any) => asset.lastMaintained ? new Date(asset.lastMaintained).getTime() : 0,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <div className='textS'>{asset.lastMaintained ? new Date(asset.lastMaintained).toLocaleDateString() : '-'}</div>
            </div>
        ),
        sortable: true,
    }];

    if (properties.showOperatingState) {
        assetColumns.push({
            name: 'Operating State',
            width: '200px',
            selector: (asset: any) => asset.operationalState,
            cell: (asset: any) => (
                <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
                    <div className='textS'><span className='colorGreen fontWeightBold'>Normal</span></div>
                </div>
            ),
            sortable: true,
        });
    }

    const calculateFilter = () => {
        const filters = [];

        console.log('Quick filter >>>', quickFilter);

        if (quickFilter && quickFilter.trim().length > 0) {
            filters.push(or(is('street').startsWith(quickFilter),
                is('city').startsWith(quickFilter),
                is('name').startsWith(quickFilter),
                is('productName').startsWith(quickFilter)));
        }

        if (address) {
            filters.push(is('street').equal(address.street));
            filters.push(is('streetNumber').equal(address.streetNumber));
            filters.push(is('postalCode').equal(address.postalCode));
            filters.push(is('city').equal(address.city));
        }

        if (properties.product && properties.product._id) {
            filters.push(is('product').equal(properties.product._id));
        }

        if (properties.scopeSites) {
            console.log('Scope sites >>>', properties.scopeSites);

            properties.scopeSites.forEach((site: any) => {
                filters.push(and(is('street').equal(site.street),
                    is('streetNumber').equal(site.streetNumber),
                    is('postalCode').equal(site.postalCode),
                    is('city').equal(site.city),
                    /*is('country').equal(site.country)*/));
            });
        }

        if (properties.scopeBuildings) {
            console.log('Scope buildings >>>', properties.scopeBuildings);

            properties.scopeBuildings.forEach((building: any) => {
                filters.push(and(is('street').equal(building.address.street),
                    is('streetNumber').equal(building.address.streetNumber),
                    is('postalCode').equal(building.address.postalCode),
                    is('city').equal(building.address.city),
                    /*is('country').equal(building.address.country)*/));
            });
        }

        if (properties.scopeFloors) {
            console.log('Scope floors >>>', properties.scopeFloors);

            properties.scopeFloors.forEach((floor: any) => {
                filters.push(is('floor').equal(floor._id))
            });
        }

        return and(...filters);
    }

    useEffect(() => {
        const call = async () => {
            try {
                const metadata = (await userAPI.getDIN276Metadata()).reduce((map: any, category: any) => {
                    map[category.code] = category;

                    return map;
                }, {});

                console.log('Address >>>', address);

                setLoading(true);

                console.log('Filter >>>', calculateFilter());

                const result = (await userAPI.query(from(Asset.type).where(calculateFilter()))).map((asset: any) => {
                    return { ...asset, schemaCategoryName: metadata[asset.schemaCategory] && metadata[asset.schemaCategory].name ? metadata[asset.schemaCategory].name : '-' };
                });

                console.log('Assets >>>', result);

                setAssets(result);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        call();
    }, [properties.product, properties.scopeSites, properties.scopeBuildings, properties.scopeFloors, quickFilter, address]);

    const downloadFile = (blob: Blob, fileName: string) => {
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Some browser needs the anchor to be in the doc

        document.body.append(link);
        link.click();
        link.remove();

        // In case the Blob uses a lot of memory

        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    return <div className={properties.className ? properties.className : ''}>
        <div className="displayFlex alignItemsStart gapL">
            <div className="displayFlex alignItemsCenter gapS">
                <Label>Quick Filter</Label>
                <TextField placeholder="Enter filter" value={quickFilter} onChange={(e: any, quickFilter: string | undefined) => setQuickFilter(quickFilter)} styles={{ fieldGroup: { width: 150 } }} />
            </div>
            <div className="displayFlex alignItemsCenter gapS">
                <Label>Product</Label>
                <TextField placeholder="Enter product name" value={''} onChange={(e: any, filter: string | undefined) => { }} styles={{ fieldGroup: { width: 300 } }} />
            </div>
            {properties.scopeBuildings || properties.scopeFloors
                ?
                <></>
                :
                <div className="displayFlex alignItemsCenter gapS">
                    <AddressPicker label={'Address'} setAddress={setAddress} />
                </div>
            }
            <div className="flexGrow1 displayFlex gapM alignItemsCenter justifyContentEnd">
                <TooltipHost content="Download ZIP with CSV and image files">
                    {downloading
                        ?
                        <div className="displayFlex gapS"><Spinner size={SpinnerSize.small} /><div className="textSmall">Downloading</div></div>
                        :
                        <ActionButton iconProps={{ iconName: 'Download' }} onClick={async () => {
                            setDownloading(true);

                            try {
                                const blob = await userAPI.queryZip(new Query(new EntityQuery(Asset.type, [], undefined, calculateFilter())));

                                downloadFile(blob, `asset-data-${new Date().toISOString()}.zip`);
                            } finally {
                                setDownloading(false);
                            }
                        }}>
                        </ActionButton>
                    }
                </TooltipHost>
                <ActionButton iconProps={{ iconName: 'QRCode' }} onClick={async () => {
                    const qrCodes: any = [];

                    for (const asset of assets) {
                        const canvasDiv = document.getElementById(`qrCanvas-${asset.id}`);

                        console.log(canvasDiv);

                        if (!canvasDiv) {
                            throw new Error('Cannot find canvas container');
                        }

                        const canvas = canvasDiv.querySelector('canvas');

                        console.log(canvas);

                        if (!canvas) {
                            throw new Error('Cannot find canvas');
                        }

                        qrCodes.push({
                            image: canvas.toDataURL('image/jpg', 0.3),
                            id: asset.id
                        });
                    }

                    const blob = await pdf(<PDFQRCodes qrCodes={qrCodes} ></PDFQRCodes>).toBlob();

                    console.log(blob);

                    downloadFile(blob, `QR Codes`);
                }}>
                </ActionButton>
            </div>
        </div>
        <div className='marginTopS borderNeutral'>
            <DataTable
                data={loading ? [] : assets}
                columns={assetColumns}
                customStyles={dataTableStyles}
                pagination
                paginationPerPage={10}
                noDataComponent={<LoadingIndicator loading={loading} text={'No equipment registered.'}></LoadingIndicator>}
            />
        </div>
        <div className="qrRenderer displayFlex gapM">
            {assets.map((asset: Asset) => <div id={`qrCanvas-${asset.id}`}>
                <QRCodeCanvas value={`https://the-real-insight.com/public/assets/${asset.id}`} />
            </div>)
            }
        </div>
    </div>;
}