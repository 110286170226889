import { Role, RoleType } from "@the-real-insight/tri-model";
import { FontIcon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { useCustomerTheme } from "../Theme";

interface Properties {
    className?: string;
    role: Role;
    showIssuer?: boolean;
}

export default function RoleLabel(properties: Properties) {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const [authentication] = useAtom(authenticationAtom) as any;

    return <div className={properties.className || ''}>
        <div className="paddingXS displayFlex gapXS alignItemsCenter">
            {(() => {
                const iconStyle = {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    margin: '0 10px',
                };
                switch (properties.role.type) {
                    case RoleType.role: return <FontIcon iconName="UserPause" style={{ ...iconStyle, color: theme.primary }} />;
                    case RoleType.parameterizableRole: return <FontIcon iconName="UserOptional" style={{ ...iconStyle, color: theme.secondary }} />;
                    case RoleType.parameterizedRole: return <FontIcon iconName="UserSync" style={{ ...iconStyle, color: theme.ternary }} />;
                    case RoleType.partnerDelegation:
                    default: return <FontIcon iconName="FollowUser" style={{ ...iconStyle, color: theme.primaryDark }} />;
                }
            })()}
            <div className="name">
                {(() => {
                    switch (properties.role.type) {
                        case RoleType.role: return <div>
                            <div className="fontWeightBold">{properties.role.getRoleName()}</div>
                        </div>;
                        case RoleType.parameterizableRole: return <div>
                            <div className="fontWeightBold colorGrey">{properties.role.getRoleName()}</div>
                            <div className="textS colorGrey">{properties.role.parameterizableBy[0]}</div>
                        </div>;
                        case RoleType.parameterizedRole: return <div>
                            <div className="fontWeightBold">{properties.role.getRoleName()}</div>
                            <div className="textS" style={{ color: theme.secondary }}>{properties.role.getParameterName()}</div>
                        </div>;
                        case RoleType.partnerDelegation:
                        default: return <div>
                            <div>
                                {properties.role.issuer && properties.showIssuer
                                    ?
                                    <div className="textS" style={{ color: theme.primary }}>{(properties.role.issuer as any).name}</div>
                                    :
                                    <></>}
                                <div className="fontWeightBold">{properties.role.getRoleName()}</div>
                                <div className="textS" style={{ color: theme.secondary }}>{properties.role.getParameterName()}</div>
                            </div>
                        </div>;
                    }
                })()}
            </div>
        </div>
    </div>;
}