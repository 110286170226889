import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { userAPI } from '../UserAPI';
import Section from '../components/Section';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { useAdministrationAPI } from '../AdministrationAPI';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { EntityQuery, from, is, LegalPerson, Query, RelationshipDirection, Role, RoleAssignment, User } from '@the-real-insight/tri-model';
import { dataTableStyles } from '../styles';
import RoleList from '../components/RoleList';
import { useDrop } from 'react-dnd';
import RoleLabel from '../components/RoleLabel';
import { useModalContext } from '../components/ModalProvider';

interface Properties {
}

export default function UserManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { pushError } = useModalContext() as any;
    const { updateUser, createUser } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [users, setUsers] = useState([]) as any;
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [editedUser, setEditedUser] = useState({}) as any;
    const [roles, setRoles] = useState([]) as any;

    interface DroppableProperties {
        accept?: any;
        children?: any;
        theme?: any;
        onDrop: (payload: any) => void
    }

    const Droppable = (properties: DroppableProperties) => {
        const [{ isOver }, drop] = useDrop(() => ({
            accept: properties.accept || 'BOX',
            drop: properties.onDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }));

        return <div
            ref={drop}
            style={{ backgroundColor: isOver ? 'rgba(255, 0, 0, 0.05)' : 'transparent' }}
        >
            {properties && properties.children
                ?
                (properties.children)
                :
                <></>
            }
        </div>;
    }

    const toggleUserDialogOpen = () => {
        setUserDialogOpen(!userDialogOpen);
    }

    const columns = [{
        name: t('settingsPanel.userManagementPanel.invalidate'),
        width: '120px',
        cell: (user: any) => (user.id !== authentication.id
            ?
            <div className="displayFlex justifyContentCenter">
                <ActionButton iconProps={{ iconName: 'Delete' }} >
                </ActionButton>
            </div>
            :
            <></>
        ),
    }, {
        name: t('settingsPanel.userManagementPanel.user'),
        selector: (user: User) => user.lastName,
        width: '250px',
        cell: (user: any) => (
            <Persona {...{
                text: `${user.firstName} ${user.lastName}`,
                secondaryText: user.email,
                imageInitials: `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`,
            }} text={`${user.firstName} ${user.lastName}`} size={PersonaSize.size40} />
        ),
        sortable: true,
    }/*, {
        name: 'Status',
        width: '80px'
    }*/, {
        name: t('settingsPanel.userManagementPanel.roles'),
        width: '350px',
        cell: (user: any) => (
            <Droppable accept={['role']} onDrop={async (role: any) => {
                await userAPI.createRoleAssignment(authentication.customer, user._id, role._id);
                await loadUsers();
            }}>
                {user.roleAssignments && user.roleAssignments.length > 0
                    ?
                    <div className='width300'>
                        {
                            user.roleAssignments.map((roleAssignment: RoleAssignment) => <div className='displayFlex alignItemsCenter'>
                                <div className='flexGrow1'><RoleLabel role={roleAssignment.role} showIssuer></RoleLabel></div>
                                <div><ActionButton iconProps={{ iconName: 'Delete' }} onClick={async () => {
                                    try {
                                        await userAPI.deleteRoleAssignment(authentication.customer, roleAssignment);
                                        await loadUsers();
                                    } catch (error) {
                                        pushError(error);
                                    }
                                }}>
                                </ActionButton></div>
                            </div>)
                        }
                    </div>
                    :
                    <div className="displayFlex justifyContentCenter width300 height40 borderNeutral">
                    </div>}
            </Droppable>
        )
    },
    ];

    const loadUsers = async () => {
        try {
            const newUsers = await userAPI.query(from(User.type).where(is('customer').equal(authentication.customer)).link('roleAssignments', RelationshipDirection.out, RoleAssignment.type).link('role', RelationshipDirection.out, Role.type));
            const legalPersonMap: any = {};

            for (const user of newUsers) {
                for (const roleAssignment of user.roleAssignments) {
                    roleAssignment.role = new Role(roleAssignment.role);

                    if (!!roleAssignment.role.issuer) {
                        let legalPerson = legalPersonMap[roleAssignment.role.issuer];

                        if (!legalPerson) {
                            legalPerson = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(roleAssignment.role.issuer)));

                            legalPersonMap[legalPerson._id] = legalPerson;
                        }

                        roleAssignment.role.issuer = legalPerson;
                    }
                }
            }

            setUsers(newUsers);
        } catch (error) {
            pushError(error);
        }
    }

    useEffect(() => {
        const call = async () => {
            await loadUsers();
        };

        call();
    }, []);

    useEffect(() => {
        const call = async () => {
            console.log('Roles Hierarchy >>>', await userAPI.getRoles(authentication.customer));

            const newRoles = await userAPI.query(new Query(new EntityQuery(Role.type, [], undefined)));

            setRoles(newRoles);
        };

        call();
    }, []);

    return <><div className="displayFlex gapM">
        <Section title={t('settingsPanel.userManagementPanel.teamMembers')}>
            <div className="width600 displayFlex alignItemsCenter">
                <ActionButton iconProps={{ iconName: 'AddFriend' }}
                    onClick={toggleUserDialogOpen}>
                    {t('settingsPanel.userManagementPanel.inviteUser')}
                </ActionButton>
            </div>
            <div className='borderNeutral'>
                <DataTable
                    data={users}
                    columns={columns}
                    customStyles={dataTableStyles}
                    selectableRows
                    pagination
                />
            </div>
        </Section>
        <Section title={t('settingsPanel.userManagementPanel.roles')}>
            <RoleList className='borderNeutral width400' showParameterizedRoles showDelegatedRoles showIssuer></RoleList>
        </Section>
    </div>
        <Dialog
            hidden={!userDialogOpen}
            onDismiss={toggleUserDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.userManagementPanel.inviteUser'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <TextField label={t('settingsPanel.userManagementPanel.email')} value={editedUser.email} onChange={(e: any, email: any) => setEditedUser({ ...editedUser, email })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.emailNotValid');
                }}
                validateOnFocusOut />
            <TextField label={t('settingsPanel.userManagementPanel.firstName')} value={editedUser.firstName} onChange={(e: any, firstName: any) => setEditedUser({ ...editedUser, firstName })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.firstNameNotValid');
                }}
                validateOnFocusOut />
            <TextField label={t('settingsPanel.userManagementPanel.lastName')} value={editedUser.lastName} onChange={(e: any, lastName: any) => setEditedUser({ ...editedUser, lastName })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.lastNameNotValid');
                }}
                validateOnFocusOut />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('settingsPanel.userManagementPanel.invite')} onClick={async () => {
                    try {
                        await createUser(authentication.customer, editedUser);

                        setUsers(await userAPI.getUsers());
                        setEditedUser({});
                        toggleUserDialogOpen();
                    } catch (error) {
                        pushError(error);
                    }
                }} />
                <DefaultButton onClick={() => {
                    toggleUserDialogOpen();
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}