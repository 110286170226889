import { EntityQuery, Query, is, LegalPerson, LegalForm, PostalCode } from "@the-real-insight/tri-model";
import { userAPI } from "../UserAPI";
import { useEffect, useRef, useState } from "react";
import { ActionButton, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton, SearchBox, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { getCodeList } from 'country-list';

interface Properties {
    className?: string;
    address: any;
    addressChanged: (address: any) => void;
}

export default function AddressEntry(properties: Properties) {
    const { t } = useTranslation();
    const codeList = getCodeList();
    const linkRef = useRef(null);
    const [filter, setFilter] = useState('');

    return <div className={properties.className || ''}>
        <div className="displayFlex gapM">
            <TextField required label={t('addressEntry.street')} styles={{ fieldGroup: { width: 300 } }} value={properties.address.street} onChange={(_: any, street: any) => {
                properties.addressChanged({ ...properties.address, street });
            }} />
            <TextField required label={t('addressEntry.streetNumber')} styles={{ fieldGroup: { width: 100 } }} value={properties.address.streetNumber} onChange={(_: any, streetNumber: any) => {
                properties.addressChanged({ ...properties.address, streetNumber });
            }} />
        </div>
        <div className="displayFlex gapM">
            <TextField required label={t('addressEntry.postalCode')} styles={{ fieldGroup: { width: 100 } }} value={properties.address.postalCode} onChange={async (_: any, postalCode: any) => {
                properties.addressChanged({ ...properties.address, postalCode });

                if (postalCode && postalCode.length === 5 && !isNaN(postalCode)) {
                    console.log('Postal code', postalCode);
                    const result = await userAPI.query(new Query(new EntityQuery(PostalCode.type, [], undefined,
                        is('postalCode').equal(postalCode))));

                    console.log('Result >>>', result);

                    if (result && result.length === 1) {
                        properties.addressChanged({ ...properties.address, postalCode: result[0].postalCode, city: result[0].city });
                    }
                }
            }} />
            <TextField required label={t('addressEntry.city')} styles={{ fieldGroup: { width: 300 } }} value={properties.address.city} onChange={(_: any, city: any) => {
                properties.addressChanged({ ...properties.address, city });
            }} />
        </div>
        <div>
            <Dropdown
                label={t('addressEntry.country')}
                defaultSelectedKey={'DE'}
                selectedKey={properties.address.country}
                options={Object.keys(codeList).map((key: any) => { return { key: key.toUpperCase(), text: codeList[key] } })}
                onChange={(_: any, entry: any) => {
                    console.log(entry);
                    properties.addressChanged({ ...properties.address, country: entry.key });
                }}
            />
        </div>
    </div>;
}