import { makeStyles, shorthands } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { IconButton } from '@fluentui/react/lib/Button';
import { useAtom } from 'jotai'
import { mapQueryAtom } from "../State";
import { mapDataAtom } from "../State";
import { mapOptionsAtom } from "../State";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react';
import { useState } from 'react';
import MapAndImage from "../MapAndImage";

const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', '#EFEFEF'),
        ...shorthands.padding('10px'),
        backgroundColor: 'white',
    },
    titleBar: {
        display: 'flex',
        alignItems: 'center'
    },
    expandButton: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'end'
    },
    table: {
        fontSize: '10px',
        borderCollapse: 'collapse',
        width: '100%'
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'black'),
        textAlign: 'right',
    },
    cell: {
        textAlign: 'right',
    }
});

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

export const MapAndImageResource = ({ resource }: { resource: any }) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const [, setMapQuery] = useAtom(mapQueryAtom);
    const [, setMapData] = useAtom(mapDataAtom);
    const [, setMapOptions] = useAtom(mapOptionsAtom);
    const [selectedEntity, setSelectedEntity] = useState(false);
    const [expanded, setExpanded] = useState(false);

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Map" iconName="MapPin" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
            <div className={styles.expandButton}>
                <IconButton iconProps={{ iconName: 'MiniExpand' }} title="Expand" ariaLabel="Expand" onClick={() => { setMapData(resource.data); setMapQuery(resource.query); setMapOptions(resource.options); navigate("/map-and-image") }} />
            </div>
        </div>
        {expanded
            ?
            <div className="marginTopL">
                <MapAndImage data={resource.data} query={null} options={resource.options} setObject={(entity: any) => {
                    setMapData(resource.data);
                    setMapQuery(resource.query);
                    setMapOptions(resource.options);
                    setSelectedEntity(entity);
                    navigate("/map-and-image")
                }}></MapAndImage>
            </div>
            :
            <></>
        }
    </div>
}
