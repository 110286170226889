import Header from "./Header";
import { Pivot, PivotItem } from "@fluentui/react";
import UserManagementPanel from "./settings/UserManagementPanel";
import UserProfilePanel from "./settings/UserProfilePanel";
import SecurityPanel from "./settings/SecurityPanel";
import BillingPanel from "./settings/BillingPanel";
import CompanyPanel from "./settings/CompanyPanel";
import { useTranslation } from "react-i18next";
import APIPanel from "./settings/APIPanel";
import { useAtom } from "jotai";
import { authenticationAtom } from './State';
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import DataManagementPanel from "./settings/DataManagementPanel";
import ProductManagementPanel from "./settings/ProductManagementPanel";
import AlertManagementPanel from "./settings/AlertManagementPanel";
import OperationsDashboard from "./dashboards/OperationsDashoard";
import ProjectPanel from "./settings/ProjectPanel";
import SideBar, { ItemType, SideBarItem } from "./components/SideBar";
import ContentPanel from "./components/ContentPanel";
import GatewayPanel from "./administration/GatewayPanel";
import ProcessManagementPanel from "./settings/ProcessPanel";
import { useModalContext } from "./components/ModalProvider";

export default function SettingsPage() {
    const { t } = useTranslation();
    const { pushError } = useModalContext() as any;
    const [authentication] = useAtom(authenticationAtom) as any;
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedPanel, setSelectedPanel] = useState(searchParams.get("panel") || 'userProfile');
    const [sidebarShown, setSidebarShown] = useState(true);

    const sideBarItems: SideBarItem[] = [
        { type: ItemType.heading, key: "customerHeading", text: "Customer" },
        { type: ItemType.item, key: 'userProfile', text: t('settingsPanel.userProfile'), iconName: "UserOptional" },
        { type: ItemType.item, key: 'securityProfile', text: t('settingsPanel.securityProfile'), iconName: "PageLock" },
        { type: ItemType.item, key: 'companyProfile', text: t('settingsPanel.companyProfile'), iconName: "CompanyDirectory" },
        { type: ItemType.item, key: 'teamManagement', text: t('settingsPanel.teamManagement'), iconName: "People" },
        { type: ItemType.item, key: 'billing', text: t('settingsPanel.billing'), iconName: "Money" },
        { type: ItemType.heading, key: "configurationHeading", text: "Configuration" },
        { type: ItemType.item, key: 'projects', text: t('settingsPanel.projects'), iconName: "ProjectCollection" },
        { type: ItemType.item, key: 'productManagement', text: t('settingsPanel.productManagement'), iconName: "Product" },
        { type: ItemType.heading, key: "operationsHeading", text: "Operations" },
        { type: ItemType.item, key: 'telemetry', text: t('settingsPanel.telemetry'), iconName: "InternetSharing" },
        { type: ItemType.item, key: 'alerts', text: t('settingsPanel.alerts'), iconName: "AlertSettings" },
        { type: ItemType.item, key: 'operationalState', text: t('settingsPanel.operatingState'), iconName: "SyncStatus" },
        { type: ItemType.item, key: 'processManagement', text: t('settingsPanel.processManagement'), iconName: "CalendarSettings" },
        { type: ItemType.heading, key: "developmentHeading", text: "Development" },
        { type: ItemType.item, key: 'api', text: t('settingsPanel.api'), iconName: "AzureAPIManagement" },
        { type: ItemType.item, key: 'dataManagement', text: t('settingsPanel.dataManagement'), iconName: "Globe" },
    ];

    const setParams = (key: string) => {
        setSearchParams({ panel: key });
        setSelectedPanel(key);
    }

    return <div className="settingsPage positionRelative">
        <Header showSideBar={!sidebarShown} showSideBarChanged={(show: boolean) => {
            console.log('In Settings >>>', show);
            setSidebarShown(show);
        }} showHome title={t('settingsPanel.settings')}></Header>
        <div className="bodyPanel">
            <SideBar items={sideBarItems} setItem={setParams} shown={sidebarShown} shownChanged={setSidebarShown}></SideBar>
            <ContentPanel>
                {authentication && authentication.roles && authentication.roles.indexOf('administrator') >= 0
                    ?
                    <div>
                        {(() => {
                            switch (selectedPanel) {
                                case 'userProfile': return <UserProfilePanel></UserProfilePanel>;
                                case 'securityProfile': return <SecurityPanel></SecurityPanel>;
                                case 'companyProfile': return <CompanyPanel></CompanyPanel>;
                                case 'teamManagement': return <UserManagementPanel></UserManagementPanel>;
                                case 'billing': return <BillingPanel></BillingPanel>;
                                case 'api': return <APIPanel></APIPanel>;
                                case 'projects': return <ProjectPanel></ProjectPanel>;
                                case 'productManagement': return <ProductManagementPanel></ProductManagementPanel>;
                                case 'telemetry': return <GatewayPanel></GatewayPanel>;
                                case 'alerts': return <AlertManagementPanel></AlertManagementPanel>;
                                case 'operationalState': return <OperationsDashboard></OperationsDashboard>;
                                case 'dataManagement': return <DataManagementPanel></DataManagementPanel>;
                                case 'processManagement': return <ProcessManagementPanel></ProcessManagementPanel>;
                            }
                        })()}
                    </div>
                    :
                    <>
                        <Pivot aria-label="">
                            <PivotItem id="userProfile" headerText={t('settingsPanel.userProfile')} itemIcon="UserOptional">
                                <div className="paddingM">
                                    <UserProfilePanel></UserProfilePanel>
                                </div>
                            </PivotItem>
                            <PivotItem id="securityProfile" headerText={t('settingsPanel.securityProfile')}>
                                <div className="paddingM">
                                    <SecurityPanel></SecurityPanel>
                                </div>
                            </PivotItem>
                        </Pivot>
                    </>
                }
            </ContentPanel>
        </div>
    </div>;
}