import { useEffect } from 'react';
import './css/website.sass';
import WebsiteHeader from './WebsiteHeader';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import { useNavigate } from "react-router-dom";

export default function LegalPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="website">
      <div className='wave'></div>
      <div className='wave'></div>
      <div className='wave'></div>
      <WebsiteHeader showSidePanel={true} showMapItem={true} showTabs={false}></WebsiteHeader>
      <div className='body'>
        <div className="content">
          <div className='marginTopXL headerText'>Impressum</div>
          <div className="marginTopXL bodyText fontWeightBold">
            The Real Insight GmbH
          </div>
          <div className="bodyText">
            Beilsteinerweg 22
          </div>
          <div className="bodyText">
            D-56479 Seck
          </div>
          <div className="bodyText">
            +49 151 275 592 28
          </div>
          <div className="bodyText">
            <a href="mailto:christian.schlicht@the-real-insight.com">christian.schlicht@the-real-insight.com</a>
          </div>
          <div className="marginTopL bodyText fontWeightBold">
            Geschäftsführer:
          </div>
          <div className="bodyText">
            Dr. Christian Schlicht
          </div>
          <div className="marginTopL bodyText fontWeightBold">
            Registergericht:
          </div>
          <div className="bodyText">
            Amtsgericht Montabaur
          </div>
          <div className="bodyText">
            Registernummer:
          </div>
          <div className="bodyText">
            HRB 30052
          </div>
          {/* <div className="marginTopL bodyText">
              Umsatzsteueridentifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            </div>
            <div className="bodyText">
              DE354974949
            </div> */}
          <div className="marginTopL bodyText fontWeightBold">
            Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:
          </div>
          <div className="bodyText">
            Dr. Christian Schlicht
          </div>
          <div className='marginTopXL marginBottomXXL textAlignLeft bodyText'>
            Die The Real Insight GmbH prüft und aktualisiert die Informationen auf ihren Webseiten ständig. Trotz aller Sorgfalt können sich die Daten inzwischen verändert haben. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen kann daher nicht übernommen werden. Gleiches gilt auch für alle anderen Internetseiten, auf die mit Hyperlinks verwiesen wird. Die The Real Insight GmbH ist für den Inhalt der Internetseiten, die per Hyperlinks erreicht werden, nicht verantwortlich. Zudem behält sich die The Real Insight GmbH das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen. Inhalt und Aufbau der Internetseiten der The Real Insight GmbH sind urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial, bedarf der vorherigen schriftlichen Zustimmung der The Real Insight GmbH.
          </div>
        </div>
        <Footer></Footer>
        </div>
    </div>
  );
}
