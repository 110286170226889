import '../App.sass';
import { useEffect, useMemo, useState } from 'react';
import { ActionButton, DefaultButton, Dropdown, IconButton, Label, Pivot, PivotItem, PrimaryButton, TextField } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import { Asset, Customer, EntityQuery, Gateway, is, Query } from '@the-real-insight/tri-model';
import { useAdministrationAPI } from '../AdministrationAPI';
import { userAPI } from '../UserAPI';
import ReactApexChart from 'react-apexcharts';
import Metric from '../components/Metric';
import { useCustomerTheme } from '../Theme';
import DeviceProperties from '../components/DeviceProperties';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AddressPicker from '../components/AddressPicker';
import { useAtom } from 'jotai';
import { authenticationAtom } from '../State';
import { useModalContext } from '../components/ModalProvider';

const now = Date.now();

interface Properties {
}

interface Device {
    uuid: string;
}

const numberFormat2 = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const numberFormat0 = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

export default function GatewayPanel(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pushError } = useModalContext() as any;
    const { theme } = useCustomerTheme();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { getCustomers, createGateway, getDevicesFromGateway, configureDevicesOnGateway, getGatewayStatus } = useAdministrationAPI();
    const [tab, setTab] = useState('productData') as any;
    const [searchParams, setSearchParams] = useSearchParams();
    const [gateways, setGateways] = useState([]) as any;
    const [selectedGateway, setSelectedGateway] = useState() as any;
    const [devices, setDevices] = useState([]) as any;
    const [filteredDevices, setFilteredDevices] = useState([]) as any;
    const [selectedDevices, setSelectedDevices] = useState([]) as any;
    const [editDevice, setEditDevice] = useState([]) as any;
    const [customers, setCustomers] = useState() as any;
    const [gatewayStatus, setGatewayStatus] = useState() as any;
    const [timer, setTimer] = useState(Date.now()) as any;
    const [memorySeries, setMemorySeries] = useState([...Array(30)].map((_: any, index: number) => {
        return [index * 10000, null]
    })) as any;
    const [cpuSeries, setCPUSeries] = useState([...Array(30)].map((_: any, index: number) => {
        return [index * 10000, null]
    })) as any;
    const [asset, setAsset] = useState() as any;
    const [address, setAddress] = useState() as any;
    const [deviceIDFilter, setDeviceIDFilter] = useState('');

    console.log('Auth >>>', authentication);

    const options: any = {
        chart: {
            id: 'realtime',
            height: 350,
            type: 'area',
            // animations: {
            //     enabled: true,
            //     easing: 'linear',
            //     dynamicAnimation: {
            //         speed: 1000
            //     }
            // },
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: [theme.secondary],
        stroke: {
            // curve: 'smooth',
            width: 2
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 5,
            labels: {
                formatter: (value: any) => {
                    return new Date(now - value).toLocaleTimeString();
                }
            }
        },
        yaxis: {
            labels: {
                formatter: (value: any) => {
                    return value ? `${value.toFixed(0)} MB` : '';
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
        legend: {
            show: false
        },
    };

    const cpuChartOptions: any = {
        chart: {
            id: 'cpu',
            height: 350,
            type: 'area',
            // animations: {
            //     enabled: true,
            //     easing: 'linear',
            //     dynamicAnimation: {
            //         speed: 1000
            //     }
            // },
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            // curve: 'smooth',
            width: 2
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 5,
            labels: {
                formatter: (value: any) => {
                    return new Date(now - value).toLocaleTimeString();
                }
            }
        },
        yaxis: {
            labels: {
                formatter: (value: any) => {
                    return value ? `${value.toFixed(0)} %` : '';
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
        legend: {
            show: false
        },
    };

    const columns = [{
        name: 'Gateway',
        selector: (gateway: any) => gateway._id,
        width: '250px',
        cell: (gateway: any) => <span>{gateway._id}</span>,
        sortable: true,
    }, {
        name: 'URL',
        selector: (gateway: any) => gateway.url,
        width: '400px',
        cell: (gateway: any) => <span>{gateway.url}</span>,
        sortable: true,
    }, {
        name: 'Customer',
        selector: (gateway: any) => gateway._tenant,
        width: '250px',
        cell: (gateway: any) => <span>{gateway._tenant}</span>,
        sortable: true,
    }, {
        name: 'Status',
        selector: (gateway: Gateway) => '',
        width: '150px',
        cell: (gateway: Gateway) => <span className='colorGreen'>{'RUNNING'}</span>,
        sortable: true,
    }];

    const deviceColumns = [{
        name: 'Device',
        selector: (device: any) => `${device.name}`,
        width: '320px',
        cell: (device: any) => <div>
            <div className='textS'>{device.name}</div>
            <div className='displayFlex alignItemsCenter textS'><span>{device.id}</span><IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Asset&selectedEntity=${device._id}`)} /></div>
        </div>,
        sortable: true,
    }, {
        name: 'Address',
        selector: (device: any) => device.street ? `${device.street} ${device.streetNumber}, ${device.postalCode} ${device.city}, ${device.country}` : ``,
        width: '300px',
        cell: (device: any) => device.street ? <div><div className='textS'>{device.street} {device.streetNumber}</div><div className='textS'>{device.postalCode} {device.city}, {device.country}</div></div> : <></>,
        sortable: true,
    }, {
        name: 'Last State Change',
        selector: (device: any) => device.lastStateChange,
        width: '150px',
        cell: (device: any) => <div>
            <div className='textS'>{device.lastStateChange ? new Date(device.lastStateChange).toLocaleDateString() : '-'}</div>
            <div className='textS'>{device.lastStateChange ? new Date(device.lastStateChange).toLocaleTimeString() : ''}</div>
        </div>,
        sortable: true,
    }, {
        name: 'Last Configuration',
        selector: (device: any) => device.lastConfigurationChange,
        width: '150px',
        cell: (device: any) => <div>
            <div className='textS'>{device.lastConfigurationChange ? new Date(device.lastConfigurationChange).toLocaleDateString() : '-'}</div>
            <div className='textS'>{device.lastConfigurationChange ? new Date(device.lastConfigurationChange).toLocaleTimeString() : ''}</div>
        </div>,
        sortable: true,
    }];

    const selectGateway = async (gateway: any) => {
        setSelectedGateway(gateway);

        if (!gateway) {
            searchParams.delete('gateway');
            setSearchParams(searchParams);

            return;
        }

        searchParams.set('gateway', gateway._id);
        setSearchParams(searchParams);

        const newDevices = await getDevicesFromGateway(gateway);

        setDevices(newDevices);
        setFilteredDevices(newDevices);
    };

    const checkHomogenousDeviceType = (devices: any) => {
        let manufacturer = null;
        let type = null;

        for (const device of devices) {
            if (!manufacturer) {
                manufacturer = device.manufacturer;
                type = device.type;

                continue;
            } else if (manufacturer !== device.manufacturer || type !== device.type) {
                return false;
            }
        }

        return true;
    }

    const selectDevices = async (devices: any) => {
        setSelectedDevices(devices);

        if (!devices || devices.length === 0) {
            setEditDevice(null);

            return;
        }

        if (devices.length > 1) {
            if (checkHomogenousDeviceType(devices)) {
                setEditDevice({ manufacturer: devices[0].manufacturer, type: devices[0].type });
            } else {
                setEditDevice(null);
            }

            return;
        }

        setEditDevice(devices[0]);

        // TODO No longer needed

        const assets = await userAPI.query(new Query(new EntityQuery(Asset.type, [], undefined, is('_id').equal(devices[0]._id))));

        if (assets && assets.length === 1) {
            setAsset(assets[0]);
        }
    };

    const setParams = (value: any) => {
        searchParams.set('tab', value.key.substring(1));
        setSearchParams(searchParams);
        setTab(value.key.substring(1));
    }

    const updateConfigurations = async (configuration: any) => {
        const updatedDevices = selectedDevices.map((device: Device) => {
            return { ...device, ...configuration };
        });

        const response = await configureDevicesOnGateway(selectedGateway, updatedDevices)
    }

    const filterDevices = (address: any) => {
        setFilteredDevices(devices.filter((device: any) => !address ||
            ((!address.street || address.street === device.street)
                && (!address.city || address.city === device.city))))
    }

    useEffect(() => {
        if (!selectedGateway) {
            return;
        }

        const call = async () => {
            const newGatewayStatus = await getGatewayStatus(selectedGateway);
            const newMemorySeries = [...memorySeries];

            newMemorySeries.pop();
            newMemorySeries.unshift([now - Date.now(), newGatewayStatus.memoryUsage.heapUsed / 1000000]);

            const newCPUSeries = [...cpuSeries];

            newCPUSeries.pop();
            newCPUSeries.unshift([now - Date.now(), newGatewayStatus.cpuUsage]);

            setMemorySeries(newMemorySeries)
            setCPUSeries(newCPUSeries)
            setGatewayStatus(newGatewayStatus)
        };

        call();

        const interval = setInterval(() => setTimer(Date.now()), 10000);

        return () => {
            clearInterval(interval);
        }
    }, [selectedGateway, timer]);

    useEffect(() => {
        const call = async () => {
            const gateways = await userAPI.query(new Query(new EntityQuery(Gateway.type)));

            setGateways(gateways);

            if (searchParams.get("gateway")) {
                const newSelectedGateway = gateways.find((site: any) => site._id === searchParams.get("gateway"));
                const newDevices = await getDevicesFromGateway(newSelectedGateway);

                setSelectedGateway(newSelectedGateway);
                setDevices(newDevices);
                setFilteredDevices(newDevices);
            }
        };

        call();
    }, []);

    useEffect(() => {
        console.log('Address >>>', address);
    }, [address]);

    return <><div>
        {selectedGateway
            ?
            <>
                {selectedGateway._id ?
                    <>
                        <div className='displayFlex alignItemsCenter'>
                            <div className='flexGrow1 displayFlex alignItemsCenter'>
                                <span className='textL'>{selectedGateway.name}</span>
                            </div>
                            <div className='displayFlex alignItemsCenter justifyContentEnd gapM'>
                                <DefaultButton
                                    onClick={() => {
                                        setSelectedGateway(null);
                                        searchParams.delete('gateway');
                                        setSearchParams(searchParams);
                                    }}>
                                    Close
                                </DefaultButton>
                            </div>
                        </div>
                        <div className='marginTopM displayFlex'>
                            <Pivot aria-label="" onLinkClick={setParams} selectedKey={tab}>
                                <PivotItem id="productData" headerText={'Status'} itemIcon="UserOptional">
                                    <div className='width1000'>
                                        <div className="paddingM">
                                            {gatewayStatus
                                                ?
                                                <div className='displayFlex flexDirectionColumn gapL'>
                                                    <div className='displayFlex gapL'>
                                                        <Metric title={'Active Devices'} value={numberFormat0.format(gatewayStatus.activeDevices)} color={theme.secondaryLight}></Metric>
                                                        <Metric title={'Total State Updates'} value={gatewayStatus.totalStateUpdateCount} color={theme.tertiary}></Metric>
                                                        <Metric title={'Total Config Updates'} value={gatewayStatus.totalConfigurationUpdateCount} color={theme.primary}></Metric>
                                                        <Metric title={'Message Throughput'} value={'53'} unit={'Msg./s'} color={theme.secondary}></Metric>
                                                    </div>
                                                    <div className='marginTopXL displayFlex gapL'>
                                                        <Metric title={'Memory Usage'} value={numberFormat2.format(gatewayStatus.memoryUsage.heapUsed / 1000000)} unit={'MB'} color={theme.secondary}></Metric>
                                                        <ReactApexChart options={{ ...options, colors: [theme.secondary] }} series={[{ data: memorySeries }]} type="area" height={150} width={500} />
                                                    </div>
                                                    <div className='displayFlex gapL'>
                                                        <Metric title={'CPU Usage'} value={gatewayStatus.cpuUsage.toFixed(0)} unit={'%'} color={theme.tertiary}></Metric>
                                                        <ReactApexChart options={{ ...cpuChartOptions, colors: [theme.tertiary] }} series={[{ data: cpuSeries }]} type="area" height={150} width={500} />
                                                    </div>
                                                </div>
                                                :
                                                <></>}
                                        </div>
                                    </div>
                                </PivotItem>
                                <PivotItem id="devices" headerText={'Devices'} itemIcon="PageLock">
                                    <div className='displayFlex gapM'>
                                        <div className='width950'>
                                            <div className="marginTopM displayFlex alignItemsStart gapM">
                                                <div className="displayFlex alignItemsCenter gapS">
                                                    <Label>ID Filter</Label>
                                                    <TextField
                                                        placeholder="Enter device ID"
                                                        value={deviceIDFilter}
                                                        onChange={() => { }}
                                                        styles={{ fieldGroup: { width: 200 } }}
                                                    />
                                                </div>
                                                <AddressPicker label={'Address'} setAddress={(address: any) => {
                                                    setAddress(address);
                                                    filterDevices(address);
                                                }} />
                                            </div>
                                            <div className='marginTopS borderNeutral'>
                                                <DataTable
                                                    data={filteredDevices}
                                                    columns={deviceColumns}
                                                    customStyles={dataTableStyles}
                                                    selectableRows
                                                    onSelectedRowsChange={({ selectedRows }) => {
                                                        selectDevices(selectedRows);
                                                    }}
                                                    pagination
                                                    paginationPerPage={5}
                                                />
                                            </div>
                                        </div>
                                        <div className='width400'>
                                            {selectedDevices.length > 1
                                                ?
                                                <>
                                                    {editDevice
                                                        ?
                                                        <DeviceProperties hideIDField configurationOnly={true} device={editDevice} applyConfiguration={updateConfigurations}></DeviceProperties>
                                                        :
                                                        <div className='displayFlex justifyContentCenter marginL'>Heterogenous device types selected.</div>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {selectedDevices.length === 1
                                                        ?
                                                        <>
                                                            {asset
                                                                ?
                                                                <div className='marginBottomL'>
                                                                    <div className='textHeader'>Device Asset</div>
                                                                    <div className='marginTopM textM'>{asset.street} {asset.streetNumber}, {asset.postalCode} {asset.city}, {asset.country}</div>
                                                                </div>
                                                                :
                                                                <></>}
                                                            <DeviceProperties device={editDevice} applyConfiguration={updateConfigurations}></DeviceProperties>
                                                        </>
                                                        :
                                                        <div className='displayFlex justifyContentCenter marginL'>No device selected.</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </PivotItem>
                            </Pivot>
                        </div>
                    </>
                    :
                    <>
                        <div className='displayFlex alignItemsCenter'>
                            <div className='flexGrow1 displayFlex alignItemsCenter'>
                                <span className='textL'>New Gateway</span>
                            </div>
                            <div className='displayFlex alignItemsCenter justifyContentEnd gapM'>
                                <DefaultButton
                                    onClick={() => {
                                        setSelectedGateway(null);
                                    }}>
                                    Close
                                </DefaultButton>
                                <PrimaryButton
                                    onClick={async () => {
                                        await createGateway(selectedGateway);

                                        setSelectedGateway(null);
                                    }}>
                                    Create
                                </PrimaryButton>
                            </div>
                        </div>
                        <div className='marginTopM displayFlex alignItemsCenter gapM'>
                            <TextField required label={'AWS URL'} styles={{ fieldGroup: { width: 400 } }} value={selectedGateway.url} onChange={(_: any, url: any) => {
                                setSelectedGateway({ ...selectedGateway, url });
                            }} />
                            <Dropdown
                                placeholder="Select options"
                                label="Customer"
                                options={customers.map((customer: Customer) => {
                                    return { key: customer._id, text: customer.name }
                                })}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            />
                        </div>
                    </>
                }
            </>
            :
            <div>
                {authentication.customer === '0'
                    ?
                    <ActionButton iconProps={{ iconName: 'AppIconDefaultAdd' }}
                        onClick={() => { setSelectedGateway({ name: 'Schneidige Meppen' }) }}>
                        Create gateway
                    </ActionButton>
                    :
                    <></>
                }
                <div className='marginTopL borderNeutral'>
                    <DataTable
                        data={gateways}
                        columns={columns}
                        customStyles={dataTableStyles}
                        selectableRows
                        selectableRowsSingle
                        onSelectedRowsChange={({ selectedRows }) => {
                            if (selectedRows && selectedRows.length > 0) {
                                selectGateway(selectedRows[0]);
                            }
                        }}
                        pagination
                        paginationPerPage={20}
                    />
                </div>
            </div>
        }
    </div >
    </>;
}