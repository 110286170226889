import '../App.sass';
import { useAtom } from "jotai";
import { useTranslation } from 'react-i18next';
import { authenticationAtom } from '../State';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useState } from 'react';
import { Entity, LegalPerson, Product, Asset, getEntityType, Relationship, Cardinality, Query, EntityQuery } from '@the-real-insight/tri-model';
import { TextField } from '@fluentui/react/lib/TextField';
import Section from '../components/Section';
import {
    DatePicker,
    defaultDatePickerStrings,
} from '@fluentui/react';
import { userAPI } from '../UserAPI';
import { ActionButton, PrimaryButton } from '@fluentui/react';
import { Label } from '@fluentui/react/lib/Label';
import { configuration } from '../Configuration';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import { useAdministrationAPI } from '../AdministrationAPI';
import { ApplicationError, UnauthorizedError } from '../general/Errors';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useModalContext } from '../components/ModalProvider';

const publicEntityTypes = [LegalPerson, Product, Asset];

interface Properties {
}

export default function DataManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { pushError } = useModalContext() as any;
    const [authentication] = useAtom(authenticationAtom) as any;
    const { updateEntity } = useAdministrationAPI();
    const [entityType, setEntityType] = useState() as any;
    const [entities, setEntities] = useState([]) as any;
    const [selectedEntity, setSelectedEntity] = useState() as any;
    const [entity, setEntity] = useState() as any;
    const [filter, setFilter] = useState('') as any;
    const [traversalPath, setTraversalPath] = useState([]) as any;
    const [loading, setLoading] = useState(false) as any;
    const [modified, setModified] = useState(false) as any;
    const locale = 'de';

    const entityTypeOptions = publicEntityTypes.map((type: any) => {
        return { key: type.type.name, text: type.type.getLabel(locale) }
    });

    const getEntityListColumns = () => {
        if (!entityType) {
            return [];
        }

        return getEntityType(entityType).fields.map((field: any) => {
            return {
                name: field.getLabel(locale),
                selector: (entity: any) => field.type === Date ? new Date(entity[field.name]).getTime() : entity[field.name],
                cell: (entity: any) => {
                    if (field.type.name === 'Image') {
                        return <Image {...{
                            imageFit: ImageFit.centerCover,
                            width: 100,
                            height: 66
                        }}
                            src={`${configuration.serverUrl}/documents/${entity[field.name]}`}
                            alt={field.getLabel(locale)}
                        />;
                    } else if (field.type === JSON) {
                        return <span></span>;
                    } else if (field.type === Date) {
                        return <div>{entity[field.name] ? new Date(entity[field.name]).toLocaleDateString() : '-'}</div>;
                    } else {
                        return <div>{entity[field.name] ? entity[field.name] : '-'}</div>;
                    }
                },
                sortable: field.type.name !== 'Image' && field.type !== JSON
            }
        });
    };

    // const entitySelection = new Selection({
    //     onSelectionChanged: async () => {
    //         await selectEntity(entitySelection.getSelection()[0] as Entity);
    //         setTraversalPath([{
    //             entity: entitySelection.getSelection()[0],
    //         }]);
    //     }
    // });

    const selectEntityType = async (entityType: string) => {
        if (!entityType) {
            return;
        }

        setLoading(true);
        setEntityType(entityType)

        try {
            const result: any = await userAPI.query(new Query(new EntityQuery(getEntityType(entityType).type)));

            console.log('Query >>>', result);

            setEntities(result);
            setEntity(null);
            setSelectedEntity(null);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const selectEntity = async (entity: Entity) => {
        console.log('Entity for selection', entity);

        if (!entity) {
            return;
        }

        const result = await userAPI.query(Query.nextNeighborQuery(entity as Entity));

        console.log('Selected Entity', result[0]);

        setEntityType(result[0].getTypeName());
        setSelectedEntity(result[0]);

        const entityClass = result[0].getTypeClass();

        setEntity(new entityClass(result[0]));
    }

    const filterChanged = async (value: string) => {
    }

    const onTraversalPathClicked = (ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem) => {
        const entry = traversalPath.find((entry: any) => entry.entity._id === item.key);

        if (traversalPath.length > 1) {
            setTraversalPath(traversalPath.slice(0, traversalPath.indexOf(entry) + 1));
        }

        selectEntity(entry.entity);
    }

    return <div>
        <div>
            <Dropdown
                label={t('settingsPanel.dataManagementPanel.entityType')}
                placeholder={`${t('global.pleaseSelect')} ...`}
                options={entityTypeOptions}
                selectedKey={entityType}
                onChange={(e: any, item: any) => selectEntityType(item.key)}
                styles={{
                    dropdown: { width: 300 },
                }}
            />
        </div>
        {entityType
            ?
            <>
                <div className='marginTopM'>
                    <Section title={getEntityType(entityType).type.getLabel(locale)}>
                        <div className='width200'>
                            <TextField label={t('settingsPanel.dataManagementPanel.filter')} value={filter} onChange={(e: any, value: any) => filterChanged(value)} />
                        </div>
                        {loading
                            ?
                            <div className="flex1 displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter">
                                <Spinner label={`${t('global.loading')}...`} size={SpinnerSize.large} ariaLive="assertive" labelPosition="top" />
                            </div>
                            :
                            <div className='marginTopM borderNeutral'>
                                <DataTable
                                    data={entities}
                                    columns={getEntityListColumns()}
                                    customStyles={dataTableStyles}
                                    selectableRows
                                    selectableRowsSingle
                                    onSelectedRowsChange={({ selectedRows }) => {
                                        console.log(selectedRows);

                                        selectEntity(selectedRows[0]);
                                    }}
                                    pagination
                                    paginationPerPage={5}
                                />
                            </div>}
                    </Section>
                </div>
                <div className='paddingS'>
                    <Breadcrumb
                        items={traversalPath.map((entry: any, index: number) => {
                            return {
                                text: `${entry.relationship ? entry.relationship.getLabel(locale) : 'Auswahl'}: ${entry.entity.constructor.type.getLabel(locale)}`,
                                key: entry.entity._id,
                                onClick: onTraversalPathClicked,
                                isCurrentItem: index === traversalPath.length - 1
                            };
                        })}
                        maxDisplayedItems={10}
                        ariaLabel="Traversal path"
                        overflowAriaLabel="More links"
                    />
                </div>
                <div className='displayFlex gapM'>
                    <div className='width500'>
                        <Section title={getEntityType(entityType).type.getLabel(locale)}>
                            <div className="displayFlex flexDirectionColumn alignItemsEnd">
                                <PrimaryButton text={t('global.save')} onClick={() => {
                                    try {
                                        console.log('Entity for update', entity);

                                        updateEntity(entity);
                                        setModified(false);
                                    } catch (error: any) {
                                        if (error instanceof ApplicationError) {
                                            pushError(error);
                                        } else if (error instanceof UnauthorizedError) {
                                            pushError(error);
                                        } else {
                                            pushError(error);
                                        }
                                    }
                                }}
                                    disabled={!modified} />
                            </div>
                            {entity
                                ?
                                <div>
                                    {getEntityType(entityType).fields.map((field: any) => {
                                        if (field.type === Date) {
                                            return <div className='marginBottomS'><DatePicker
                                                label={`${field.getLabel(locale)}`}
                                                placeholder="Select a date..."
                                                ariaLabel="Select a date"
                                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                                strings={defaultDatePickerStrings}
                                                value={new Date(entity[field.name])}
                                            /></div>
                                        } else if (field.type === String) {
                                            return <div className='marginBottomS'><TextField label={`${field.getLabel(locale)}`}
                                                value={entity[field.name]}
                                                onChange={(event: any, value: any) => {
                                                    const newEntity = { ...entity };

                                                    newEntity[field.name] = value;

                                                    setEntity(new (entity.getTypeClass())(newEntity));
                                                    setModified(true);
                                                }}>
                                            </TextField></div>
                                        } else if (field.type === JSON) {
                                            return <div className='marginBottomS'><TextField label={`${field.getLabel(locale)}`} multiline disabled
                                                value={JSON.stringify(entity[field.name])}></TextField></div>
                                        } else if (field.type.name === 'Image') {
                                            return <div>
                                                <div>
                                                    <Label>{field.getLabel(locale)}</Label>
                                                </div>
                                                <div>
                                                    <Image {...{
                                                        imageFit: ImageFit.centerCover,
                                                        width: 150,
                                                        height: 100
                                                    }}
                                                        src={`${configuration.serverUrl}/documents/${entity[field.name]}`}
                                                        alt='Example of the image fit value "center" on an image smaller than the frame.'
                                                    />
                                                </div>
                                            </div>
                                        } else if (field.type.name === 'Document') {
                                            return <div>
                                                <div>
                                                    <Label>{field.getLabel(locale)}</Label>
                                                </div>
                                                <div>
                                                    <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }}>
                                                        {t('global.select')}
                                                    </ActionButton>
                                                </div>
                                            </div>
                                        } else {
                                            return <Label>{field.getLabel(locale)}</Label>;
                                        }
                                    })}
                                </div>
                                :
                                <div>{`Keine Auswahl.`}</div>}
                        </Section>
                    </div>
                    <div className='flexGrow1'>
                        {getEntityType(entityType).relationships.map((relationship: Relationship, index: number) => {
                            return <div className={index > 0 ? 'marginTopM' : ''}>
                                <Section title={relationship.getLabel(locale)}>
                                    {selectedEntity && (((relationship.cardinality === Cardinality.OneToOne || relationship.cardinality === Cardinality.ManyToOne) && selectedEntity[relationship.name]) || ((relationship.cardinality === Cardinality.OneToMany || relationship.cardinality === Cardinality.ManyToMany) && selectedEntity[relationship.name].length > 0))
                                        ?
                                        <div>
                                            <div className='displayFlex justifyContentEnd'>
                                                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }}
                                                    onClick={() => {
                                                        setTraversalPath([...traversalPath, {
                                                            relationship,
                                                            entity: selectedEntity[relationship.name],
                                                        }]);
                                                        selectEntity(selectedEntity[relationship.name]);
                                                    }}>
                                                    {t('global.select')}
                                                </ActionButton>
                                            </div>
                                            <div className='labelValueList'>
                                                {relationship.type.fields.map((field: any) => {
                                                    if (field.type === Date) {
                                                        return <div className='labelValue'>
                                                            <div className='label'>{field.getLabel(locale)}</div>
                                                            <div className='value'>{selectedEntity[relationship.name][field.name] ? new Date(selectedEntity[relationship.name][field.name]).toLocaleDateString() : '-'}</div>
                                                        </div>;
                                                    } else if (field.type === String) {
                                                        return <div className='labelValue'>
                                                            <div className='label'>{field.getLabel(locale)}</div>
                                                            <div className='value'>{selectedEntity[relationship.name][field.name] ? selectedEntity[relationship.name][field.name] : '-'}</div>
                                                        </div>;
                                                    } else if (field.type === JSON) {
                                                        return <div className='labelValue'>
                                                            <div className='label'>{field.getLabel(locale)}</div>
                                                            <div className='value'></div>
                                                        </div>;
                                                    } else if (field.type.name === 'Image') {
                                                        return <div className='labelValue'>
                                                            <div className='label'>{field.getLabel(locale)}</div>
                                                            <div className='value'></div>
                                                        </div>;
                                                    } else if (field.type.name === 'Document') {
                                                        return <div className='labelValue'>
                                                            <div className='label'>{field.getLabel(locale)}</div>
                                                            <div className='value'><ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }}>
                                                                {t('global.select')}
                                                            </ActionButton></div>
                                                        </div>;
                                                    } else {
                                                        return <div className='labelValue'>
                                                            <div className='label'>{field.getLabel(locale)}</div>
                                                            <div className='value'>{field.type.name}</div>
                                                        </div>;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        :
                                        <span>{t(`settingsPanel.dataManagementPanel.noRelationshipSet`)}</span>
                                    }
                                </Section>
                            </div>
                        })}
                    </div>
                </div>
            </>
            :
            <></>
        }
    </div>;
}