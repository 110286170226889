class Properties {
  way: any;
}

export default function WayProperties(properties: Properties) {
  return (
    <div>
    </div>
  );
}
