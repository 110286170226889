import { ActionButton, ComboBox, IconButton, Label, Pivot, PivotItem, Position, SpinButton, Spinner, Text, TooltipHost } from "@fluentui/react";
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { FloorplanEditor } from "../floorplanEditor/FloorplanEditor";
import DataTable from "react-data-table-component";
import { useAdministrationAPI } from "../AdministrationAPI";
import { Address, Asset, Building, EntityQuery, Floor, FloorCategory, getConvexPolygonArea, is, LinkedEntityQuery, Query, RelationshipDirection, Space, SpaceCategory } from "@the-real-insight/tri-model";
import { userAPI } from "../UserAPI";
import { useDrag } from 'react-dnd'
import { FloorplanGeopositionEditor } from '../components/floorplan-geoposition-editor/FloorplanGeopositionEditor'
import { ColorSelector } from "../components/ColorSelector";
import Section from "../components/Section";
import { IconSelector } from "../components/IconSelector";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../components/ModalProvider";

class Properties {
  floor: any;
  processError?: (error: any) => void;
}

interface SpaceEditorProperties {
  floor?: any;
  floorChanged: () => void;
}

function SpaceEditor(properties: SpaceEditorProperties) {
  const [spaces, setSpaces] = useState([]) as any;
  const [creating, setCreating] = useState(false) as any;
  const [selectedSpaceIndex, setSelectedSpaceIndex] = useState(-1);
  const { createSpace, updateSpace, deleteSpace } = useAdministrationAPI();

  const colors = [
    'rgba(255, 0, 0, 0.1)',
    'rgba(0, 255, 0, 0.1)',
    'rgba(0, 0, 255, 0.1)',
  ];
  const scale = 18;

  const loadSpaces = async () => {
    const result = await userAPI.query(new Query(new EntityQuery(Floor.type, [], undefined, is('_id').equal(properties.floor._id)),
      [new LinkedEntityQuery('floor', RelationshipDirection.in, Space.type)]));

    if (!result || result.length === 0 || result.length > 1) {
      setSpaces([]);
    } else {
      console.log('Floor with spaces >>>', result[0]);

      // const dxf = (await userAPI.sampleDXF()).map((space: any, index: number) => ({ ...space, color: colors[index % 3], shape: space.shape.map((vertex: any) => ({x: vertex.x * scale, y: vertex.y * scale})) }));

      // console.log('DXF >>>', dxf);

      setSpaces([...result[0].spaces/*, ...dxf*/]);
    }
  }

  useEffect(() => {
    loadSpaces();
  }, [properties.floor]);

  const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 170 } };

  const spacesColumns = [{
    name: '',
    width: '80px',
    cell: (space: any) => (
      <div className='height20 displayFlex alignItemsCenter gapXS'>
        <ActionButton iconProps={{ iconName: 'Delete' }} onClick={async () => {
          setSpaces(spaces.filter((otherSpace: any) => otherSpace._id !== space._id));

          await deleteSpace(space);

          properties.floorChanged();
        }}></ActionButton>
        <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => {
          setSelectedSpaceIndex(spaces.indexOf(space));
        }}></ActionButton>
      </div>
    ),
    sortable: false,
  }, {
    name: 'Name',
    width: '180px',
    cell: (space: any) => (
      <TextField className="underlined" underlined styles={narrowTextFieldStyles} value={space.name} onChange={async (_: any, name?: string) => {
        const newSpace = { ...space, name };
        const newSpaces = [...spaces]

        newSpaces[spaces.indexOf(space)] = newSpace;

        setSpaces(newSpaces);
      }} onBlur={async () => {
        await updateSpace(space);
      }} />
    )
  }, {
    name: 'Long Name',
    width: '180px',
    cell: (space: any) => (
      <TextField className="underlined" underlined styles={narrowTextFieldStyles} value={space.longName} onChange={async (_: any, longName?: string) => {
        const newSpace = { ...space, longName };
        const newSpaces = [...spaces]

        newSpaces[spaces.indexOf(space)] = newSpace;

        setSpaces(newSpaces);
      }} onBlur={async () => {
        await updateSpace(space);
      }} />
    )
  }, {
    name: 'Kategorie',
    width: '180px',
    cell: (space: any) => <ComboBox placeholder={'Bitte auswählen ...'} options={Object.keys(SpaceCategory).filter((item) => isNaN(Number(item))).map((key: string) => { return { key, text: key } })}
      selectedKey={space.category} onChange={async (e: any, option: any) => {
        const newSpace = { ...space, category: option.key };
        const newSpaces = [...spaces]

        await updateSpace(newSpace);

        newSpaces[spaces.indexOf(space)] = newSpace;

        setSpaces(newSpaces);
      }} />
  }, {
    name: 'Farbe',
    minWidth: '80px',
    maxWidth: '80px',
    cell: (space: any) => <div className="flexGrow1 displayFlex alignItemsCenter justifyContentCenter">
      <ColorSelector color={space.color} colorChanged={async (color: string) => {
        console.log('Changed color >>>', color);

        const newSpace = { ...space, color };
        const newSpaces = [...spaces]

        await updateSpace(newSpace);

        newSpaces[spaces.indexOf(space)] = newSpace;

        setSpaces(newSpaces);
      }}></ColorSelector>
    </div>
  }, {
    name: 'Fläche/㎡',
    minWidth: '100px',
    maxWidth: '100px',
    right: true,
    cell: (space: any) => (
      <span>{space.shape ? (getConvexPolygonArea(space.shape) / 1000000).toFixed(2) : '-'}</span>
    )
  }];

  return <div className="displayFlex gapM">
    <div>
      <FloorplanEditor floor={properties.floor} floorChanged={properties.floorChanged} spaces={spaces} assets={[]}
        selectedSpaceIndex={selectedSpaceIndex}></FloorplanEditor>
    </div>
    <Section title="Räume">
      <div className="width800">
        <div className="marginTopM height20 displayFlex alignItemsCenter">
          {creating
            ?
            <Spinner label="Anlegen ..." ariaLive="assertive" labelPosition="right" />
            :
            <ActionButton iconProps={{ iconName: 'Add' }}
              onClick={async () => {
                try {
                  setCreating(true);

                  await createSpace(properties.floor, new Space({ name: `Raum ${properties.floor.spaces.length + 1}`, floor: properties.floor }));

                  await loadSpaces();

                  setCreating(false);
                  setSelectedSpaceIndex(properties.floor.spaces.length);

                  properties.floorChanged();
                } catch (error) {
                  // TODO Process error
                  console.error(error);
                }
              }}>
              Raum anlegen
            </ActionButton>
          }
        </div>
        <DataTable
          data={spaces}
          columns={spacesColumns}
          pagination
          paginationPerPage={10}
        />
      </div>
    </Section>
  </div>;
}

function AssetDnD(properties: any) {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'AssetDnD',
    item: properties.asset,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return isDragging ?
    <div ref={dragPreview}
      className="paddingXS height20 width100"
      style={{
        opacity: 0,
        cursor: 'move',
        position: 'relative',
        border: '1px solid red'
      }}
    >
      <span style={{ opacity: 0, position: 'absolute', top: '-10px', left: '0px' }}>{properties.asset.name || properties.asset.id}</span>
    </div>
    :
    <span ref={drag} className="fontWeightBold">{properties.asset.name || properties.asset.id}</span>;
}

interface EquipmentEditorProperties {
  floor?: any;
  floorChanged?: (floor: any) => void;
}

function EquipmentEditor(properties: EquipmentEditorProperties) {
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState([]) as any;
  const [spaces, setSpaces] = useState([]) as any;
  const [assets, setAssets] = useState([]) as any;
  const [searchFilter, setSearchFilter] = useState('');
  const { updateAsset, deleteAsset } = useAdministrationAPI();
  const { showDeleteDialog } = useModalContext() as any;
  const assetsColumns = [{
    name: '',
    width: '50px',
    cell: (asset: any) => (
      <ActionButton iconProps={{ iconName: 'Delete' }}
        onClick={() => showDeleteDialog('Löschen Gerät/Ausstattung', asset.id, async () => {
          await deleteAsset(asset);

          refreshAssets();
        })}></ActionButton>
    )
  }, {
    name: 'Name/ID',
    width: '200px',
    cell: (asset: any) => (
      <div className="displayFlex alignItemsCenter gapS">
        <AssetDnD asset={asset}></AssetDnD>
        <ActionButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Asset&selectedEntity=${asset._id}`)}></ActionButton>
      </div>
    )
  }, {
    name: 'DIN 276 Type',
    width: '200px',
    sortable: true,
    selector: (asset: any) => asset.schemaCategory,
    cell: (asset: any) => (
      <div>
        <div className="textS">{asset.schemaCategory}</div>
        <div className="textS">{metadata[asset.schemaCategory] ? metadata[asset.schemaCategory].name : ''}</div>
      </div>
    )
  }, {
    name: 'Symbol',
    width: '80px',
    right: true,
    cell: (asset: any) => (
      <IconSelector icon={asset.icon} iconChanged={async (icon: string) => {
        const newAsset = { ...asset, icon };
        const newAssets = [...assets];

        newAssets[assets.indexOf(asset)] = newAsset;

        setAssets(newAssets);

        await updateAsset(newAsset);
      }}></IconSelector>
    )
  }, {
    name: 'Zuordnung Etage',
    width: '200px',
    sortable: true,
    selector: (asset: any) => asset.floor ? (asset.floor === properties.floor._id ? 'Diese Etage' : 'Andere Etage') : 'Keine Etage',
    cell: (asset: any) => (
      <span>{asset.floor ? (asset.floor === properties.floor._id ? 'Diese Etage' : 'Andere Etage') : 'Keine Etage'} {asset.x !== undefined && asset.y !== undefined ? '(positioniert)' : '(nicht positioniert)'}</span>
    )
  }];

  const loadSpaces = async () => {
    setMetadata((await userAPI.getDIN276Metadata()).reduce((map: any, category: any) => {
      map[category.code] = category;

      return map;
    }, {}));

    const result = await userAPI.query(new Query(new EntityQuery(Floor.type, [], undefined, is('_id').equal(properties.floor._id)),
      [new LinkedEntityQuery('floor', RelationshipDirection.in, Space.type)]));

    if (!result || result.length === 0 || result.length > 1) {
      setSpaces([]);
    } else {
      console.log('Floor with spaces >>>', result[0]);

      setSpaces(result[0].spaces);
    }
  }

  useEffect(() => {
    loadSpaces();
  }, []);

  const refreshAssets = async () => {
    if (!properties.floor) {
      return;
    }

    try {
      const result = await userAPI.query(new Query(new EntityQuery(Asset.type, [], undefined, is('floor').equal(properties.floor._id))));

      console.log(result);

      setAssets(result as Asset[]);
    } catch (error) {
      // TODO Process error

      console.error();
    }
  };

  useEffect(() => {
    const call = async () => {
      await refreshAssets();
    };

    call();
  }, [properties.floor])

  return <><div className="displayFlex gapM">
    <div>
      <FloorplanEditor floor={properties.floor} floorChanged={properties.floorChanged} spaces={spaces} selectedSpaceIndex={-1} assets={assets} assetsChanged={() => refreshAssets()}></FloorplanEditor>
    </div>
    <div>
      <Section title="Geräte und Anlagen">
        <div className="width700">
          <div className='marginTopM headerM'>In diesem Gebäude</div>
          <div className="marginTopM borderNeutral">
            <DataTable
              data={assets}
              columns={assetsColumns}
              pagination
              paginationPerPage={5}
            />
          </div>
          <div className='marginTopM headerM'>Suche im Bestand</div>
          <div className="marginTopS displayFlex gapS">
            <Label>Filter:</Label>
            <TextField className="underlined" value={searchFilter} underlined placeholder="Namen oder ID eingeben ..." onChange={(_: any, filter?: string) => {
            }}
              styles={{ fieldGroup: { width: 250 } }} />
          </div>
          <div className="marginTopM borderNeutral">
            <DataTable
              data={[]}
              columns={assetsColumns}
            />
          </div>
        </div>
      </Section>
    </div>
  </div>
  </>;
}

export default function FloorProperties(properties: Properties) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [floor, setFloor] = useState(properties.floor) as any;
  const { updateFloor } = useAdministrationAPI();

  const changeFloor = async (changedFloor: any) => {
    await updateFloor(changedFloor);
    await refreshFloor();
  };

  const refreshFloor = async () => {
    const result = await userAPI.query(new Query(new EntityQuery(Floor.type, [], undefined, is('_id').equal(floor._id)),
      [new LinkedEntityQuery('building', RelationshipDirection.out, Building.type),
      new LinkedEntityQuery('address', RelationshipDirection.out, Address.type)
      ]));

    console.log('Result >>>', result);

    if (!result || result.length === 0 || result.length > 1) {
      // Process error

      console.error('No match');
    }

    // TODO Could apply reverse relationship binding

    setFloor(result[0]);
  }

  useEffect(() => {
    refreshFloor();
  }, [properties.floor]);

  return <div>
    <div className="displayFlex alignItemsCenter">
      <Text variant="medium">Etage {floor.level !== undefined ? floor.level : ''}</Text>
      <TooltipHost content="Copy floor UUID">
        <IconButton iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(floor._id)}></IconButton>
      </TooltipHost>
    </div>
    <div>
      <Text variant="xxLarge">{floor.name}</Text>
    </div>
    <div className="displayFlex alignItemsCenter">
      <Text variant="medium">{floor.building && floor.building.address ? `${floor.building.address.street} ${floor.building.address.streetNumber}, ${floor.building.address.postalCode} ${floor.building.address.city}, ${floor.building.address.country}` : ''}</Text>
      {floor.building ? <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Building&selectedEntity=${floor.building._id}`)}></IconButton> : <></>}
    </div>
    <div className="marginTopM">
      <Pivot>
        <PivotItem headerText="Allgemein" itemIcon="Info">
          <div className="marginTopM">
            <Section title="">
              <div className="displayFlex alignItemsCenter gapM">
                <TextField label="Name" value={floor.name} styles={{ fieldGroup: { width: 250 } }} onChange={(_: any, name?: string) => {
                  setFloor({ ...floor, name });
                }}
                  onBlur={() => {
                    changeFloor(floor);
                  }} />
                <SpinButton
                  label="Gebäudeebene"
                  labelPosition={Position.top}
                  defaultValue="0"
                  value={floor.level}
                  min={-200}
                  max={200}
                  step={1}
                  incrementButtonAriaLabel="Increase value by 1"
                  decrementButtonAriaLabel="Decrease value by 1"
                  styles={{ spinButtonWrapper: { width: 75 } }}
                  onChange={(_: any, value?: string) => {
                    console.log('Change level >>>', value);
                    setFloor({ ...floor, level: Number(value) });
                  }}
                  onBlur={() => {
                    changeFloor(new Floor(floor));
                  }}
                />
              </div>
              <div className="marginTopM displayFlex alignItemsCenter gapM">
                <ComboBox placeholder={'Bitte auswählen ...'} options={Object.keys(FloorCategory).filter((item) => isNaN(Number(item))).map((key: string) => { return { key, text: key } })}
                  selectedKey={floor.category} onChange={async (e: any, option: any) => {
                    const newFloor = { ...floor, category: option.key };

                    await updateFloor(newFloor);

                    setFloor(newFloor);
                  }} />
              </div>
            </Section>
          </div>
        </PivotItem>
        <PivotItem headerText="Räume" itemIcon="Room">
          <div className="marginTopM">
            <SpaceEditor floor={floor} floorChanged={refreshFloor}></SpaceEditor>
          </div>
        </PivotItem>
        <PivotItem headerText="Geräte & Ausstattung" itemIcon="ProcessMetaTask">
          <div className="marginTopM">
            <EquipmentEditor floor={floor} floorChanged={refreshFloor}></EquipmentEditor>
          </div>
        </PivotItem>
        <PivotItem headerText="Geoposition" itemIcon="World">
          <div className="marginTopM">
            <FloorplanGeopositionEditor floor={floor} floorChanged={refreshFloor}></FloorplanGeopositionEditor>
          </div>
        </PivotItem>
      </Pivot>
    </div>
  </div>;
}
