import { DefaultButton, Dialog, DialogFooter, DialogType, Label, Persona, PersonaSize, PrimaryButton, SelectionMode, TextField, Selection } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAdministrationAPI } from "../AdministrationAPI";
import { ActionButton, Text } from '@fluentui/react';
import Section from "../components/Section";
import { Checkbox } from "@fluentui/react-components";
import DataTable from "react-data-table-component";
import { dataTableStyles } from "../styles";
import { useModalContext } from "../components/ModalProvider";

enum DialogMode {
    create = 'create',
    update = 'update',
}

export default function CustomerAndUserManagementPanel(properties: any) {
    const { t } = useTranslation();
    const { showDeleteDialog } = useModalContext() as any;
    const { getCustomers, createCustomer, updateCustomer, deleteCustomer, getUsers, createUser, updateUser, deleteUser } = useAdministrationAPI();
    const [customerFilter, setCustomerFilter] = useState() as any;
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState() as any;
    const [userFilter, setUserFilter] = useState() as any;
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState() as any;
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [customerDialogMode, setCustomerDialogMode] = useState(DialogMode.create);
    const [editedCustomer, setEditedCustomer] = useState() as any;
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [userDialogMode, setUserDialogMode] = useState(DialogMode.create);
    const [editedUser, setEditedUser] = useState() as any;
    const [deleteCustomerConfirmationDialogOpen, setDeleteCustomerConfirmationDialogOpen] = useState(false);
    const [deleteUserConfirmationDialogOpen, setDeleteUserConfirmationDialogOpen] = useState(false);

    const customerSelection = new Selection({
        onSelectionChanged: () => {
            setCustomer(customerSelection.getSelection()[0]);
        }
    })

    const filterItem = (filter: string, item: any, fields: any) => {
        if (!filter || filter.trim().length === 0) {
            return true;
        }

        for (const field of fields) {
            if (item[field] &&
                item[field].toLowerCase().indexOf(filter.toLowerCase()) === 0) {
                return true;
            }
        }

        return false;
    };

    const filterCustomers = async () => {
        const allCustomers = await getCustomers();

        setCustomers(allCustomers.filter((customer: any) => filterItem(customerFilter, customer, ['name', 'domain'])));
    };

    const filterUsers = async () => {
        if (!customer) {
            return;
        }

        const allUsers = await getUsers(customer.id);

        setUsers(allUsers.filter((user: any) => filterItem(userFilter, user, ['firstName', 'lastName'])));
    };

    useEffect(() => {
        filterCustomers();
    }, [customerFilter]);

    useEffect(() => {
        filterUsers();
    }, [userFilter, customer]);

    const editCustomer = (customer: any) => {
        setEditedCustomer({ ...customer });
        toggleCustomerDialogOpen();
    }

    const toggleCustomerDialogOpen = () => {
        setCustomerDialogOpen(!customerDialogOpen);
    }

    const editUser = (user: any) => {
        setEditedUser({ ...user });
        toggleUserDialogOpen();
    }

    const toggleUserDialogOpen = () => {
        setUserDialogOpen(!userDialogOpen);
    }

    const customerColumns = [{
        name: 'ID',
        width: '150px',
        cell: (customer: any) => (
            <Text>{customer.id}</Text>
        ),
    }, {
        name: 'Name',
        minWidth: '100px',
        maxWidth: '200px',
        cell: (customer: any) => (
            <Text>{customer.name}</Text>
        ),
    }, {
        name: 'Created',
        width: '250px',
        right: true,
        cell: (customer: any) => (
            <Text variant='medium'>{new Date(customer.created).toLocaleDateString()} {new Date(customer.created).toLocaleTimeString()}</Text>
        )
    }, {
        name: 'Domain',
        minWidth: '100px',
        maxWidth: '200px',
        cell: (customer: any) => (
            <a href={`https://${customer.domain}`} target="_blank" rel="noreferrer"><Text variant='medium'>{customer.domain}</Text></a>
        )
    }, {
        name: 'Actions',
        minWidth: '100px',
        maxWidth: '200px',
        cell: (customer: any) => (
            <div className="displayFlex gapS">
                <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => {
                    setCustomerDialogMode(DialogMode.update);
                    editCustomer(customer);
                }}>
                </ActionButton>
                <ActionButton iconProps={{ iconName: 'Delete' }} onClick={() => showDeleteDialog('Delete Customer', customer.name, async () => {
                    setCustomer(null);
                    await deleteCustomer(customer.id);

                    filterCustomers();
                })}>
                </ActionButton>
            </div>
        ),
    },
    ];

    const userColumns = [
        {
            name: 'User',
            width: '300px',
            cell: (user: any) => (
                <Persona {...{
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: `${user.email}`,
                    imageInitials: `${user.firstName.toUpperCase().charAt(0)}${user.lastName.toUpperCase().charAt(0)}`,
                }} text={`${user.firstName} ${user.lastName}`} size={PersonaSize.size40} />
            ),
        }, {
            name: 'Status',
            width: '100px',
        }, {
            name: 'Administrator',
            width: '100px',
            center: true,
            cell: (user: any) => (
                <div className="displayFlex justifyContentCenter">
                    <Checkbox checked={user.roles && user.roles.indexOf('administrator') >= 0} onChange={async (e: any, value: any) => {
                        user.roles = user.roles || [];

                        if (value && user.roles.indexOf('administrator') < 0) {
                            user.roles.push('administrator')
                        } else {
                            user.roles = user.roles.filter((role: string) => role !== 'administrator')
                        }

                        await updateUser(customer.id, user);
                    }} />
                </div>
            )
        }, {
            name: 'Actions',
            width: '100px',
            cell: (user: any) => (
                <div className="displayFlex gapS">
                    <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => {
                        console.log('User >>>', user);
                        editUser(user);
                        setUserDialogMode(DialogMode.update);
                    }}>
                    </ActionButton>
                    <ActionButton iconProps={{ iconName: 'Delete' }} onClick={() => showDeleteDialog('Delete User', `${user.firstName} ${user.lastName}`, async () => {
                        await deleteUser(customer.id, user);

                        filterUsers();
                    })}>
                    </ActionButton>
                </div>
            ),
        },
    ];

    return <>
        <div>
            <Section title="Customers">
                <div className="marginTopL displayFlex gapM">
                    <div className="displayFlex gapS">
                        <Label>Filter:</Label>
                        <TextField
                            value={customerFilter}
                            onChange={(e: any, value: string | undefined) => setCustomerFilter(value)}
                            styles={{ root: { maxWidth: '100px' } }}
                        />
                    </div>
                    <ActionButton iconProps={{ iconName: 'AddFriend' }} onClick={() => {
                        setCustomerDialogMode(DialogMode.create);
                        editCustomer({});
                    }}>
                        Create customer
                    </ActionButton>
                </div>
                <DataTable
                    data={customers}
                    columns={customerColumns}
                    customStyles={dataTableStyles}
                    selectableRows
                    selectableRowsSingle
                    onSelectedRowsChange={({ selectedRows }) => {
                        console.log(selectedRows);

                        setCustomer(selectedRows[0]);
                    }}
                    pagination
                    paginationPerPage={5}
                />
            </Section>
            <div className="marginTopL displayFlex gapM">
                <Section title="Users">
                    <div className="width600">
                        {customer
                            ?
                            <div className="marginTopM">
                                <div className="displayFlex gapM">
                                    <div className="displayFlex gapS">
                                        <Label>Filter:</Label>
                                        <TextField
                                            value={userFilter}
                                            onChange={(e: any, value: string | undefined) => setUserFilter(value)}
                                            styles={{ root: { maxWidth: '100px' } }}
                                        />
                                    </div>
                                    <ActionButton iconProps={{ iconName: 'AddFriend' }} onClick={() => {
                                        setUserDialogMode(DialogMode.create);
                                        editUser({});
                                    }}>
                                        Create user
                                    </ActionButton>
                                </div>
                                <DataTable
                                    data={users}
                                    columns={userColumns}
                                    customStyles={dataTableStyles}
                                    pagination
                                    paginationPerPage={5}
                                />
                            </div>
                            :
                            <div className="marginTopL"><Text variant="medium">No customer selected.</Text></div>
                        }
                    </div>
                </Section>
                <Section title="Authentication & SSO">
                </Section>

            </div>
        </div>
        {editedCustomer ?
            <Dialog
                hidden={!customerDialogOpen}
                onDismiss={toggleCustomerDialogOpen}
                maxWidth={600}
                minWidth={600}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: customerDialogMode === DialogMode.update ? 'Edit Customer' : 'Create Customer',
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <TextField label="Name:" value={editedCustomer.name} onChange={(e: any, customerName: any) => setEditedCustomer({ ...editedCustomer, name: customerName })} />
                <TextField label="Domain:" value={editedCustomer.domain} onChange={(e: any, domain: any) => setEditedCustomer({ ...editedCustomer, domain })} />
                <DialogFooter className="marginTopXXL">
                    <PrimaryButton text={customerDialogMode === DialogMode.update ? 'Apply' : 'Create'} onClick={async () => {
                        if (customerDialogMode === DialogMode.update) {
                            updateCustomer({ ...customer, ...editedCustomer });
                            filterCustomers();
                        } else {
                            await createCustomer(editedCustomer);
                            filterCustomers();
                        }

                        toggleCustomerDialogOpen();
                    }} />
                    <DefaultButton onClick={toggleCustomerDialogOpen} text={t('global.cancel')} />
                </DialogFooter>
            </Dialog >
            :
            <></>
        }
        {
            editedUser ?
                <Dialog
                    hidden={!userDialogOpen}
                    onDismiss={toggleUserDialogOpen}
                    maxWidth={600}
                    minWidth={600}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: userDialogMode === DialogMode.update ? 'Edit User' : 'Create User',
                    }}
                    modalProps={{
                        isBlocking: true,
                    }}
                >
                    <TextField label="E-Mail" value={editedUser.email} onChange={(e: any, email: any) => setEditedUser({ ...editedUser, email })}
                        onGetErrorMessage={(value: string): string => {
                            return value && value.length > 0 ? '' : `Email cannot be empty.`;
                        }}
                        validateOnFocusOut />
                    <TextField label="First Name" value={editedUser.firstName} onChange={(e: any, firstName: any) => setEditedUser({ ...editedUser, firstName })}
                        onGetErrorMessage={(value: string): string => {
                            return value && value.length > 0 ? '' : `First name cannot be empty.`;
                        }}
                        validateOnFocusOut />
                    <TextField label="Last Name" value={editedUser.lastName} onChange={(e: any, lastName: any) => setEditedUser({ ...editedUser, lastName })}
                        onGetErrorMessage={(value: string): string => {
                            return value && value.length > 0 ? '' : `Last name cannot be empty.`;
                        }}
                        validateOnFocusOut />
                    <TextField label="Password" value={editedUser.password} onChange={(e: any, password: any) => setEditedUser({ ...editedUser, password })}
                        onGetErrorMessage={(value: string): string => {
                            return value && value.length > 0 ? '' : `Password cannot be empty.`;
                        }}
                        type="password"
                        canRevealPassword
                        validateOnFocusOut />
                    <TextField label="Repeat password" value={editedUser.repeatPassword} onChange={(e: any, repeatPassword: any) => setEditedUser({ ...editedUser, repeatPassword })}
                        onGetErrorMessage={(value: string): string => {
                            return value && value.length > 0 ? '' : `Repeat password cannot be empty.`;
                        }}
                        type="password"
                        canRevealPassword
                        validateOnFocusOut />
                    <DialogFooter className="marginTopXXL">
                        <PrimaryButton text={userDialogMode === DialogMode.update ? 'Apply' : 'Create'} onClick={async () => {
                            if (userDialogMode === DialogMode.update) {
                                await updateUser(customer.id, { ...user, ...editedUser });

                                filterUsers();
                            } else {
                                await createUser(customer.id, { ...editedUser, oneTimePassword: true });

                                filterUsers();
                            }

                            toggleUserDialogOpen();
                        }} />
                        <DefaultButton onClick={toggleUserDialogOpen} text={t('global.cancel')} />
                    </DialogFooter>
                </Dialog>
                :
                <></>
        }
    </>;
}