import './App.sass';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useState, useEffect, useRef } from "react";
import tinycolor from "tinycolor2";
import { getEntityType, PoI, PoIType, Entity, Way } from '@the-real-insight/tri-model';
import { Toggle } from '@fluentui/react';
import Map, { Marker, Popup, Source, Layer, NavigationControl, ScaleControl, FullscreenControl, GeolocateControl, MapRef } from 'react-map-gl';
import { EntityFields } from './components/EntityFields';
import markerChargingStation from './marker-charging-station-icon.svg';
import markerUniversity from './marker-university-icon.svg';
import marker from './marker.svg';

interface Properties {
    setObject?: (object: Entity) => void;
    showMiniMap?: boolean;
    query: string | null;
    data?: any;
    options?: any;
    pois?: any[]
    path?: { lat: number; lon: number }[];
}

const mapPoItoSVG = (poiType: any) => {
    console.log('PoIType >>>', poiType);

    switch (poiType) {
        case PoIType.chargingStation:
        case 'chargingPoint':
            return markerChargingStation;
        case PoIType.university: return markerUniversity;
        default: return marker;
    }
}

const percentToColor = (value: number) => {
    const minimumColor = tinycolor('#00FF00');
    const maximumColor = tinycolor('#FF0000');
    const step = (maximumColor.toHsl().h - minimumColor.toHsl().h) / 100;

    return minimumColor.spin(step * value);
};

export default function MapAndImage(properties: Properties) {
    const [bridges, setBridges] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [areas, setAreas] = useState([]);
    const [shapes, setShapes] = useState([]);
    const [plotsOfLand, setPlotsOfLand] = useState([]);
    const [options] = useState(properties.options || {});
    const [heatmapMax, setHeatmapMax] = useState(100);
    const [heatmapMin, setHeatmapMin] = useState(0);
    const [pieChartData, setPieChartData] = useState() as any;
    const [selectedEntity, setSelectedEntity] = useState() as any;
    const [hoveredEntity, setHoveredEntity] = useState() as any;
    const [showSatelliteImage, setShowSatelliteImage] = useState(false);
    const [pois, setPoIs] = useState([]) as any;
    const [ways, setWays] = useState([]) as any;
    const mapRef = useRef<MapRef>(null);

    // const calculateHeatmapDetails = (features: any) => {
    //     if (!options.heatmapField || !features || features.length === 0) {
    //         return;
    //     }

    //     let minimum = Number.MAX_VALUE;
    //     let maximum = Number.MIN_VALUE;

    //     features.forEach((feature: any) => {
    //         const value = feature[options.heatmapField];

    //         if (value !== undefined) {
    //             minimum = Math.min(minimum, value);
    //             maximum = Math.max(maximum, value);
    //         }
    //     });

    //     setHeatmapMin(minimum);
    //     setHeatmapMax(maximum);
    // }

    // const adjustBounds = (features: any) => {
    //     let minimumLat = Number.MAX_VALUE;
    //     let maximumLat = Number.MIN_VALUE;
    //     let minimumLon = Number.MAX_VALUE;
    //     let maximumLon = Number.MIN_VALUE;

    //     features.forEach((feature: any) => {
    //         if (feature.boundingBox) {
    //             minimumLat = Math.min(minimumLat, feature.boundingBox[0][0]);
    //             maximumLat = Math.max(maximumLat, feature.boundingBox[1][0]);
    //             minimumLon = Math.min(minimumLon, feature.boundingBox[0][1]);
    //             maximumLon = Math.max(maximumLon, feature.boundingBox[1][1]);
    //         } else if (feature.shape) {
    //             feature.shape.forEach((entry: any) => {
    //                 if (entry.forEach) {
    //                     entry.forEach((point: any) => {
    //                         minimumLat = Math.min(minimumLat, point[0]);
    //                         maximumLat = Math.max(maximumLat, point[0]);
    //                         minimumLon = Math.min(minimumLon, point[1]);
    //                         maximumLon = Math.max(maximumLon, point[1]);
    //                     });
    //                 } else if (entry.lat) {
    //                     minimumLat = Math.min(minimumLat, entry.lat);
    //                     maximumLat = Math.max(maximumLat, entry.lat);
    //                     minimumLon = Math.min(minimumLon, entry.lon);
    //                     maximumLon = Math.max(maximumLon, entry.lon);
    //                 }
    //             });
    //         }
    //     });

    //     setBounds([[minimumLat, minimumLon], [maximumLat, maximumLon]] as any);
    // }

    // useEffect(() => {
    //     const f = async () => {

    //         if (properties.objectClass === 'bridge') {
    //             const newBridges = await userAPI.getBridges(properties.query);

    //             setBridges(newBridges);

    //             newBridges.forEach((bridge: any) => {
    //                 bridge.class = 'Bridge';
    //             });
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, [map, properties.objectClass, properties.query]);

    // useEffect(() => {
    //     const f = async () => {
    //         if (properties.objectClass === 'building') {
    //             let newBuildings;

    //             newBuildings = properties.data.buildings;

    //             setBuildings(newBuildings);

    //             newBuildings.forEach((building: any) => {
    //                 building.class = 'Building';
    //                 // @TODO Temporary
    //                 building.id = building.id.replace('undefined', 'DE')
    //             });

    //             console.log('Buildings >>>', newBuildings);

    //             calculateHeatmapDetails(newBuildings);
    //             adjustBounds(newBuildings);
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, [map]);

    // useEffect(() => {
    //     const f = async () => {
    //         if (properties.objectClass === 'Tile') {
    //             let newAreas;

    //             newAreas = properties.data.areas;

    //             // TODO Hack

    //             newAreas.forEach((area: any) => {
    //                 area.class = 'Tile';
    //             });

    //             setAreas(newAreas);
    //             calculateHeatmapDetails(newAreas);
    //             adjustBounds(newAreas);
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, []);

    // useEffect(() => {
    //     const f = async () => {
    //         if (properties.objectClass === 'LocalGovernment' && properties.data.localGovernments) {
    //             let newLocalGovernments;
    //             newLocalGovernments = properties.data.localGovernments;

    //             // TODO Hack

    //             newLocalGovernments.forEach((area: any) => {
    //                 area.class = 'LocalGovernment';
    //             });

    //             setShapes(newLocalGovernments);
    //             adjustBounds(newLocalGovernments);
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, []);

    // useEffect(() => {
    //     const f = async () => {
    //         if (properties.objectClass === 'PostalCode' && properties.data.postalCodes) {
    //             let newPostalCodes;
    //             newPostalCodes = properties.data.postalCodes;

    //             // TODO Hack

    //             newPostalCodes.forEach((postalCode: any) => {
    //                 postalCode.class = 'PostalCode';
    //             });

    //             console.log('Postal codes >>>', newPostalCodes);

    //             setShapes(newPostalCodes);
    //             adjustBounds(newPostalCodes);
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, []);

    // useEffect(() => {
    //     const f = async () => {
    //         if (properties.objectClass === 'PlotOfLand' && properties.data.plotsOfLand) {
    //             let newPlotsOfLand;
    //             newPlotsOfLand = properties.data.plotsOfLand;

    //             // TODO Hacks

    //             newPlotsOfLand.forEach((plotOfLand: any) => {
    //                 plotOfLand.class = 'PlotOfLand';
    //             });

    //             console.log('Plots of land >>>', newPlotsOfLand);

    //             setPlotsOfLand(newPlotsOfLand);
    //             adjustBounds(newPlotsOfLand);
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, []);

    // useEffect(() => {
    //     const f = async () => {
    //         if (properties.data.points && properties.options.pieChart) {

    //             console.log('Pie Chart Data ====>>>', properties.data.points);

    //             setPieChartData(properties.data.points)
    //             //adjustBounds(newPlotsOfLand);
    //         }
    //     };

    //     f().catch((error) => console.error(error))
    // }, []);

    // useEffect(() => {
    //     try {
    //         if (map && bounds) {
    //             map.fitBounds(bounds);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }, [map, bounds]);

    useEffect(() => {
        if (properties.options.entityType === 'PoI') {
            const newPois = properties.data.map((raw: any) => {
                if (raw._type) {
                    const entityType = getEntityType(raw._type);

                    return new entityType(raw);
                } else {
                    return raw;
                }
            });

            console.log('PoIs >>>', newPois);

            setPoIs(newPois);
        } else if (properties.options.entityType === 'Way') {
            const newWays = properties.data.map((raw: any) => {
                if (raw._type) {
                    const entityType = getEntityType(raw._type);

                    return new entityType(raw);
                } else {
                    return raw;
                }
            });

            console.log('Ways >>>', newWays.map((raw: any) => new Way(raw)));

            setWays(newWays.map((raw: any) => new Way(raw)));
        }
    }, [properties.data]);

    const openPopup = (poi: any) => {
        setSelectedEntity(poi)
    }

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current.getMap();
            
            const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
                const features = map.queryRenderedFeatures(e.point, { layers: ways.map((_: Way, i: number) => `path-${i}`) });
                
                // Reset all paths to default style (purple)
                ways.forEach((_: Way, i: number) => {
                    map.setPaintProperty(`path-${i}`, 'line-width', 3);
                    map.setPaintProperty(`path-${i}`, 'line-color', '#9483F1');
                });

                // Highlight the path under the cursor (red)
                if (features.length > 0) {
                    const layerId = features[0].layer.id;
                    const index = parseInt(layerId.split('-')[1]);
                    const way = ways[index];
                    map.setPaintProperty(layerId, 'line-width', 5);
                    map.setPaintProperty(layerId, 'line-color', '#FF0000');
                    setHoveredEntity(way);
                } else {
                    setHoveredEntity(null);
                }
            };

            map.on('mousemove', handleMouseMove);

            return () => {
                map.off('mousemove', handleMouseMove);
            };
        }
    }, [ways]);

    return <div className="displayFlex flexDirectionColumn gapM">
        <Toggle
            label="Show satellite image"
            checked={showSatelliteImage}
            onChange={(_: any, showSatelliteImage?: boolean) => setShowSatelliteImage(!!showSatelliteImage)}
            role="checkbox"
        />
        <Map mapboxAccessToken="pk.eyJ1IjoibWFyY2dpbGxlc2VwZWhyaSIsImEiOiJjbTFoOWszeHQwYzR6MmpzYXh0OWo0cWF1In0.oCmn9LnN9lZavqGgDL_Y2w"
            ref={mapRef}
            initialViewState={{
                longitude: properties.options.centerLon || 8.670636045048122,
                latitude: properties.options.centerLat || 50.10495071520639,
                zoom: 15
            }}
            style={{ width: 800, height: 600 }}
            mapStyle={showSatelliteImage ? 'mapbox://styles/mapbox/satellite-streets-v9' : 'mapbox://styles/mapbox/streets-v9'}
        >
            <NavigationControl position="top-right" />
            <ScaleControl position="bottom-right" />
            <FullscreenControl position="top-right" />
            <GeolocateControl position="top-right" />
            {(ways || []).map((way: Way, index: number) => (
                <Source
                    key={`path-${index}`}
                    type="geojson"
                    data={{
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: way.shape.map((point: any) => [point.lon, point.lat])
                        }
                    }}
                >
                    <Layer
                        id={`path-${index}`}
                        type="line"
                        paint={{
                            'line-color': '#9483F1',
                            'line-width': 3,
                            'line-opacity': 0.8
                        }}
                    />
                </Source>
            ))}
            {pois.map((poi: PoI) => <Marker
                longitude={poi.lon}
                latitude={poi.lat}>
                <div className="marker" onClick={() => properties.setObject ? properties.setObject(poi) : ''}
                    onMouseEnter={() => setHoveredEntity(poi)} onMouseLeave={() => setHoveredEntity(null)}>
                    <span><img style={{ width: 35, height: 35 }} src={mapPoItoSVG(poi.poiType)} /></span>
                </div>
            </Marker>)}
            {hoveredEntity ?
                <Popup
                    latitude={hoveredEntity.lat}
                    longitude={hoveredEntity.lon}
                    onClose={() => setHoveredEntity(null)}
                    closeButton={false}
                    closeOnClick={false}
                    offset={0}
                    style={{ width: '500px' }}
                >
                    <div>
                        <div className='textHeader'>{hoveredEntity.getTypeClass().type.label.de}</div>
                        <div>
                            <EntityFields entity={hoveredEntity}></EntityFields>
                        </div>
                    </div>
                </Popup>
                :
                <></>
            }
        </Map>
    </div>;
}