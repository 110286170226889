import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAdministrationAPI } from "../AdministrationAPI";
import { Checkbox, Label, PrimaryButton, Text, TextField } from '@fluentui/react';
import DataTable from "react-data-table-component";
import { dataTableStyles } from "../styles";
import { Invoice, InvoiceStatus } from "@the-real-insight/tri-model";
import Section from "../components/Section";
import { useModalContext } from "../components/ModalProvider";

export default function InvoicingPanel(properties: any) {
    const { t } = useTranslation();
    const { pushError } = useModalContext() as any;
    const { getAllInvoices, updateInvoices } = useAdministrationAPI();
    const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    const [openOnlyChecked, setOpenOnlyChecked] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [clearRows, setClearRows] = useState(false);
    const [filter, setFilter] = useState() as any;

    const filterInvoices = async () => {
        setInvoices((await getAllInvoices(new Date('2024-05-01T00:00:00'), new Date(), openOnlyChecked, filter))
            .sort((a: Invoice, b: Invoice) => b.periodFromDate.getTime() - a.periodFromDate.getTime()));
    }

    useEffect(() => {
        try {
            filterInvoices();
        } catch (error: any) {
            pushError(error);
        }
    }, [openOnlyChecked, filter]);

    const columns = [{
        name: t('billingPanel.paymentStatusColumn'),
        width: '250px',
        cell: (invoice: Invoice) => (
            <div className="width50 paddingS displayFlex justifyContentCenter">
                <div className={(invoice.status === InvoiceStatus.paid ? 'colorGreen' : 'colorRed') + ' fontWeightBold textM'}>{invoice.status === InvoiceStatus.paid ? 'Bezahlt' : 'Offen'}</div>
            </div>
        ),
    }, {
        name: t('administrationPage.invoicingPanel.invoiceIDColumn'),
        width: '250px',
        cell: (invoice: Invoice) => (
            <Text variant="medium">{invoice.id}</Text>
        ),
    }, {
        name: t('billingPanel.invoiceDateColumn'),
        width: '200px',
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{invoice.creationDate.toLocaleDateString()}</Text>
        ),
    }, {
        name: t('billingPanel.invoicePeriodColumn'),
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{invoice.periodFromDate.toLocaleDateString()} - {new Date(invoice.periodToDate).toLocaleDateString()}</Text>
        ),
    }, {
        name: t('billingPanel.amountColumn'),
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{currencyFormat.format(invoice.subtotal())}</Text>
        ),
    }, {
        name: t('administrationPage.invoicingPanel.paidDateColumn'),
        cell: (invoice: Invoice) => (
            <Text variant="medium">{invoice.paidDate ? invoice.paidDate.toLocaleDateString() : t('administrationPage.invoicingPanel.notPaid')}</Text>
        ),
    }];
    return <>
        <Section title={t('administrationPage.invoicingPanel.invoicesAndPayment')}>
            <div className="paddingM displayFlex alignItemsCenter gapM">
                <Checkbox label={t('administrationPage.invoicingPanel.openOnly')} checked={openOnlyChecked} onChange={() => setOpenOnlyChecked(!openOnlyChecked)} />
                <div className="displayFlex gapS">
                    <Label>{t('administrationPage.invoicingPanel.filter')}:</Label>
                    <TextField
                        value={filter}
                        onChange={(e: any, value: string | undefined) => setFilter(value)}
                        styles={{ root: { maxWidth: '200px' } }}
                    />
                </div>
                <div className="flexGrow1 displayFlex justifyContentEnd">
                    <PrimaryButton disabled={selectedInvoices.length === 0}
                        onClick={async () => {
                            console.log('Marking as paid >>>', selectedInvoices);

                            selectedInvoices.forEach((invoice: Invoice) => {
                                invoice.status = InvoiceStatus.paid;
                                invoice.paidDate = new Date();
                            });

                            try {
                                await updateInvoices(selectedInvoices);

                                filterInvoices();
                                setSelectedInvoices([]);
                                setClearRows(!clearRows);
                            } catch (error) {
                                pushError(error);
                            }
                        }}
                    >{t('administrationPage.invoicingPanel.markPaid')}</PrimaryButton>
                </div>
            </div>
            <div className="marginTopL">
                <DataTable
                    columns={columns}
                    data={invoices}
                    customStyles={dataTableStyles}
                    selectableRows
                    pagination
                    onSelectedRowsChange={(selection: any) => {
                        setSelectedInvoices(selection.selectedRows);
                    }}
                    clearSelectedRows={clearRows}
                />
            </div>
        </Section>
    </>;
}