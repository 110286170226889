import { Role, RoleType } from "@the-real-insight/tri-model";
import { useEffect, useState } from "react";
import { FontIcon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from 'react-accessible-treeview';
import cx from "classnames";
import { userAPI } from "../UserAPI";
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { useCustomerTheme } from "../Theme";
import RoleLabel from "./RoleLabel";

interface Properties {
    className?: string;
    showParameterizedRoles?: boolean,
    showDelegatedRoles?: boolean,
    showIssuer?: boolean,
}

export default function RoleList(properties: Properties) {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [roles, setRoles] = useState([]);
    const [expandedIds, setExpandedIds] = useState([]);

    interface DraggableProperties {
        children?: any;
        item?: any;
        type?: any;
    }

    const Draggable = (properties: DraggableProperties) => {
        const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
            type: properties.type,
            item: properties.item,
            collect: (monitor: any) => ({
                isDragging: monitor.isDragging()
            })
        }))

        return <>
            <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <div ref={drag} >
                    {properties && properties.children
                        ?
                        (properties.children)
                        :
                        <></>
                    }
                </div>
            </div>
        </>;
    }

    useEffect(() => {
        const call = async () => {
            const newRoles = (await userAPI.getRoles(authentication.customer)).map((role: any) => new Role(role));

            console.log('Roles Hierarchy >>>', newRoles);

            newRoles.sort((a: Role, b: Role) => a.getRoleName().localeCompare(b.getRoleName()));            
            setRoles(newRoles)
        };

        call();
    }, []);

    const buildTree = (roles: Role[]) => {
        const nodes = roles.filter((role: Role) => properties.showDelegatedRoles || role.type !== RoleType.partnerDelegation)
            .map((role: Role) => {
                const roleNode = {
                    name: `${role.name}`,
                    children: [] as any,
                    metadata: role
                };

                if (role.instances) {
                    role.instances.forEach((instance: Role) => {
                        const instanceNode = {
                            name: `${instance.name}`,
                            children: [] as any,
                            metadata: instance
                        };

                        instance.template = role as any;

                        if (instance.delegates) {
                            instance.delegates.forEach((delegate: Role) => {
                                delegate.principal = instance as any;

                                const delegateNode = {
                                    name: `${delegate.name}`,
                                    children: [] as any,
                                    metadata: delegate
                                };

                                if (properties.showDelegatedRoles) {
                                    instanceNode.children.push(delegateNode);
                                }
                            })
                        }

                        if (properties.showParameterizedRoles) {
                            roleNode.children.push(instanceNode);
                        }
                    });
                }

                return roleNode;
            });

        const result = flattenTree({
            name: '',
            children: Object.values(nodes) as any
        });

        return result;
    }

    return <div className={properties.className || ''}>
        <TreeView
            data={buildTree(roles)}
            aria-label="Controlled expanded node tree"
            expandedIds={expandedIds}
            defaultExpandedIds={[1]}
            nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                isDisabled,
                getNodeProps,
                level,
                handleExpand,
            }) => {
                return (
                    <div
                        {...getNodeProps({ onClick: handleExpand })}
                        className="displayFlex paddingXXS alignItemsCenter"
                        style={{
                            marginLeft: 40 * (level - 1),
                            opacity: isDisabled ? 0.5 : 1,
                        }}
                    >
                        <div className="width10">
                            {isBranch ?
                                <ArrowIcon isOpen={isExpanded} className='' />
                                :
                                <></>
                            }
                        </div>
                        <div className="displayFlex gapS alignItemsCenter">
                            <Draggable type={'role'} item={element.metadata}>
                                <RoleLabel role={new Role(element.metadata)} showIssuer={properties.showIssuer}></RoleLabel>
                            </Draggable>
                        </div>
                    </div>
                );
            }}
        />
    </div>;
}

const ArrowIcon = ({ isOpen, className }: { isOpen: any, className: string }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );

    return <IoMdArrowDropright className={classes} />;
};